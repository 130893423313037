import Newlmodal from "./components/NewUser/NewUser";
import Changemodal from "./components/ChangeUser/ChangeUser";
import DetailmodalUser from "./components/DetailUser/DetailUser";
import Deletemodal from "./components/DeleteUser/DeleteUser";
import { useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";
import Typography from "@mui/material/Typography";
import {
  Box,
  ThemeProvider,
  IconButton,
  Chip,
  Menu,
  TablePagination,
} from "@mui/material";
import Icon from "../../assets/images/users-icon-svg.svg";
import {
  QuickSearchToolbar,
  pageTheme,
  StyledDataGrid,
  CustomLoadingOverlay,
  escapeRegExp,
} from "../../ayudas/Themes";
import { MoreVert } from "@mui/icons-material";
import { ImageArray } from "./UserServices";
import { GridOverlay } from "@mui/x-data-grid-pro";
import axios from "axios";
import ExporExcelMiddle from "../../ayudas/ExporExcelMiddle";
import TablePaginationActions from "../../ayudas/TablePaginations";
import { env } from "../../env";
export default function UserTable(props) {
  const { DataUser, UpdateId } = props;
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  const [rows, setRows] = useState([]);
  const [totalRows, settotalRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const rowsPerPage = 10;
  const [ExportData, setExportData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [signalReload, setsignalReload] = useState(0);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cookies.set("CurrentPageDataPagination", "1", {
      path: "/",
      SameSite: "none",
      secure: true,
    });
    async function GetData() {
      setLoading(true);
      var url = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/`;
      var requestHeaders = new Headers();
      requestHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      requestHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: requestHeaders,
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions).then((response) =>
        response.json()
      );
      if (response) {
        let array = [];
        response.forEach((element) => {
          array.push({
            id: element.id,
            rol: element.attributes.rol[0],
            firstName: element.firstName,
            lastName: element.lastName,
            nickName: element.firstName + " " + element.lastName,
            actions: element,
            email: element.email,
            urlIma: element.attributes.avatar[0],
            totp: element.totp,
          });
        });
        setRows(array);
        settotalRows(array);
        let expo = {
          data: array,
          colums: [
            { header: "User", key: "nickName" },
            { header: "Email", key: "email" },
            { header: "Role", key: "rol" },
          ],
        };
        setExportData(expo);
        setLoading(false);
      } else {
        setRows([]);
        settotalRows([]);
        setLoading(false);
      }
    }
    GetData();

    return () => {
      source.cancel();
    };
  }, [cookies, signalReload]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);

    if (searchValue === "") {
      setRows(totalRows);
    }
  };
  const Reload = () => {
    setTimeout(() => {
      requestSearch("");
      setsignalReload(signalReload + 1);
    }, 1000);
    UpdateId();
  };

  const columns = [
    {
      field: "img",
      headerName: "Img",
      flex: 0.3,
      minWidth: 30,
      align: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => (
        <Box style={{ color: "#0a0818", textAlign: "center", width: "100%" }}>
          |
        </Box>
      ),
      renderCell: (params) => (
        <>
          {
            <Box
              sx={{
                paddingTop: "10px",
                height: "100%",
              }}
            >
              {ImageArray[params.row.urlIma]}
            </Box>
          }
        </>
      ),
    },

    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      minWidth: 100,

      renderCell: (params) => (
        <Typography
          style={{
            width: "100%",
            fontWeight: "600",
            alignContent: "center",
            height: "100%",
            overflow: "hidden",
            marginLeft: "7px",

            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.firstName + " " + params.row.lastName}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "rol",
      headerName: "Role",
      type: "singleSelect",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Role</Box>
      ),
      renderCell: (params) => (
        <Chip
          style={{
            textTransform: "capitalize",
            width: "120px",
          }}
          variant="outlined"
          label={params.value}
          color={params.value === "admin" ? "secondary" : "primary"}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      minWidth: 200,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Actions</Box>
      ),
      renderCell: (params) => (
        <>
          <DetailmodalUser
            User={params.row}
            expandButton={false}
            DataUser={DataUser}
            Reload={Reload}
            rol={params.row.rol}
          />

          <Changemodal User={params} DataUser={DataUser} Reload={Reload} />

          <Deletemodal id={params.id} DataUser={DataUser} Reload={Reload} />
        </>
      ),
    },
  ];

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileId = "page-menu-mobile";
  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <ExporExcelMiddle
          pageOrigin="Users"
          externalData={ExportData}
          fileName="E.V.E. User Export"
        />
        <Newlmodal DataUser={DataUser} Reload={Reload} />
      </Menu>
    </Box>
  );

  return (
    <Box style={{ width: "100%" }}>
      <ThemeProvider theme={pageTheme}>
        <Typography variant="pageTitle">
          <img src={Icon} alt="" className="rt-icon-header" />
          Users
          <Box
            sx={{
              float: { xs: "none", md: "right" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <ExporExcelMiddle
              pageOrigin="Users"
              externalData={ExportData}
              fileName="E.V.E. User Export"
            />
            <Newlmodal DataUser={DataUser} Reload={Reload} />
          </Box>
          <Box
            sx={{
              float: { xs: "right", md: "none" },
              display: { xs: "inline-block", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </Box>
        </Typography>
        {renderMobileMenu}
        <Box
          style={{
            width: "100%",
            backgroundColor: "#242933",
            border: " 1px solid #090f1a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "14px",
            marginTop: "24px",
            height: "auto",
          }}
        >
          <div style={{ color: "white", height: "auto", width: "100%" }}>
            <StyledDataGrid
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
              slots={{
                toolbar: QuickSearchToolbar,
                loadingOverlay: CustomLoadingOverlay,
                noRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                noResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
                
              }}
              disableRowSelectionOnClick
              disableColumnSelector
              disableSelectionOnClick
              suppressPaginationPanel={true}
              disableDensitySelector
              loading={loading}
              rows={rows}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: { paginationModel: { pageSize: rowsPerPage } },
              }}
              pagination
              pageSizeOptions={[5, 10, 20]}
              density="comfortable"
            />
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
