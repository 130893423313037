import React from "react";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import "../AgentStyles.scss";

import { Box } from "@mui/system";
import { deleteClient } from "../../../Services/Clients";
import { rolValidation } from "../../../ayudas/ScriptsFunctions";
import GeneralModalDelete from "../../../ayudas/CommonComponents/GeneralDeleteModal";
import { Delete } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

export default function Deletemodal(props) {
  const { row, Reload } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (row.online) {
      enqueueSnackbar("Unable to delete an online Endpoint",{variant:"info"});
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deletelogic = async () => {
    const response = await deleteClient(row.id);
    if (response) {
      Reload();
      handleClose();
    }
  };
  return (
    <Box style={{ display: "inline-block" }}>
      <Tooltip title="Delete">
        <IconButton disabled={rolValidation()} className="iconButtonSize" onClick={handleOpen}>
          <Delete color="inherit" />
        </IconButton>
      </Tooltip>
      <GeneralModalDelete
        ExternalOpen={open}
        HeaderText=""
        BodyText="Do you want to delete this endpoint?"
        CloseAction={handleClose}
        PrimaryAction={deletelogic}
        CancelAction={handleClose}
      />
    </Box>
  );
}
