import { Box, Modal, Typography } from "@mui/material";
import { ActionButton, SecondaryButton } from "../Themes";
import "./GeneralDeleteModal.scss";
export default function GeneralModalDelete(props) {
  const {
    ExternalOpen = false,
    HeaderText = "Do you want to delete this",
    BodyText = "The Elimination is permanent",
    pButtonText = "Delete",
    sButtonText = "Cancel",
  } = props;
  const CloseAction = () => {
    props.CloseAction();
  };
  const PrimaryAction = () => {
    props.PrimaryAction();
  };
  const CancelAction = () => {
    props.CancelAction();
  };
  return (
    <Modal open={ExternalOpen} onClose={CloseAction}>
      <Box className="GeneralModal">
        <Typography variant="h5">{HeaderText}</Typography>
        <Typography variant="h6">{BodyText}</Typography>

        <ActionButton onClick={PrimaryAction}>{pButtonText}</ActionButton>
        <SecondaryButton onClick={CancelAction}>{sButtonText}</SecondaryButton>
      </Box>
    </Modal>
  );
}
