import * as React from "react";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import FilterFromToExecutions from "./FilterFromToExecutions/FilterFromToExecutions";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import filterIcon from "../../../assets/images/filter-icon.svg";
import { ButtonActions } from "../../../ayudas/Themes";

const PopOverColor = styled(Popover)(({ theme }) => ({ 
  color: theme.palette.getContrastText("#ffff"),
  "& .MuiPopover-paper": {
    backgroundColor: "rgb(30, 28, 58)",
    backgroundImage:"none"
  },
}));
export default function PopOverFilter(props) {
  const {ValueDateGet}=props;
const [valueDate, setValueDate] = React.useState(ValueDateGet);

React.useEffect(() => {
  setValueDate(ValueDateGet);
}, [ValueDateGet])

  const HandleChange = (value)=>{
    setValueDate(value)
    props.HandleFilterDate(value);   
  }

  return (
    <PopupState variant="popover" >
      {(popupState) => (
        <>
          <Tooltip title="Filter By Calendar" arrow>
            <ButtonActions  style={{marginLeft:"20%"}} variant="contained" {...bindTrigger(popupState)}>
              <img src={filterIcon} style={{marginRight:"10px"}} alt=""></img>
              Filter by dates
            </ButtonActions>
          </Tooltip>

          <PopOverColor  
            {...bindPopover(popupState)}
            //open={value}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            //trigger="click"
          >
            <FilterFromToExecutions 
            HandleChangeDate={valueDate} 
            HandleChange={HandleChange} />
          </PopOverColor>


        </>
      )}
    </PopupState>
  );
}
