import { Grid, Paper, ThemeProvider } from "@mui/material";
import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import licenceIcon from "../../assets/images/licence-top-icn.svg";
import diamond from "../../assets/images/diamond.png";
import "./License.scss";
import { pageTheme } from "../../ayudas/Themes";
import { ConvertDateUtc } from "../../ayudas/GlobalData";
import Cookies from "universal-cookie";
import axios from "axios";
import { env2 } from "../../ayudas/Instance";
import { endPoint } from "../../Services/EndPoint";
import AxiosFetch from "../../Services/FetchServices";
import { Monitor } from "@mui/icons-material";

const stylePaper = {
  height: "450px",
  width: "90%",
  margin: "5% 15px",
  background: "rgb(9, 15, 26)",
  padding: "15px 15px",
};

export default function Lincense() {
  const [Start, setStart] = useState("");
  const [Finish, setFinish] = useState("");
  const [Settings, setSettings] = useState([]);
  const [Data, setData] = useState([]);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source(),
      source2 = CancelToken.source();
      cookies.set("CurrentPageDataPagination", "13", {  path: "/",
        SameSite: "none",
        secure: true,});

    async function GetData() {
      const url = env2.api + endPoint.enviroments;
      const response = await AxiosFetch("GET", url, source);
      if (response?.msg && response.msg.query) {
        response.msg.result.forEach((element) => {
          if (element.name === "SOCKET") {
            element.settings.forEach((setting) => {
              if (setting.variable === "noAgents") {
                setSettings(setting.value);
              }
            });
          }
        });
      }
    }
    async function GetData2() {
      const url = env2.api + endPoint.licenseData;
      const response = await AxiosFetch("GET", url, source);
      if (response?.msg && response.msg.query) {
        setData(response.msg.result.AplicationInformation);
        let data = ConvertDateUtc(
          response.msg.result.AplicationInformation.LicenseRealiseDate
        );
        let data2 = ConvertDateUtc(
          response.msg.result.AplicationInformation.LicenseEndDate
        );
        setStart(data);
        setFinish(data2);
      }
    }
    GetData();
    GetData2();

    return () => {
      source.cancel();
      source2.cancel();
    };
  }, [cookies]);

  return (
    <ThemeProvider theme={pageTheme}>
      <Box style={{ width: "100%" }}>
        <Typography variant="pageTitle">
          <img src={licenceIcon} alt="" className="rt-icon-header" />
          License Details
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper sx={stylePaper}>
              <Typography variant="h5" mb={2}>
                License Information
              </Typography>
              <Typography variant="subtitle1" color={"GrayText"}>
                The license you purchase will allow you to do the following
              </Typography>
              <Typography
                width={"60%"}
                fontSize={70}
                textAlign={"center"}
                color="#c59d48"
                alignContent={"center"}
                backgroundColor="#c59d4866"
                borderRadius={"16px"}
                marginLeft={"20%"}
                marginTop={"15px"}
                marginBottom={"15px"}
                height={"100px"}
              >
                <Grid container spacing={0}>
                  <Grid item xs={6} textAlign={"center"}>
                    <Monitor
                      sx={{
                        fontSize: "100px",
                        color: "white",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} textAlign={"center"}>
                    {Settings}
                  </Grid>
                </Grid>
              </Typography>
              <Typography variant="subtitle1" color={"GrayText"} mb={2}>
                when connected at the same time
              </Typography>
              <Typography variant="h6">License Realise Date:</Typography>
              <Typography color="#c59d48" mb={2}>
                {Start}
              </Typography>
              <Typography variant="h6">License End Date: </Typography>
              <Typography color="#c59d48" mb={2}>
                {Finish}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={stylePaper}>
              <Typography variant="h5" mb={2} textAlign={"center"}>
                Level of License
              </Typography>
              <Typography align="center" style={{ padding: "10px" }}>
                <img src={diamond} alt="" style={{ width: "50%" }} />
              </Typography>
              <Typography
                align="center"
                style={{ paddingTop: "16px", color: "#c59d48" }}
              >
                {Data.LicenseInstalled}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* <Box className="Body-box-style">
          <Table>
            <TableHead
              style={{
                backgroundColor: "rgb(36, 41, 51)",
                borderRadius: "12px",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    borderBottom: "none",
                    backgroundColor: "#090f1a",
                    width: "30%",
                  }}
                >
                  <Typography style={{ color: myColor.VeryLight }}>
                    Max No. Endpoints
                  </Typography>
                  <Box className="Table-box-style">
                    <Typography
                      align="center"
                      style={{ color: myColor.VeryLight }}
                    >
                      <br />
                    </Typography>
                    <CardContent
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography
                        style={{ padding: "30px", color: myColor.VeryLight }}
                      >
                        {Settings}
                      </Typography>
                      <img src={monitorIcon} alt="" />
                    </CardContent>
                  </Box>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    backgroundColor: "#090f1a",
                    width: "30%",
                  }}
                >
                  <Typography style={{ color: myColor.VeryLight }}>
                    License Level
                  </Typography>
                  <Box className="Table-box-style">
                    <Typography align="center" style={{ padding: "10px" }}>
                      <img src={diamond} alt="" />
                    </Typography>
                    <Typography
                      align="center"
                      style={{ paddingTop: "16px", color: myColor.VeryLight }}
                    >
                      {Data.LicenseInstalled}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    backgroundColor: "#090f1a",
                    width: "30%",
                  }}
                >
                  <Typography style={{ color: myColor.VeryLight }}>
                    Subscription
                  </Typography>
                  <Box className="Table-box-style">
                    <br />
                    <Box style={{ marginLeft: "60px", marginTop: "13px" }}>
                      <TableRow>
                        <TableCell
                          style={{
                            color: myColor.VeryLight,
                            borderBottom: "none",
                            borderRight: "solid",
                            width: "50%",
                          }}
                        >
                          {"Start Date: "}
                          <br />
                          {Start}
                          <br />
                        </TableCell>
                        <TableCell
                          style={{
                            color: myColor.VeryLight,
                            borderBottom: "none",
                            width: "50%",
                          }}
                        >
                          {"Expiration: "}
                          <br />
                          {Finish}
                          <br />
                        </TableCell>
                      </TableRow>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Box> */}
      </Box>
    </ThemeProvider>
  );
}
