import React, { useEffect, useState } from "react";

import { Description } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  PrimaryButton,
} from "../../../../ayudas/Themes";
import CreatedNewScript from "./CreatedNewScript/CreatedNewScript";
import { ListItems } from "../../../../ayudas/StylesComponents/StylesComponents";
import CreatedNewEmulationScript from "./CreatedNewEmulationScript/CreatedNewEmulationScript";

export default function HeaderScriptPage(props) {
  const { DataUser, ReloadAgents } = props;

  const [openModalUploadScript, setOpenModalUploadScript] = useState(false);
  const [openModalNewEmulationScript, setOpenModalNewEmulationScript] =
    useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [reloadTable, setreloadTable] = useState(0);

  useEffect(() => {
    if (ReloadAgents !== 0 && ReloadAgents !== undefined) {
      setreloadTable(ReloadAgents);
    }
  }, [ReloadAgents]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    props.handleListItemClickMain(index);
  };

  const HandleReload = (value) => {
    props.HandleReload(value);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="pageTitle">
            <Description
              className="rt-icon-header"
              style={{ color: "#c59d48" }}
            />
            Custom Threat Validation
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <ListItems>
            <Box
              classes=""
              xs={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
            >
              <List
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0",
                  borderRadius: "8px",
                }}
                component="nav"
                aria-label="primary"
              >
                <ListItemButton
                  style={{
                    borderRadius: "8px 0px 0px 8px",

                    border: "1px solid #464366",
                    textAlign: "center",
                  }}
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemText primary="Library" />
                </ListItemButton>
                <Divider />

                <ListItemButton
                  style={{
                    borderRadius: "0px 8px 8px 0px",
                    border: "1px solid #464366",
                    textAlign: "center",
                  }}
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                >
                  <ListItemText primary="Emulations" />
                </ListItemButton>
              </List>
            </Box>
          </ListItems>
        </Grid>
        <Grid item xs={5}>
          {selectedIndex === 2 ? (
            <PrimaryButton
              style={{ float: "right" }}
              onClick={setOpenModalNewEmulationScript.bind(true)}
            >
              New Emulation
            </PrimaryButton>
          ) : (
            <PrimaryButton
              style={{ float: "right" }}
              onClick={setOpenModalUploadScript.bind(true)}
            >
              New Custom Threat
            </PrimaryButton>
          )}
          <CreatedNewEmulationScript
            getOpenModal={openModalNewEmulationScript}
            DataUser={DataUser}
            HandleReload={HandleReload}
            ReloadTableAgents={reloadTable}
          ></CreatedNewEmulationScript>
          <CreatedNewScript
            getOpenModal={openModalUploadScript}
            DataUser={DataUser}
            HandleReload={HandleReload}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
