import React, { useState, useEffect } from "react";
import { DesactivateExecutionAsync } from "../../../../Services/Executions";
import GeneralModalDelete from "../../../../ayudas/CommonComponents/GeneralDeleteModal";

export default function PopUpDeleteScheduled(props) { 
  const {GetOpenModal, idExecution}=props;
  const [open, setOpen] = useState(GetOpenModal);
  const [idExecutionData, setIdExecutionData] = useState(idExecution);

  const handleClose = () => {
    setOpen(false)
    props.effectModal(false)
  };

  useEffect(() => {
    setOpen(GetOpenModal);
    return () => {
      setOpen(GetOpenModal);
    }
  }, [GetOpenModal])

  useEffect(() => {
    setIdExecutionData(idExecution);
    return () => {
      setIdExecutionData(idExecution);
    }
  }, [idExecution])

  const DeleteExecutionById = async (id)=>{
    await DesactivateExecutionAsync(id);
    props.effectModal(false);
    props.effectRefresh();
  }

  return (
    <div>
      <GeneralModalDelete
        ExternalOpen={open}
        HeaderText=""
        BodyText="Are you sure to deleted emulation?"
        CloseAction={handleClose}
        PrimaryAction={DeleteExecutionById.bind(this,idExecutionData)}
        CancelAction={handleClose}
      />   
    </div>
  );
}
