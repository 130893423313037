import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import moment from "moment";

export default function FilterFromToExecutions(props) {
  const { HandleChange } = props;
  const today = dayjs(moment());
  const more = today.add(1, "day");

  const [value, setValue] = useState([more, more]);

  useEffect(() => {
    if (value[0] <= today && value[1] <= today) HandleChange(value);
  }, [HandleChange, value, today]);

  return (
    <Box
      style={{
        color: "white",
        padding: "20px",
        margin: "20px",
        backgroundColor: "#1e1c3a",
        borderRadius: "10px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          value={value}
          onChange={(newValue) => setValue(newValue)}
          maxDate={today}
        />
      </LocalizationProvider>
    </Box>
  );
}
