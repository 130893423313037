import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import { MenuItem, Select, Typography } from "@mui/material";
import execimg from "../../../../../assets/images/execution-icon-light.svg";

import { FormInputTextField } from "../../../../../ayudas/Themes";

export default function StepNameExecution(props) {
  const { ValueNameExecution,safeDownloadExternal } = props;

  const [nameExecution, setnameExecution] = useState(ValueNameExecution);
  const [safeDownload, setSafeDownload] = useState(safeDownloadExternal);
  useEffect(() => {
    setnameExecution(nameExecution);
    setSafeDownload(safeDownloadExternal)
  }, [nameExecution,safeDownloadExternal]);

  const HandleOnChange = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== " ") {
        props.handleNameExecution(e.target.value);
        setnameExecution(e.target.value);
      } else {
        setnameExecution("");
      }
    }
  };

  const HandleSafe = (value) => {
    setSafeDownload(value);
    props.handleSafeDownload(value);
  };
  return (
    <Box
      style={{
        width: "100%",
        height: "510px",
        backgroundColor: "rgb(36, 41, 51)",
        border: "1px solid #1e1c3a",
        padding: "15px",
        boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
        borderRadius: "12px",
      }}
    >
      <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
        Name emulation
      </Typography>
      <FormInputTextField
        style={{ width: "600px" }}
        onChange={(e) => {
          HandleOnChange(e);
        }}
        //required
        id="outlined-basic"
        label="Name emulation*"
        variant="outlined"
        type="text"
        value={nameExecution}
      />
      <Typography sx={{ mt: 2, mb: 1, textAlign: "center", fontWeight: "600" }}>
        Do you want to activate the secure download?
      </Typography>
      <Select
        style={{ width: "50px" }}
        onChange={(e) => {
          HandleSafe(e.target.value);
        }}
        //required
        id="outlined-basic"
        label="SSL enable"
        variant="outlined"
        type="text"
        value={safeDownload}
      >
        <MenuItem value={"n"}>No</MenuItem>
        <MenuItem value={"Y"}>Yes</MenuItem>
      </Select>
      <Box style={{ marginTop: "35px", textAlign: "center", fontSize: "35px" }}>
        <img style={{ width: "230px" }} src={execimg} alt="" />
      </Box>
    </Box>
  );
}
