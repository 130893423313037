import {
  CheckCircle,
  Delete,
  Download,
  InstallDesktopTwoTone,
} from "@mui/icons-material";
import { PrimaryButton } from "../Packages/PackageTheme";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  Modal,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import WindowsIcon from "../../assets/images/window-icon.svg";
import LinuxIcon from "../../assets/images/linux-icon.svg";
import axios from "axios";
import { env2 } from "../../ayudas/Instance";
import { endPoint } from "../../Services/EndPoint";
import AxiosFetch from "../../Services/FetchServices";
import { downloadFileInsider } from "../../Services/FetchRequests";
import {
  addAgent,
  deleteAgentAsync,
  deleteOldAgentAsync,
  downloadAuthFileAsync,
} from "../../Services/Agent";
import GeneralModalDelete from "../../ayudas/CommonComponents/GeneralDeleteModal";
import { ActionButton } from "../../ayudas/Themes";
import FileUpload from "../../ayudas/CommonComponents/FileUpload";
import { rolValidation } from "../../ayudas/ScriptsFunctions";
import { enqueueSnackbar } from "notistack";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function DeleteOldmodal(props) {
  const { Row } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deletelogic = () => {
    deleteOldAgentAsync(
      localStorage.getItem("token"),
      Row?._id ? Row._id : Row.id
    );
    handleClose();
    props.Reload();
  };

  const body = (
    <Box
      className="Section"
      style={{
        width: "400px",
        margin: "auto",
        backgroundColor: "#090f1a",
        border: "1px solid #1e1c3a",
      }}
    >
      <GeneralModalDelete
        ExternalOpen={open}
        HeaderText=""
        BodyText="Do you want to delete this installer?"
        CloseAction={handleClose}
        PrimaryAction={deletelogic}
        CancelAction={handleClose}
      />
    </Box>
  );

  return (
    <Box style={{ display: "inline-block" }}>
      <Tooltip title="Delete File">
        <IconButton onClick={handleOpen}>
          <Delete color="inherit" />
        </IconButton>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </Box>
  );
}

function UnpublishModal(props) {
  const { Row } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UnpublishEvent = () => {
    deleteAgentAsync(localStorage.getItem("token"), Row);
    handleClose();
    props.Reload();
  };

  const body = (
    <Box
      className="Section"
      style={{
        width: "400px",
        margin: "auto",
        backgroundColor: "#090f1a",
        border: "1px solid #1e1c3a",
      }}
    >
      <GeneralModalDelete
        ExternalOpen={open}
        HeaderText="Delete Lastet Previous Version"
        BodyText="This action will revert to the previous version and remove the most recent version of the installer, do you want to perform this action?"
        CloseAction={handleClose}
        PrimaryAction={UnpublishEvent}
        CancelAction={handleClose}
      />
    </Box>
  );

  return (
    <Box style={{ display: "inline-block" }}>
      <Tooltip title="Delete Latest Previous Version">
        <IconButton onClick={handleOpen}>
          <Delete color="inherit" />
        </IconButton>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </Box>
  );
}
export default function Installers() {
  const [openDownloadAgents, setopenDownloadAgents] = useState(false);
  const OpenAgents = () => {
    setopenDownloadAgents(true);
  };
  const CloseModal = () => {
    setLinuxData({});
    setWindowsData({});
    setcheckInstaller(false);
    setcheckConfigurationFile(true);
    setProgressDownload(false);
    setfinishDownload(false);
    setopenDownloadAgents(false);
    setValue(0);
  };
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    if (exitsInstaller) {
      setValue(newValue);
      if (newValue === 2) {
        setName("");
        setVersion("");
        setClean(true);
      }
    } else {
      setValue(2);
    }
  };
  const [distribution, setDistribution] = useState("Windows");
  const [exitsInstaller, setExitsInstaller] = useState(true);
  const [checkInstaller, setcheckInstaller] = useState(false);
  const [checkConfigurationFile, setcheckConfigurationFile] = useState(true);
  const [LinuxData, setLinuxData] = useState({});
  const [WindowsData, setWindowsData] = useState({});
  const [signalReload, setSignalReload] = useState(0);
  const [progressDownloand, setProgressDownload] = useState(false);
  const [finishDownload, setfinishDownload] = useState(false);

  const [oldInsiders, setoldInsider] = useState([]);
  const [Clean, setClean] = useState(false);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (openDownloadAgents) {
      if (value === 0) {
        const url = env2.api + endPoint.agents;
        async function GetDataLastVersions() {
          const response = await AxiosFetch("GET", url, source);
          if (response && response.msg.query) {
            response.msg.result.forEach((element) => {
              if (element.platform === "Windows") {
                setWindowsData({
                  id: element._id,
                  name: element.name,
                  version: element.version,
                  platform: "Windows",
                });
              } else if (element.platform === "GNULinux") {
                setLinuxData({
                  id: element._id,
                  name: element.name,
                  version: element.version,
                  platform: "GNULinux",
                });
              }
            });
          } else {
            enqueueSnackbar(
              response?.msg?.result ? response.msg.result : "Without result",{variant:"info"}
            );
            setExitsInstaller(false);
            setValue(2);
          }
        }
        GetDataLastVersions();
      }
    }
    return () => {
      if (!openDownloadAgents) {
        source.cancel();
      }
    };
  }, [openDownloadAgents, value]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (value === 1) {
      let version = distribution === "Windows" ? WindowsData.id : LinuxData.id;
      let url = env2.api + endPoint.oldAgents + version;
      async function GetOldVersions() {
        const response = await AxiosFetch("GET", url, source);
        if (response?.msg?.result && Array.isArray(response.msg.result)) {
          setoldInsider(response.msg.result);
        } else {
          setoldInsider([]);
        }
      }
      GetOldVersions();
    }

    return () => {
      if (!openDownloadAgents) {
        source.cancel();
      }
    };
  }, [
    openDownloadAgents,
    signalReload,
    value,
    distribution,
    LinuxData.id,
    WindowsData.id,
  ]);

  const DownloadOlds = async (id) => {
    let body = {
      installer: id,
      configFile: "Y",
    };
    setProgressDownload(true);
    await downloadFileInsider(
      localStorage.getItem("token"),
      env2.api + endPoint.downloadAgent,
      "Insider " + distribution + ".zip",
      body
    );
  };

  const DownloadService = async () => {
    if (checkConfigurationFile && checkInstaller) {
      let body = {
        installer: distribution === "Windows" ? WindowsData.id : LinuxData.id,
        configFile: "Y",
      };
      setProgressDownload(true);
      await downloadFileInsider(
        localStorage.getItem("token"),
        env2.api + endPoint.downloadAgent,
        "Insider " + distribution + ".zip",
        body
      );
      setfinishDownload(true);
    } else {
      if (checkConfigurationFile) {
        setProgressDownload(true);
        await downloadAuthFileAsync(
          localStorage.getItem("token"),
          distribution === "Windows" ? WindowsData.id : LinuxData.id
        );
        setfinishDownload(true);
      } else {
        let body = {
          installer: distribution === "Windows" ? WindowsData.id : LinuxData.id,
          configFile: "n",
        };
        setProgressDownload(true);

        await downloadFileInsider(
          localStorage.getItem("token"),
          env2.api + endPoint.downloadAgent,
          "Insider " + distribution + ".zip",
          body
        );
        setfinishDownload(true);
      }
    }
    setTimeout(() => {
      CloseModal();
    }, 5500);
  };
  const [artefactFile, setartefactFile] = useState("");
  const [Name, setName] = useState("");
  const [version, setVersion] = useState("");
  const [Load, setLoad] = useState(false);

  const handleArtefactfile = (e) => {
    if (e.files[0]) {
      const startindex = e.files[0].name.indexOf("(");
      const endIndex = e.files[0].name.indexOf(")");
      if (startindex !== -1) {
        let version = e.files[0].name.substring(startindex + 1, endIndex);
        setVersion(version);
      }
      if (distribution === "Windows") {
        let ext = e.files[0].name.split(".");

        if (ext[ext.length - 1] === "msi") {
          setartefactFile(e.files[0]);
        } else {
          enqueueSnackbar("Extension no support",{variant:"info"});
          setName("");
          setVersion("");
          setClean(true);
        }
      } else {
        let ext = e.files[0].name.split(".");
        if (ext.includes("tar") || ext.includes("xz")) {
          setartefactFile(e.files[0]);
        } else {
          enqueueSnackbar("Extension no support",{variant:"info"});
          setName("");
          setVersion("");
          setClean(true);
        }
      }
    }
  };
  const uploadFile = () => {
    setLoad(true);
    var file = artefactFile;
    if (file && Name !== "" && version !== "") {
      const agentData = {
        file: file,
        name: Name,
        version: version,
        platform: distribution === "Windows" ? distribution : "GNULinux",
      };
      if (WindowsData?.id || LinuxData?.id) {
        agentData.id =
          distribution === "Windows" ? WindowsData.id : LinuxData.id;
      }
      const response = addAgent(agentData);
      if (response) {
        setLoad(false);
        setValue(0);
      }
    } else {
      enqueueSnackbar("Please enter all fields *",{variant:"warning"});
      setLoad(false);
    }
  };

  return (
    <>
      <PrimaryButton disabled={rolValidation()} variant="outlined" onClick={OpenAgents}>
        <InstallDesktopTwoTone style={{ color: "#fff", marginRight: "7px" }} />
        Insiders
      </PrimaryButton>
      <Modal open={openDownloadAgents} onClose={CloseModal}>
        <Box
          className="Section"
          style={{
            backgroundColor: "rgb(10, 8, 24)",
            border: "1px solid #090f1a",
            boxShadow: "18px 4px 35px rgb(0 0 0 / 15%)",
            borderRadius: "14px",
            width: "400px",
            minHeight: "600px",
            display: "inline",
          }}
        >
          <Typography
            variant="h4"
            display={"flex"}
            width={"100%"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <InstallDesktopTwoTone
              style={{ fontSize: "35px", color: "#fff", marginRight: "15px" }}
            />
            Insiders
          </Typography>
          <Divider />
          <Box mt={2} mb={2} ml={2}>
            <Typography variant="subtitle1">
              Distribution :
              <Button
                onClick={() => {
                  setDistribution("Windows");
                }}
                sx={{
                  height: "40px",
                  width: "80px",
                  border: "1px solid #0000ff66",
                  backgroundColor: "#0000ff99",
                  opacity: distribution === "Windows" ? 1 : 0.3,
                  color: "#fff",
                  borderRadius: "16px",
                  margin: "0px 7px",
                }}
              >
                <img src={WindowsIcon} alt="" />
              </Button>
              <Button
                onClick={() => {
                  setDistribution("Linux");
                }}
                sx={{
                  height: "40px",
                  width: "80px",
                  border: "1px solid #bc50fa66",
                  backgroundColor: "#bc50fa99",
                  opacity: distribution === "Linux" ? 1 : 0.3,
                  color: "#fff",
                  borderRadius: "16px",
                  margin: "0px 7px",
                }}
              >
                <img src={LinuxIcon} alt="" />
              </Button>
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "#090f1a",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Last Version" {...a11yProps(0)} />
              <Tab label="Old Versions" {...a11yProps(1)} />
              <Tab label="Upload" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {/* Download lastVersion */}
            <Box
              sx={{
                alignContent: "start",
                borderRadius: "16px",
                height: "400px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Typography variant="h5" mb={3}>
                Last version
              </Typography>
              {distribution === "Windows" && (
                <Button
                  disabled={!exitsInstaller || !WindowsData?.name}
                  sx={{
                    width: "80%",
                    height: "120px",
                    border: "1px solid #0000ff66",
                    backgroundColor: "#0000ff33",
                    color: "#fff",
                    borderRadius: "16px",
                  }}
                  onClick={DownloadService}
                >
                  {finishDownload ? (
                    <Grid container spacing={0}>
                      <Grid item xs={4} textAlign={"center"}>
                        <CheckCircle
                          sx={{ fontSize: "80px" }}
                          color="primary"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography textAlign="center" variant="caption">
                          File Ready
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : progressDownloand ? (
                    <Grid container spacing={0}>
                      <Grid item xs={4} textAlign={"center"}>
                        <CircularProgress color="primary" />
                      </Grid>
                      <Grid item md={8} alignContent={"center"}>
                        <Typography textAlign="center" variant="caption">
                          Preparing file, please wait
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={0} mt={2}>
                      <Grid item xs={4}>
                        <img
                          src={WindowsIcon}
                          style={{ width: "50px" }}
                          alt=""
                        ></img>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        color="#c59d48"
                        borderRadius={"16px"}
                        alignContent={"center"}
                      >
                        <Typography variant="caption">
                          {WindowsData.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          Click to download
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Button>
              )}
              {distribution === "Linux" && (
                <Button
                  disabled={!exitsInstaller || !LinuxData?.name}
                  sx={{
                    width: "80%",
                    height: "120px",
                    border: "1px solid #bc50fa66",
                    backgroundColor: "#bc50fa33",
                    color: "#fff",
                    borderRadius: "16px",
                  }}
                  onClick={DownloadService}
                >
                  {finishDownload ? (
                    <Grid container spacing={3} style={{ height: "100%" }}>
                      <Grid item xs={4} textAlign={"center"}>
                        <CheckCircle color="secondary" />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography textAlign="center" variant="caption">
                          File Ready
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : progressDownloand ? (
                    <Grid container spacing={0}>
                      <Grid item xs={4} textAlign={"center"}>
                        <CircularProgress color="secondary" />
                      </Grid>
                      <Grid item md={8}>
                        <Typography textAlign="center" variant="caption">
                          Preparing file, please wait
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <img
                          src={LinuxIcon}
                          style={{ width: "50px" }}
                          alt=""
                        ></img>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        color="#c59d48"
                        borderRadius={"16px"}
                        alignContent={"center"}
                      >
                        <Typography variant="caption">
                          {LinuxData.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          Click to download
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Button>
              )}
              <Typography mt={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={
                          distribution === "Windows" ? "primary" : "secondary"
                        }
                        style={{ marginLeft: "80px" }}
                        value={checkInstaller}
                        onChange={() => {
                          setcheckInstaller(!checkInstaller);
                        }}
                      />
                    }
                    label="Executive Insider"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={
                          distribution === "Windows" ? "primary" : "secondary"
                        }
                        style={{ marginLeft: "80px" }}
                        value={checkConfigurationFile}
                        defaultChecked
                        onChange={() => {
                          setcheckConfigurationFile(!checkConfigurationFile);
                        }}
                      />
                    }
                    label="Configuration File"
                  />
                </FormGroup>
              </Typography>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {/* Table LastVersion */}
            <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Version</TableCell>
                    <TableCell>Download</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {distribution === "Windows"
                        ? WindowsData.version
                        : LinuxData.version}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          DownloadOlds(
                            distribution === "Windows"
                              ? WindowsData.id
                              : LinuxData.id
                          );
                        }}
                      >
                        <Download />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton>
                        <UnpublishModal
                          Row={
                            distribution === "Windows"
                              ? WindowsData.id
                              : LinuxData.id
                          }
                          Reload={() => {
                            setValue(0);
                            setSignalReload(signalReload + 1);
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {oldInsiders.map((old) => {
                    return (
                      <TableRow>
                        <TableCell>{old.version}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              DownloadOlds(old.id);
                            }}
                          >
                            <Download />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton>
                            <DeleteOldmodal
                              Row={old}
                              Reload={() => {
                                setSignalReload(signalReload + 1);
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {/* Upload Version */}
            <Box
              sx={{
                alignContent: "start",
                borderRadius: "16px",
                height: "400px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Typography>Select a file:</Typography>
              <FileUpload
                Clean={Clean}
                CleanOff={() => {
                  setClean(false);
                }}
                ArtifactType={true}
                HandleFile={handleArtefactfile}
              />

              <Divider />
              <Input
                type="text"
                placeholder="Name"
                value={Name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                id="NameAgent"
                fullWidth
                variant="filled"
                style={{ marginTop: "15px", color: "#fff" }}
              ></Input>

              <Input
                type="text"
                placeholder="Version"
                value={version}
                onChange={(e) => {
                  setVersion(e.target.value);
                }}
                id="VersionAgent"
                fullWidth
                variant="filled"
                style={{ marginTop: "15px", color: "#fff" }}
              ></Input>
              <ActionButton disabled={Load} onClick={uploadFile}>
                {Load ? "Loading" : "Load"}
              </ActionButton>
            </Box>
          </CustomTabPanel>
        </Box>
      </Modal>
    </>
  );
}
