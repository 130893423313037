import { useState, useEffect } from "react";
import { ArrowBackIos, ArrowForwardIos, Check } from "@mui/icons-material";
import reximg from "../../../../../assets/images/t-rex-wr.gif";
import {
  Badge,
  Grow,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import failTableIcon from "../../../../../assets/images/fail-table-icon.svg";
import PackageIcon from "../../../../../assets/images/package-icon-white.svg";
import "./SelectPack.scss";
import { getPackagePageAdvancedAsync } from "../../../../../Services/Package";
import { STATUS_TAG } from "../../../../../ayudas/Themes";
import { enqueueSnackbar } from "notistack";

const PACKAGE_TYPE_TAG = {
  Known: { label: "Known", color: "#8c30ff", className: "PackageType Known" },
  Challenger: {
    label: "Challenger",
    color: "#ff7e35",
    className: "PackageType Challenger",
  },
  Interactiva: {
    label: "Interactive",
    color: "#fff",
    className: "PackageType Interactiva",
  },
  Unknown: {
    label: "Unknown",
    color: "#ffab2d",
    className: "PackageType Unknown",
  },
  Zero: { label: "Zero", color: "#ff4747", className: "PackageType Zero" },
  undefined: {
    label: "Undefined",
    color: "#fff",
    className: "PackageType Undefined",
  },
  Uncategorized: {
    label: "Uncategorized",
    color: "#ffab2d",
    className: "PackageType Unknown",
  },
};
export default function StepSelectPackages(props) {
  const { ValuesCheckedPackages, ValuesCheckedPackagesValues } = props;

  const [FilterName, setFilterName] = useState(null);
  const [pagePakcs, setPagePacks] = useState(0);
  const [NoPagesPacks, setNoPagesPacks] = useState(0);
  const [rowsPerPagePacks, setRowsPerPagePacks] = useState(5);
  const [NoPacks, setNoPacks] = useState(0);
  const [tempRowsForPacks, settempRowsForPacks] = useState([]);
  const [checked, setChecked] = useState(ValuesCheckedPackages);
  const [packlist, setpacklist] = useState(ValuesCheckedPackagesValues);
  const [checkedGrow, setCheckedGrow] = useState(false);
  const [ShowPackage, setShowPackage] = useState(false);

  useEffect(() => {
    const GetData = async () => {
      let filterData = {};
      if (FilterName !== "") filterData.name = FilterName;
      const response = await getPackagePageAdvancedAsync(
        rowsPerPagePacks,
        pagePakcs + 1,
        "lastModification",
        "desc",
        filterData
      );

      if (response && response?.info) {
        let array = [];
        response.packages?.forEach((element) => {
          array.push(element);
        });
        let NoTotal = response.info?.neto;
        let NoPages = Math.ceil(NoTotal / rowsPerPagePacks);
        setNoPacks(NoTotal);
        setNoPagesPacks(NoPages);
        settempRowsForPacks(array);
        setCheckedGrow(true);
      } else {
        enqueueSnackbar("Without results",{variant:"info"});
        setNoPacks(0);
        setNoPagesPacks(0);
        settempRowsForPacks([]);
        setCheckedGrow(true);
      }
    };
    GetData();
  }, [pagePakcs, FilterName, rowsPerPagePacks]);

  const NewRowsPack = (event) => {
    setRowsPerPagePacks(parseInt(event.target.value, 10));
    setPagePacks(0);
  };

  const HandleFilterName = (e) => {
    if (e.target.value !== "") {
      let filter = e.target.value;
      setFilterName(filter);
    } else {
      setFilterName("");
    }
    setPagePacks(0);
  };

  const BackPagePack = () => {
    setPagePacks(pagePakcs - 1);
  };
  const NextPagePack = () => {
    setPagePacks(pagePakcs + 1);
  };

  const handleTogglePacks = (value) => () => {
    const currentIndex = checked.indexOf(value.id);
    const newChecked = [...checked];
    const listnew = [...packlist];
    if (currentIndex === -1) {
      newChecked.push(value.id);
      listnew.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      listnew.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setpacklist(listnew);
    props.handleCheckedPackages(newChecked);
    props.handleCheckedPackValues(listnew);
  };
  const handleDeletePacks = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const listnew = [...packlist];
    newChecked.splice(currentIndex, 1);
    listnew.splice(currentIndex, 1);
    setChecked(newChecked);
    setpacklist(listnew);
    props.handleCheckedPackages(newChecked);
    props.handleCheckedPackValues(listnew);
  };
  const handleShow = () => {
    setShowPackage(!ShowPackage);
  };
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        // minHeight: "440px",
        // maxHeight: "450px",
      }}
    >
      <Box
        style={{
          width: "100%",
          height: "450px",
          backgroundColor: "rgb(36, 41, 51)",
          border: "1px solid #1e1c3a",
          borderRadius: "8px",
        }}
      >
        <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
          Select Packages
        </Typography>
        <Box
          style={{
            marginTop: "10px",
            width: "100%",
            height: "50px",
            paddingLeft: "10px",
            paddingRight: "15px",
          }}
        >
          {checked.length > 0 ? (
            <>
              <Badge
                badgeContent={checked.length}
                color="secondary"
                style={{
                  zIndex: "1001",
                  float: "left",
                  marginTop: "7px",
                  marginLeft: "15px",
                }}
              >
                <IconButton
                  style={{
                    width: "35px",
                    height: "35px",
                    backgroundColor: "#c59d48",
                  }}
                  onClick={handleShow}
                >
                  <img src={PackageIcon} alt="" style={{ width: "90%" }} />
                </IconButton>
              </Badge>
              <Box
                style={{
                  display: ShowPackage ? "flex" : "none",
                  zIndex: "1000",
                  position: "fixed",
                  height: "auto",
                  width: "250px",
                  padding: "10px",
                  backgroundColor: "#11172E",
                  borderRadius: "14px",
                  border: "1px solid #fff",
                }}
              >
                <Box
                  style={{ marginTop: "45px", width: "100%", height: "auto" }}
                >
                  {packlist.length > 0
                    ? packlist.map((row, index) => (
                        <Box className="ItemList">
                          <Box
                            style={{ width: "80%", display: "inline-block" }}
                          >
                            <Tooltip title={row.name}>
                              <Typography
                                className="title"
                                style={{ marginTop: "7px" }}
                              >
                                {row.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box
                            style={{ width: "20%", display: "inline-block" }}
                          >
                            <Tooltip title={"Delete"}>
                              <IconButton onClick={handleDeletePacks(row.id)}>
                                <img
                                  src={failTableIcon}
                                  alt=""
                                  style={{
                                    marginTop: "-14px",
                                    cursor: "pointer",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      ))
                    : null}
                </Box>
              </Box>
            </>
          ) : null}
          <Select
            variant="outlined"
            onChange={NewRowsPack}
            value={rowsPerPagePacks}
            defaultValue={"Five"}
            style={{
              color: "#fff",
              //backgroundColor: "#6c757d",
              borderColor: "#090f1a",
              fontSize: "1rem",
              height: "35px",
              width: "10%",
              marginTop: "7px",
              marginLeft: "30%",
            }}
          >
            <MenuItem disabled value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>

          <input
            style={{
              border: "1px solid #464366",
              backgroundColor: "rgb(21, 19, 43)",
              borderRadius: "15px",
              height: "49px",
              fontSize: "14px",
              minWidth: "100px",
              padding: "3px 15px",
              color: "#8e97ad",
              float: "right",
              width: "30%",
            }}
            type="text"
            className="input-style-dash"
            placeholder="Search name..."
            onChange={(e) => {
              HandleFilterName(e);
            }}
          ></input>
        </Box>
        <Box
          className="arrow after"
          style={{
            display: pagePakcs + 1 === 1 || NoPacks === 0 ? "none" : "block",
          }}
          onClick={BackPagePack.bind()}
        >
          <ArrowBackIos style={{ marginTop: "60px" }} />
        </Box>

        <Box className="Contain">
          {tempRowsForPacks.length > 0 ? (
            tempRowsForPacks.map((row, index) => (
              <Grow
                in={checkedGrow}
                style={{ transformOrigin: "0 0 0" }}
                {...(checkedGrow ? { timeout: 500 * index } : {})}
              >
                <Box className="card">
                  <Tooltip title={row.name}>
                    <Typography
                      fontSize={"20px"}
                      fontWeight={"600"}
                      className="title"
                    >
                      {row.name}
                    </Typography>
                  </Tooltip>
                  <Box style={{ color: "#66638f" }}>
                    <Typography sx={{marginBottom: "7px"}}>Tag Status:</Typography>

                    <Chip
                      style={{ width: "120px" }}
                      variant="outlined"
                      label={STATUS_TAG[row.tagTest].label}
                      color={STATUS_TAG[row.tagTest].chipColor}
                    />

                    <Typography sx={{ marginTop: "5px",marginBottom:"-8px" }}>Type:</Typography>
                    <Box
                      style={{ display: "block", width: "100%" }}
                      className={PACKAGE_TYPE_TAG[row.type].className}
                    >
                      <Typography align="center">
                        {PACKAGE_TYPE_TAG[row.type].label}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        borderColor:
                          checked.indexOf(row.id) !== -1 ? "#c59d48" : "#fff",
                          marginBottom:"0px",
                      }}
                      className="checkCustom"
                      onClick={handleTogglePacks(row)}
                    >
                      {checked.indexOf(row.id) !== -1 ? (
                        <Check
                          fontSize="large"
                          style={{
                            marginTop: "4px",
                            color:
                              checked.indexOf(row.id) !== -1
                                ? "#c59d48"
                                : "#66638f",
                          }}
                        />
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </Grow>
            ))
          ) : (
            <Box>
              <Typography>Without Results</Typography>

              <img style={{ width: "200px" }} src={reximg} alt="" />
            </Box>
          )}
        </Box>
        <Box
          className="arrow before"
          style={{
            display:
              pagePakcs + 1 === NoPagesPacks || NoPacks === 0
                ? "none"
                : "block",
          }}
          onClick={NextPagePack.bind()}
        >
          <ArrowForwardIos style={{ marginTop: "60px" }} />
        </Box>
      </Box>
    </Box>
  );
}
