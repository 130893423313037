import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ThemeProvider } from "@mui/material/styles";
import { pageTheme } from "../../../../ayudas/Themes";
import PropTypes from "prop-types";
import AgentsIcon from "../../../../assets/images/lpt-icon-white.svg";
import PackageIcon from "../../../../assets/images/package-icon-white.svg";
import DateIcon from "../../../../assets/images/date-time-icon-white.svg";
import LaunchIcon from "../../../../assets/images/launch-icon-white.svg";
import ArrowBackIcon from "../../../../assets/images/arrow-bottom.svg";
import AccountIcon from "../../../../assets/images/users-icon-white.svg";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { TextIncrease } from "@mui/icons-material";

export default function StepperFromNewExecution(props) {
  const { ActiveStep } = props;

  const [activeStep, setActiveStep] = useState(ActiveStep);

  useEffect(() => {
    setActiveStep(ActiveStep);
  }, [ActiveStep]);

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <img src={DateIcon} alt="" />,
      2: <img src={ArrowBackIcon} alt="" />,
      3: <img src={PackageIcon} alt="" />,
      4: <TextIncrease />,
      5: <img src={AgentsIcon} alt="" />,
      6: <img src={AccountIcon} alt="" />,
      7: <img src={LaunchIcon} alt="" />,
    };

    ColorlibStepIcon.propTypes = {
      /**
       * Whether this step is active.
       * @default false
       */
      active: PropTypes.bool,
      className: PropTypes.string,
      /**
       * Mark the step as completed. Is passed to child components.
       * @default false
       */
      completed: PropTypes.bool,
      /**
       * The label displayed in the step icon.
       */
      icon: PropTypes.node,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconRoot = styled("Box")(({ theme, ownerState }) => ({
    backgroundColor: "#090f1a",
    border: "1.37962px solid #1e1c3a",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "13.74px",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#c59d48",
      boxShadow: "0px 13.7962px 27.5924px rgba(0, 0, 0, 0.05)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#544628",
      boxShadow: "0px 13.7962px 27.5924px rgba(0, 0, 0, 0.9)",
    }),
  }));

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    borderStyle: "dotted none none none",
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#e328af",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#c59d48",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      borderColor: "#66638f",
    },
  }));

  return (
    <ThemeProvider theme={pageTheme}>
      <Box style={{ width: "100%" }}>
        <Box
          style={{
            width: "100%",
            backgroundColor: "rgb(36, 41, 51)",
            border: "1px solid #1e1c3a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "12px",
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            <Step key={0}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Type:</Typography>
              </StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Vector:</Typography>
              </StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Packages:</Typography>
              </StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Arguments:</Typography>
              </StepLabel>
            </Step>
            <Step key={4}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Endpoint:</Typography>
              </StepLabel>
            </Step>
            <Step key={5}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Name:</Typography>
              </StepLabel>
            </Step>
            <Step key={6}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ color: "#fff" }}>Emulation:</Typography>
              </StepLabel>
            </Step>
          </Stepper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
