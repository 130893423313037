import { Box, IconButton, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { ActionButton, FormInputTextField } from "../../../ayudas/Themes";
import detailsIcon from "../../../assets/images/eye-icon.svg";
import { UpdateUrlEnviroment } from "../../../Services/Enviroment";
import { enqueueSnackbar } from "notistack";

export default function ModelURL(props) {
  const { DataSettings, Reload } = props;
  const [open, setOpen] = useState(false);
  const [C2, setC2] = useState("");
  const [EMAILRESETPASSWORD, setEMAILRESETPASSWORD] = useState("");

  const handleOpen = () => {
    if (DataSettings[0].variable === "C2") {
      setC2(DataSettings[0].value);
      setEMAILRESETPASSWORD(DataSettings[1].value);
    } else {
      setC2(DataSettings[1].value);
      setEMAILRESETPASSWORD(DataSettings[0].value);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const putUpdateData = async () => {
    let regex =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regex.exec(C2) && regex.exec(EMAILRESETPASSWORD)) {
      const data = {
        C2: C2,
        EMAILRESETPASSWORD: EMAILRESETPASSWORD,
        permanent: "Y",
      };
      await UpdateUrlEnviroment(data);
      Reload();
    } else {
      enqueueSnackbar("checks the urls as some are not valid",{variant:"error"});
    }
  };

  const body = (
    <div
      className="Section"
      style={{
        backgroundcolor: "#090f1a",
        borderBottom: "1px solid #1e1c3a",
        width: "400px",
      }}
    >
      <Typography align="center" variant="h6">
        URL parameters
      </Typography>
      <Box
        style={{
          width: "100%",
          backgroundColor: "#1e1c3a",
          border: "1px solid #1e1c3a",
          padding: "15px",
          boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
          borderRadius: "12px",
          marginTop: "24px",
          textAlign: "center",
        }}
      >
        <Typography>C2 URL</Typography>
        <FormInputTextField
          style={{ margin: "7px" }}
          value={C2}
          onChange={(event) => {
            if (event.target.value !== " ") {
              setC2(event.target.value);
            }
          }}
        />

        <Typography>Restore email password URL</Typography>
        <FormInputTextField
          style={{ margin: "7px" }}
          value={EMAILRESETPASSWORD}
          onChange={(event) => {
            if (event.target.value !== " ") {
              setEMAILRESETPASSWORD(event.target.value);
            }
          }}
        />
      </Box>

      <ActionButton
        onClick={putUpdateData}
        disabled={C2 === "" || EMAILRESETPASSWORD === ""}
        style={{ width: "70%", marginLeft: "15%" }}
      >
        Update
      </ActionButton>
    </div>
  );

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <img src={detailsIcon} alt="" />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
