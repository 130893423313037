import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  styled,
  TablePagination,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import {
  ButtonActions,
  CustomLoadingOverlay,
  FormInputTextField,
  pageTheme,
  StyledDataGrid,
} from "../../../../ayudas/Themes";
import { Clear, FilterAlt, Search } from "@mui/icons-material";
import { GridOverlay } from "@mui/x-data-grid-pro";

import { useCallback, useEffect, useState } from "react";
import { ConvertDateUtc } from "../../../../ayudas/GlobalData";
import DetailsScript from "./GeneralModals/DetailsScript/DetailsScript";
import EditScript from "./GeneralModals/EditScript/EditScript";
import DeleteModalScript from "./GeneralModals/DeleteScript/DeleteScript";
import DetailsBox from "./GeneralModals/DetailsBox/DetailsBox";
import DeleteModalBox from "./GeneralModals/DeleteBox/DeleteBox";

import WindowIcon from "../../../../assets/images/window-icon.svg";
import LinuxIcon from "../../../../assets/images/linux-icon.svg";
import Multi from "../../../../assets/multi.png";

import ReportBox from "./GeneralModals/ReportBox/ReportBox";
import LaunchBox from "./GeneralModals/LaunchBox/LaunchBox";
import { IconsLanguages } from "./assests/IconsLanguage";
import { env2 } from "../../../../ayudas/Instance";
import { endPoint } from "../../../../Services/EndPoint";
import axios from "axios";
import AxiosFetch from "../../../../Services/FetchServices";
import TablePaginationActions from "../../../../ayudas/TablePaginations";

const ButtonRows = styled(Button)(({ theme }) => ({
  margin: "5px",
  padding: "10px",
  color: theme.palette.getContrastText("#c59d48"),
  backgroundColor: "#c59d48",
  border: "1px solid #1e1c3a",
  "&:hover": {
    backgroundColor: "#c59d48",
  },
}));

const PlatformList = [
  {
    id: 1,
    name: "Windows",
    value: "Windows",
  },
  {
    id: 2,
    name: "GNULinux",
    value: "GNULinux",
  },
  {
    id: 3,
    name: "MultiPlatform",
    value: "MultiPlatform",
  },
];

const LanguajeList = [
  {
    id: 1,
    name: "Perl",
    value: "perl",
  },
  {
    id: 2,
    name: "python",
    value: "phython",
  },
  {
    id: 3,
    name: "Ruby",
    value: "ruby",
  },
  {
    id: 4,
    name: "Powershell",
    value: "powershell",
  },
  {
    id: 5,
    name: "Bash",
    value: "BATHWIN",
  },
  {
    id: 6,
    name: "Shell",
    value: "BATHLinux",
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function BodyScriptPage(props) {
  const {
    DataUser,
    handleselectListItemClickBody,
    HandleReload,
    AgentConnect,
  } = props;
  const [sortModel, setSortModel] = useState([
    { field: "updatedAt", sort: "desc" },
  ]);
  const [sortModelBox, setSortModelBox] = useState([
    { field: "updatedAt", sort: "desc" },
  ]);
  const [page, setPage] = useState(0);
  const [pageBox, setPageBox] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingBox, setLoadingBox] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageBox, setRowsPerPageBox] = useState(10);
  const [tempRows, settempRows] = useState([]);

  const [ExecutionTemp, setExecutionTemp] = useState([]);
  const [FilterName, setFilterName] = useState("");
  const [FilterScriptName, setFilterScriptName] = useState("");
  const [FilterNameBox, setFilterNameBox] = useState("");

  const [FilterStatus, setFilterStatus] = useState([]);
  const [FilterSource, setFilterSource] = useState("");
  const [FilterLanguaje, setFilterLanguaje] = useState([]);
  const [FilterPlatform, setFilterPlatform] = useState("");
  const [rowCount, setrowCount] = useState(0);
  const [rowCountBox, setrowCountBox] = useState(0);
  const [lastValuePage, setLastValuePage] = useState(0);

  const [selectedIndex, setSelectedIndex] = useState(
    handleselectListItemClickBody
  );
  const [showEl, setShowel] = useState(null);

  const [signalReload, setsignalReload] = useState(0);
  const [signalReloadBox, setsignalReloadBox] = useState(0);

  const [selectionModel, setSelectionModel] = useState([]);
  const [selectionModelBox, setSelectionModelBox] = useState([]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLastValuePage(0);
    setPage(0);
  };

  const handleChangeRowsPerPageBox = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLastValuePage(0);
    setPage(0);
  };
  const ConstructorBody = useCallback(() => {
    let objFormData = {};

    if (FilterName) {
      objFormData.name = FilterName;
    }
    if (FilterScriptName) {
      objFormData.scriptName = FilterScriptName;
    }
    if (FilterStatus.length > 0) {
      objFormData.source = FilterStatus;
    }
    if (FilterPlatform) {
      objFormData.platform = FilterPlatform;
    }

    if (FilterLanguaje.length > 0) {
      objFormData.language = FilterLanguaje;
    }
    return JSON.stringify(objFormData);
  }, [
    FilterLanguaje,
    FilterName,
    FilterScriptName,
    FilterPlatform,
    FilterStatus,
  ]);

  const ConstructorBodyBox = useCallback(() => {
    let objFormData = {};
    if (FilterNameBox !== "") {
      objFormData.name = FilterNameBox;
    }
    return JSON.stringify(objFormData);
  }, [FilterNameBox]);

  useEffect(() => {
    setSelectedIndex(handleselectListItemClickBody);
  }, [handleselectListItemClickBody]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      const baseUrl = `${env2.api}${endPoint.advancePaginationScript}`;
      const paginationParams = `${rowsPerPage <= 0 ? 10 : rowsPerPage}/${
        page <= 0 ? 1 : page + 1
      }`;
      const sortParams =
        sortModel.length > 0
          ? `col=${sortModel[0].field}&order=${sortModel[0].sort}`
          : `col=updatedAt&order=desc`;

      const url = `${baseUrl}${paginationParams}?${sortParams}`;

      setLoading(true);
      const data = await AxiosFetch("POST", url, source, ConstructorBody());
      if (data) {
        setLoading(false);        
      }
      const response = data?.msg ? data.msg : null;
      if (response?.result?.scripts && Array.isArray(response.result.scripts)) {
        let dataScripts = response.result;
        let array = [];
        response.result.scripts.forEach((element) => {
          array.push({
            id: element._id,
            scriptName: element.scriptName,
            platform: element.platform,
            language: element.language,
            source: element.source,
            name: element.name,
            tagTest: element.tagTest,
            createdBy: element?.createdBy ? element.createdBy : "",
            updatedAt: ConvertDateUtc(element.updatedAt),
            actions: element.file,
          });
        });
        settempRows(array);
        setrowCount(dataScripts.info?.neto);
        setLoading(false);
      } else {
        settempRows([]);
        setrowCount(0);
        setLoading(false);
      }
    }
    GetData();

    return () => {
      source.cancel();
    };
  }, [
    page,
    rowsPerPage,
    sortModel,
    FilterLanguaje,
    FilterName,
    FilterScriptName,
    FilterPlatform,
    FilterStatus,
    signalReload,
    HandleReload,
    ConstructorBody,
  ]);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      const baseUrl = `${env2.api}${endPoint.advancePaginationBox}`;
      const paginationParams = `${rowsPerPageBox <= 0 ? 10 : rowsPerPageBox}/${
        pageBox <= 0 ? 1 : pageBox + 1
      }`;
      const sortParams =
        sortModelBox.length > 0
          ? `col=${sortModelBox[0].field}&order=${sortModelBox[0].sort}`
          : `col=updatedAt&order=desc`;

      const url = `${baseUrl}${paginationParams}?${sortParams}`;
      setLoadingBox(true);
      const data = await AxiosFetch("POST", url, source, ConstructorBodyBox());
      if (data) {
        setLoadingBox(false);        
      }
      const response = data?.msg ? data.msg : null;
      if (response?.result) {
        let dataBoxScript = response.result;
        let array = [];
        let data = dataBoxScript?.scripts ? dataBoxScript.scripts : [];
        data.forEach((element) => {
          array.push({
            id: element._id,
            name: element.name,
            description: element.description,
            updatedAt: ConvertDateUtc(element.updatedAt),
            status: element.status,
            createdBy: element?.createdBy ? element.createdBy : "-- --",
            executeBy: element?.lastRunBy ? element.lastRunBy : "-- --",
            actions: element.file,
            noClients: element.fk_client.length,
            noScripts: element.fk_script.length,
          });
        });
        setExecutionTemp(array);
        setrowCountBox(dataBoxScript.info?.neto ? dataBoxScript.info?.neto : 0);
        setLoadingBox(false);
      } else {
        setExecutionTemp([]);
        setrowCountBox(0);
        setLoadingBox(false);
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [
    pageBox,
    rowsPerPageBox,
    sortModelBox,
    FilterNameBox,
    HandleReload,
    signalReloadBox,
    ConstructorBodyBox,
  ]);

  const Reload = () => {
    setsignalReload(signalReload + 1);
  };
  const ReloadBox = () => {
    setPageBox(0);
    setsignalReloadBox(signalReloadBox + 1);
  };
  const HandleFilterName = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== "") {
        setFilterName(e.target.value);
      } else {
        setFilterName("");
      }
    }
  };
  const HandleFilterScriptName = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== "") {
        setFilterScriptName(e.target.value);
      } else {
        setFilterScriptName("");
      }
    }
  };
  const HandleFilterNameBox = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== "") {
        setFilterNameBox(e.target.value);
      } else {
        setFilterNameBox("");
      }
    }
  };

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };
  const handleSortModelChangeBox = (newModel) => {
    setSortModelBox(newModel);
  };

  const Execute = (value) => {
    props.Execute(value);
  };

  const Columns = [
    {
      field: "platform",
      headerName: "Platform",
      filterable: false,
      flex: 0.2,
      align: "center",

      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Platform</Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          {params.row.platform === "Windows" ? (
            <img src={WindowIcon} alt="" />
          ) : params.row.platform === "GNULinux" ? (
            <img src={LinuxIcon} alt="" />
          ) : (
            <>
              <img src={Multi} alt="" style={{ width: "40px" }} />
            </>
          )}
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Threat",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <DetailsScript
          Choose={true}
          ScriptData={params}
          DataUser={DataUser}
          Reload={Reload}
        />
      ),
    },
    {
      field: "scriptName",
      headerName: "Alias",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "language",
      headerName: "Language",
      flex:.6,
      filterable: false,
      minWidth: 10,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Language</Box>
      ),
      renderCell: (params) => (
        <>
          {
            IconsLanguages[
              params.row.language === "BATHWIN"
                ? "bash"
                : params.row.language === "BATHLinux"
                ? "shell"
                : params.row.language === "phython"
                ? "python"
                : params.row.language
            ].chip
          }
        </>
      ),
    },
    {
      field: "createdBy",
      headerName: "Edited By",
      minWidth: 100,
      flex: 0.5,
      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Edited By</Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.source === "FILERAPTOR" ? (
            <Box style={{ textAlign: "center", width: "100%" }}>EVE</Box>
          ) : (
            <Box style={{ textAlign: "center", width: "100%" }}>
              {params.row?.createdBy ? params.row.createdBy : "-"}
            </Box>
          )}
        </>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Last Modification",
      minWidth: 100,
      flex: 0.5,
      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          Last Modification
        </Box>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      filterable: false,
      type: "actions",
      renderCell: (params) => (
        <>
          <DetailsScript
            Choose={false}
            ScriptData={params}
            DataUser={DataUser}
            Reload={Reload}
          />
          <EditScript
            DataUser={DataUser}
            idScript={params.id}
            HandleReload={Reload}
          />
          <DeleteModalScript
            ScriptData={params}
            DataUser={DataUser}
            Reload={Reload}
          />
        </>
      ),
    },
  ];
  const ColumnsEmulation = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "noClients",
      headerName: "Endpoints",
      sortable: false,
      filterable: false,
      flex: 0.4,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Endpoints</Box>
      ),
    },
    {
      field: "noScripts",
      headerName: "Custom Threats",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 0.5,
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Custom Threats</Box>
      ),
    },
    {
      field: "executedBy",
      headerName: "Execute By",
      minWidth: 100,
      flex: 1,
      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Execute By</Box>
      ),
      renderCell: (params) => (
        <>
          <Box>{params.row.executeBy}</Box>
        </>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Execute Date",
      flex: 0.2,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Execute Date</Box>
      ),
      filterable: false,
    },
    {
      field: "launcher",
      headerName: "Launcher",
      sortable: false,
      filterable: false,
      flex: 0.5,
      minWidth: 100,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Launcher</Box>
      ),
      renderCell: (params) => (
        <>
          <LaunchBox
            ClientOfExecution={params.row}
            openModalGet={false}
            DataUser={DataUser}
            Execute={Execute}
            Reload={ReloadBox}
            AgentConnect={AgentConnect}
          ></LaunchBox>
        </>
      ),
    },
    {
      field: "reports",
      headerName: "Reports",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Reports</Box>
      ),
      renderCell: (params) => (
        <>
          <ReportBox BoxData={params} DataUser={DataUser}></ReportBox>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      filterable: false,
      type: "actions",
      renderCell: (params) => (
        <>
          <DetailsBox Choose={false} BoxData={params} DataUser={DataUser} />

          <DeleteModalBox
            BoxData={params}
            DataUser={DataUser}
            Reload={ReloadBox}
          />
        </>
      ),
    },
  ];

  const abrir = Boolean(showEl);
  const handleClick = (event) => {
    setShowel(event.currentTarget);
  };
  const handleClose = () => {
    setShowel(null);
  };

  const handlePlatform = (event) => {
    const {
      target: { value },
    } = event;
    setFilterPlatform(value);
  };

  const handleTestlist = (event) => {
    const {
      target: { value },
    } = event;
    if (value === "FILERAPTOR") {
      setFilterStatus(["FILERAPTOR"]);
    } else {
      setFilterStatus(["CODE", "FILE"]);
    }

    setFilterSource(value);
  };

  const handleTLanguajelist = (event) => {
    if (event.target.value !== "") {
      setFilterLanguaje(event.target.value);
    } else {
      setFilterLanguaje("");
    }
  };

  const CleanFilter = () => {
    setFilterName("");
    setFilterScriptName("");
    setFilterPlatform("");
    setFilterStatus([]);
    setFilterSource("");
    setFilterLanguaje([]);
  };

  const handlepaginationMode = (event) => {
    if (
      event.pageSize === 5 ||
      event.pageSize === 10 ||
      event.pageSize === 20
    ) {
      setRowsPerPage(parseInt(event?.pageSize, 10));
      setPage(parseInt(event?.page));
    }
  };

  const handlepaginationModeBox = (event) => {
    if (
      event.pageSize === 5 ||
      event.pageSize === 10 ||
      event.pageSize === 20
    ) {
      setRowsPerPageBox(parseInt(event?.pageSize, 10));
      setPageBox(parseInt(event?.page));
    }
  };
  return (
    <Box style={{ width: "100%" }}>
      <ThemeProvider theme={pageTheme}>
        <Box
          style={{
            width: "100%",
            backgroundColor: "#242933",
            border: " 1px solid #090f1a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "14px",
            marginTop: "24px",
            height: "auto",
          }}
        >
          {selectedIndex === 1 ? (
            <>
              <FormInputTextField
                onChange={(e) => {
                  HandleFilterName(e);
                }}
                color="secondary"
                variant="outlined"
                value={FilterName}
                label="Search threat..."
                InputProps={{
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      onClick={() => setFilterName("")}
                    >
                      {FilterName ? <Clear /> : <Search />}
                    </IconButton>
                  ),
                }}
                style={{ color: "#ffffff", marginBottom: "10px" }}
              />
              <FormInputTextField
                onChange={(e) => {
                  HandleFilterScriptName(e);
                }}
                color="secondary"
                variant="outlined"
                value={FilterScriptName}
                label="Search Alias..."
                InputProps={{
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      onClick={() => setFilterScriptName("")}
                    >
                      {FilterName ? <Clear /> : <Search />}
                    </IconButton>
                  ),
                }}
                style={{ color: "#ffffff", marginBottom: "10px", marginLeft:"15px" }}
              />
              <Tooltip title="Select Filter" arrow>
                <ButtonRows
                  id="basic-button"
                  aria-controls={abrir ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={abrir ? "true" : undefined}
                  onClick={handleClick}
                  style={{ marginLeft: "15px" }}
                >
                  <FilterAlt style={{ color: "white" }} />
                  <Typography
                    style={{ color: "white", textTransform: "capitalize" }}
                  >
                    Filters
                  </Typography>
                </ButtonRows>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={showEl}
                open={abrir}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>
                  <FormControl
                    sx={{ m: 1, width: 300 }}
                    style={{ marginTop: "-1px" }}
                  >
                    <InputLabel
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                      id="FilterStatus"
                      color="secondary"
                    >
                      Filter by Publisher
                    </InputLabel>
                    <Select
                      color="secondary"
                      variant="outlined"
                      id="FilterSattus"
                      value={FilterSource}
                      onChange={handleTestlist}
                      MenuProps={MenuProps}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    >
                      <MenuItem
                        style={{
                          backgroundColor:
                            FilterSource === "FILERAPTOR"
                              ? "purple"
                              : "transparent",
                        }}
                        key={"FILERAPTOR"}
                        value={"FILERAPTOR"}
                        onClick={() => {
                          if (FilterSource === "FILERAPTOR") {
                            setFilterSource("");
                            setFilterStatus([]);
                          }
                        }}
                      >
                        By E.V.E.
                      </MenuItem>
                      <MenuItem
                        style={{
                          backgroundColor:
                            FilterSource === "USER" ? "purple" : "transparent",
                        }}
                        key={"USER"}
                        value={"USER"}
                        onClick={() => {
                          if (FilterSource === "USER") {
                            setFilterSource("");
                            setFilterStatus([]);
                          }
                        }}
                      >
                        By User Code
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MenuItem>

                <MenuItem>
                  <FormControl
                    sx={{ m: 1, width: 300 }}
                    style={{ marginTop: "-1px" }}
                  >
                    <InputLabel
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                      id="FilterTreat"
                      color="secondary"
                    >
                      Filter by Platform
                    </InputLabel>
                    <Select
                      color="secondary"
                      variant="outlined"
                      id="FilterTreat"
                      value={FilterPlatform}
                      onChange={handlePlatform}
                      MenuProps={MenuProps}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    >
                      {PlatformList.map((platform) => (
                        <MenuItem
                          style={{
                            backgroundColor:
                              FilterPlatform === platform.value
                                ? "purple"
                                : "transparent",
                          }}
                          key={platform.id}
                          value={platform.value}
                          onClick={() => {
                            if (FilterPlatform === platform.value) {
                              setFilterPlatform("");
                            }
                          }}
                        >
                          {platform.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>

                <MenuItem>
                  <FormControl
                    sx={{ m: 1, width: 300 }}
                    style={{ marginTop: "-1px" }}
                  >
                    <InputLabel
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                      id="FilterStatus"
                      color="secondary"
                    >
                      Filter by Language
                    </InputLabel>
                    <Select
                      color="secondary"
                      variant="outlined"
                      id="FilterPlatformSelector"
                      value={FilterLanguaje}
                      onChange={handleTLanguajelist}
                      MenuProps={MenuProps}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    >
                      {LanguajeList.map((languaje) => (
                        <MenuItem
                          style={{
                            backgroundColor:
                              FilterLanguaje === languaje.value
                                ? "purple"
                                : "transparent",
                          }}
                          key={languaje.id}
                          value={languaje.value}
                          onClick={() => {
                            if (FilterLanguaje === languaje.value) {
                              setFilterLanguaje("");
                            }
                          }}
                        >
                          {languaje.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>

                <MenuItem>
                  <Tooltip title="Clean Filters" arrow>
                    <ButtonActions
                      onClick={CleanFilter}
                      style={{ marginLeft: "30%" }}
                    >
                      Clear filters
                    </ButtonActions>
                  </Tooltip>
                </MenuItem>
              </Menu>
              <StyledDataGrid
                slotProps={{
                  pagination: {
                    ActionsComponent: TablePaginationActions,
                    showFirstButton: true,
                    showLastButton: true,
                  }                  
                }}
                slots={{
                  loadingOverlay: CustomLoadingOverlay,                 
                  noRowsOverlay: () => (
                    <GridOverlay>
                      <div>No Data Results</div>
                    </GridOverlay>
                  ),
                  noResultsOverlay: () => (
                    <GridOverlay>
                      <div>No Results Filter Data</div>
                    </GridOverlay>
                  ),
                }}
                pagination={!loading}
                pageSizeOptions={[5, 10, 20]}
                rowCount={rowCount}
                paginationMode="server"
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(newSort) => handleSortModelChange(newSort)}
                disableColumnMenu={true}
                columns={Columns}
                loading={loading}
                rows={tempRows}
                autoHeight={true}
                initialState={{
                  pagination: { paginationModel: { pageSize: rowsPerPage } },
                }} 
                disableSelectionOnClick
                disableRowSelectionOnClick
                disableColumnSelector
                suppressPaginationPanel={true}
                disableDensitySelector
                onRowsPerPageChange={handleChangeRowsPerPage}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                onPaginationModelChange={(event) => {
                  handlepaginationMode(event);
                }}
                density="comfortable"

              />
            </>
          ) : (
            <>
              <FormInputTextField
                onChange={(e) => {
                  HandleFilterNameBox(e);
                }}
                color="secondary"
                variant="outlined"
                value={FilterNameBox}
                label="Search name..."
                InputProps={{
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      onClick={() => setFilterNameBox("")}
                    >
                      {FilterName ? <Clear /> : <Search />}
                    </IconButton>
                  ),
                }}
                style={{ color: "#ffffff", marginBottom: "10px" }}
              />
              <StyledDataGrid
                slotProps={{
                  pagination: {
                    ActionsComponent: TablePaginationActions,
                    showFirstButton: true,
                    showLastButton: true,
                  }
                }}
                slots={{
                  LoadingOverlay: CustomLoadingOverlay,
                  noRowsOverlay: () => (
                    <GridOverlay>
                      <div>No Data Results</div>
                    </GridOverlay>
                  ),
                  noResultsOverlay: () => (
                    <GridOverlay>
                      <div>No Results Filter Data</div>
                    </GridOverlay>
                  ),                  
                }}
                pagination={!loadingBox}
                pageSizeOptions={[5, 10, 20]}
                rowCount={rowCountBox}
                paginationMode="server"
                sortingMode="server"
                sortModel={sortModelBox}
                onSortModelChange={(newSort) =>
                  handleSortModelChangeBox(newSort)
                }
                disableColumnMenu={true}
                columns={ColumnsEmulation}
                loading={loadingBox}
                rows={ExecutionTemp}
                autoHeight={true}
                initialState={{
                  pagination: { paginationModel: { pageSize: rowsPerPage } },
                }} 
                disableSelectionOnClick
                disableRowSelectionOnClick
                disableColumnSelector
                suppressPaginationPanel={true}
                disableDensitySelector
                onRowsPerPageChange={handleChangeRowsPerPageBox}
                onSelectionModelChange={(newSelectionModelBox) => {
                  setSelectionModelBox(newSelectionModelBox);
                }}
                selectionModel={selectionModelBox}
                onPaginationModelChange={(event) => {
                  handlepaginationModeBox(event);
                }}
                density="comfortable"

              />
            </>
          )}
        </Box>
      </ThemeProvider>
    </Box>
  );
}
