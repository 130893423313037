export const ModalStyle = {
  transform: "translate(-50%, -50%)",
  position: "absolute",
  top: "50%",
  left: "50%",
  fontSize: "calc(16px + 2vmin)",
  borderRadius: "4px",
  backgroundColor: "#090f1a",
  color: "#fff",
  maxHeight: "90%",
  overflowY: "auto",
  overflowX: "hidden",
  minWidth: "560px",
  height:"auto",
  width: "auto",
  maxWidth: "80%",
  padding: "20px",
};
export const checkDisable = {
  border: "2px solid gray",
  borderRadius: "2px",
  width: "16px",
  height: "16px",
};
export const checkOff = {
  border: "2px solid #e328af",
  backgroundColor: "transparent",
  borderRadius: "2px",
  width: "16px",
  height: "16px",
  cursor: "pointer",
};
export const checkOn = {
  border: "2px solid #8325f7",
  backgroundColor: "#8325f7",
  borderRadius: "2px",
  width: "16px",
  height: "16px",
  cursor: "pointer",
};
export const headerStyle = {
  textAlign: "center",
  width: "100%",
  height: "37px",
  alignItems: "center",
  display: "flex",
  paddingLeft: "32px",
};
