import { Box, ThemeProvider, Typography } from "@mui/material";
import Logo from "../../assets/Logo-dash.svg";
import { pageTheme } from "../../ayudas/Themes";

export default function WelcomePage() {
  return (
    <Box style={{ width: "100%" }}>
      <ThemeProvider theme={pageTheme}>
        <Box
          sx={{
            backgroundColor: "rgb(9, 15, 26)",
            width: "100%",
            height: "600px",
            alignContent: "center",
            textAlign:"center",
          }}
        >
          <img src={Logo} alt="Logo" style={{ width: "70%" }} />
          <Typography variant="h3" color="#8e97ad">
            Welcome 
          </Typography>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
