import { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Modal } from "@mui/material";
// import { ActionButton, SecondaryButton } from "../../../../../helpers/Themes";
import { Download } from "@mui/icons-material";
import "../../BodyScriptPageStyles.scss";
import { downloadScriptFileAsync } from "../../../../Services/ServiceScriptPage";
import { ActionButton, SecondaryButton } from "../../../../../../ayudas/Themes";
import { PrimaryButton } from "../../../../../Packages/PackageTheme";

export default function DowloadModalScript(props) {
  const { scriptFile, DataUser } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    // console.log(scriptFile);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const downloadFile = async (e) => {
    await downloadfile(scriptFile.id,"FILE");
  };
  const downloadZip = async (e) => {
    await downloadfile(scriptFile.id,"ZIP");
  };
  
  async function downloadfile(id,mode) {
    const ScriptFile = {
      id: id,
      name: mode === "ZIP" ? scriptFile.name+".zip": scriptFile.name,
    };
    setOpen(false);
    await downloadScriptFileAsync(DataUser.token, ScriptFile, mode);
  }
  const body = (
    <Box
      className="Section"
      style={{
        width:"25%",
        backgroundColor: "#242933",
        border: "1px solid #090f1a",
        boxShadow: "18px 4px 35px rgba(0, 0, 0, 0.15)",
        borderRadius: "14px",
        padding: "40px",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" style={{ marginBottom: "30px" }}>
        Do you want to download this file?
      </Typography>
      <Typography style={{ marginBottom: "30px" }}>
        The file could be dangerous for your computer
      </Typography>
      <ActionButton fullWidth onClick={downloadFile}>Download File</ActionButton>
      <ActionButton fullWidth onClick={downloadZip}>Download Zip</ActionButton>
      <SecondaryButton fullWidth onClick={handleClose}>Cancel</SecondaryButton>
    </Box>
  );

  return (
    <Box style={{ display: "inline-block" }}>
      <PrimaryButton style={{ display:DataUser.user.result.rol !== "admin"? "none":"", }} variant="contained" color="info" className="ArtifactButton"
        onClick={handleOpen}
      >
        <Download color="inherit"/>
        <Typography className="labelcontent"> File</Typography>
      </PrimaryButton>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </Box>
  );
}
