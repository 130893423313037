import  { useState } from "react";
import {
  Box,
  Grid2 as Grid,
  IconButton,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { VpnKey } from "@mui/icons-material";
import "../../../../General.scss";
import "../../styles/User.scss";
import { ActionButton, SecondaryButton } from "../../../../ayudas/Themes";
import { enqueueSnackbar } from "notistack";
import { env } from "../../../../env";

export default function Changemodal(props) {
  const { User } = props;

  const [open, setOpen] = useState(false);

  const [password, setPassword] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let flag = true;

    if (password === "") {
      flag = false;
    }

    if (flag) {
      const userData = {
        type: "password",
        temporary: checked,
        value: password,
      };
      var url = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/${User.id}/reset-password`;
      var requestHeaders = new Headers();
      requestHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      requestHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "PUT",
        headers: requestHeaders,
        body: JSON.stringify(userData),
        redirect: "follow",
      };
      await fetch(url, requestOptions)
        .then((response) => {
          if (response.ok || response.status === 204) {
            props.Reload();
            setChecked(false);
            setPassword("");
            setOpen(false);
            enqueueSnackbar("update password successfully", {
              variant: "success",
            });

          } else {
            console.log("error", response.statusText);
          }
        })
        .catch((error) => console.log("error", error));
      props.Reload();
      handleClose();
    } else {
      enqueueSnackbar("Passwords not match our empty", { variant: "error" });
    }
  };

  const body = (
    <Box className="contenido" style={{ maxWidth: "500px" }}>
      <Box className="header">
        <Typography className="headerTitle" textAlign={"center"}>
          Change Password
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          size={4}
          
        >
          <Typography color="white">Temporary</Typography>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "7px",
            }}
          >
            <Typography color={!checked ? "white" : "grey"}>No</Typography>

            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography color={checked ? "white" : "grey"}>Yes</Typography>
          </Box>
        </Grid>
        <Grid
          item
          size={6}
          style={{
            color: "white",
          }}
        >
          <Box>
            <Box>
              <Typography>New Password</Typography>
              <TextField
                fullWidth
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item size={12} textAlign="center">
          <Box>
            <SecondaryButton
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </SecondaryButton>
            <ActionButton onClick={handleSubmit}>Save</ActionButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div>
      {/* <Tooltip title="Change password"> */}
      <IconButton
        className="iconButtonSize"
        style={{ color: "#fff", display: "inline-block" }}
        onClick={handleOpen}
      >
        <VpnKey />
      </IconButton>
      {/* </Tooltip> */}
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
