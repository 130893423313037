import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import DiagramTree from "../../Scripts/AgentDiagramTree";

export default function AgentTree(props) {
  useEffect(() => {
    
    if (props.Data) {
      DiagramTree(props.Data);
    }

    return () => {};
  }, [props.Data]);
  return (
    <>
      <div
        style={{
          minHeight: "450px",
          width: "50%",
          display: "inline-block",
          color: "#21273F",
          fill: "#21273F",
        }}
        id="molecule"
      ></div>
      <Box
        style={{
          width: "33.3%",
          marginRight: "10%",
          display: "inline-block",
          float: "right",
        }}
      >
        <Box
          style={{
            width: "50%",
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#fff", margin: "15px", width: "200px" }}
          >
            Node Details
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#25BEC1",
                display: "inline-block",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              Host Name
            </Box>
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#9f30f6",
                display: "inline-block",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              Emulations
            </Box>
          </Typography>

          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#e328af",
                display: "inline-block",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              Packages
            </Box>
          </Typography>
        </Box>
        <Box
          style={{
            width: "50%",
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#fff", margin: "15px", width: "200px" }}
          >
            File Challenge Levels
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "darkred",
                display: "inline-block",
                transform: "rotate(45deg)",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              Zero Day
            </Box>
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#6a0000",
                display: "inline-block",
                transform: "rotate(45deg)",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              Critical
            </Box>
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#ff0000",
                display: "inline-block",
                transform: "rotate(45deg)",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              High
            </Box>
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#ff5830",
                display: "inline-block",
                transform: "rotate(45deg)",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              Middle
            </Box>
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#ffffa2",
                display: "inline-block",
                transform: "rotate(45deg)",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              Low
            </Box>
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#c039ff",
                display: "inline-block",
                transform: "rotate(45deg)",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              None
            </Box>
          </Typography>
          <Typography
            style={{ color: "#66638f", margin: "15px", width: "200px" }}
          >
            <Box
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "4px",
                backgroundColor: "#FFF",
                display: "inline-block",
                transform: "rotate(45deg)",
              }}
            />
            <Box style={{ display: "inline-block", marginLeft: "8px" }}>
              Undefined
            </Box>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
