import Excel from "exceljs";

export default function ExporExcel(props) {
  const { importData, fileName } = props;

  const ExportExcelFuntion = async () => {
    if (
      importData !== null &&
      importData !== undefined &&
      importData.length !== 0
    ) {
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("sheet1");
      worksheet.columns = importData.colums; // Fix typo
      importData.data.forEach((element) => {
        worksheet.addRow(element);
      });
      const blob = new Blob([await workbook.xlsx.writeBuffer()], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();
    }
  };

  return (
    <div
      style={{
        width: "0px",
        height: "0px",
        backgroundColor: "transparent",
        borderColor: "transparent",
      }}
      id="exportedButton"
      onClick={ExportExcelFuntion}
    ></div>
  );
}
