import axios from "axios";
import "./Dash.scss";

import { useEffect, useMemo, useState } from "react";
import AxiosFetch from "../../Services/FetchServices";
import Top10MostusedAgentsScript from "../../Scripts/Top10MostUsedAgentsScript";
import Top5SuccessfullyExecutedArtifactScript from "../../Scripts/Top5SuccessfullyExecutedArtifactsScript";
import Top5ArtifactsSuccessfullyEvadedNetworkSecurityScript from "../../Scripts/Top5ArtifactsSuccessfullyEvadedNetworkSecurityScript";
import Top5MostThreatenedAgentScript from "../../Scripts/Top5MostThreatenedAgentsScript";
import ExecutionsCommittedScript from "../../Scripts/ExecutionsCommittedScript";
import moment from "moment";
import TimeLineOfExecutionsScript from "../../Scripts/TimeLineOfExecutionsScript";

import {
  Box,
  Grid2,
  Typography,
  TextField,
  ThemeProvider,
} from "@mui/material";
import imageasset from "../../assets/images/dashboard-icon-svg.svg";

import AgentsLocationMap from "./AgentsLocation/AgentsLocationMap";
import Top5SuccessfullyExecutedArtifacts from "./Top5SuccessfullyExecutedArtifacts/Top5SuccessfullyExecutedArtifacts";
import Top5MostThreatenedAgents from "./Top5MostThreatenedAgents/Top5MostThreatenedAgents";
import ExecutionsCommited from "./ExecutionsCommitted/ExecutionsCommitted";
import TimeLineOfExecutions from "./TimeLineOfExecutions/TimeLineOfExecutions";
import { pageTheme } from "../../ayudas/Themes";
import GridComponent from "./GridComponent/GripComponent";
import { env2 } from "../../ayudas/Instance";
import OnlineAgents from "./OnlineAgents/OnlneAgents";
import Cookies from "universal-cookie";
import Top5ArtifactsSuccessfullyEvadedNetworkSecurity from "./Top5ArtifactsSuccessfullyEvadedNetworkSecurity/Top5ArtifactsSuccessfullyEvadedNetworkSecurity";
import Top10MosUsedAgents from "./Top10MostUsedAgents/Top10MosUsedAgents";
import ExecutionsResults from "./ExecutionsResults/ExecutionsResults";
import { endPoint } from "../../Services/EndPoint";

const ColorDefault = [
  "#5ECFFF",
  "#E328AF",
  "#FDC501",
  "#9743FF",
  "#FF4A55",
  "#2BC155",
  "#5E6EFF",
  "#CAA521",
  "#8200A3",
  "#CFF000",
];

const colorArr = [
  "#5ECFFF",
  "#E328AF",
  "#FDC501",
  "#9743FF",
  "#FF4A55",
  "#2BC155",
  "#5E6EFF",
  "#CAA521",
  "#8200A3",
  "#CFF000",
];

const colorList = ["#6418C3", "#5ECFFF", "#E328AF", "#FFAB2D", "#FF4A55"];

function AgentsConnecteds(props) {
  const { AgentConnect } = props;
  const [markers, setmarkers] = useState(AgentConnect);

  useEffect(() => {
    setmarkers(AgentConnect);
  }, [AgentConnect]);

  return <AgentsLocationMap AgentConnect={markers} />;
}

function AgentsConnectedsTableRender(props) {
  const { AgentConnect, Redirect } = props;
  const [markers, setmarkers] = useState(AgentConnect);

  useEffect(() => {
    setmarkers(AgentConnect);
  }, [AgentConnect]);

  return <OnlineAgents DataAgentsConnects={markers} Redirect={Redirect} />;
}

export default function Dashboard(props) {
  const { AgentConnect, DataUser, Redirect } = props;
  const [Top10Most, setTop10Most] = useState([]);
  const [Top5ArtifactsNetworkSecurity, setTop5ArtifactsNetworkSecurity] =
    useState([]);
  const [Top5ArtifactsSuccessfull, setTop5ArtifactsSuccessfull] = useState([]);
  const [Top5Most, setTop5Most] = useState([]);
  const [dataExecutionsCommitedLegend, setdataExecutionsCommitedLegend] =
    useState([]);
  const [TotalSuccess, setTotalSuccess] = useState(0);
  const [TotalResult, setTotalResult] = useState([]);
  const [TotalNoSend, setTotalNoSend] = useState(0);
  const [TotalFailed, setTotalFailed] = useState(0);
  const [selectedDate, setselectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [DaysGoBack, setDaysGoBack] = useState(7);
  const [dataTimeLine, setdataTimeLine] = useState([]);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  const GetTop5ArtifactsRealExecution = async (DataTopNS) => {
    let maxNoArtifactObj = DataTopNS.reduce((max, p) => {
      return p.value > max ? p.value : max;
    }, 0);

    Top5SuccessfullyExecutedArtifactScript(maxNoArtifactObj, DataTopNS);
  };

  const GetTop5ArtifactsNetworkExecution = async (DataTopNS) => {
    let maxNoArtifactObj = DataTopNS.reduce((max, p) => {
      return p.value > max ? p.value : max;
    }, 0);

    Top5ArtifactsSuccessfullyEvadedNetworkSecurityScript(
      maxNoArtifactObj,
      DataTopNS
    );
  };

  const GetTop5MosAgentThreatenedChart = async (Top5Agents) => {
    Top5MostThreatenedAgentScript(Top5Agents);
  };

  useEffect(() => {
    cookies.set("CurrentPageDataPagination", "0", {
      path: "/",
      SameSite: "none",
      secure: true,
    });
  }, [cookies]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = env2.api + endPoint.topAgentsSuccesfully;

    async function GetData() {
      const response = await AxiosFetch("GET", url, source);
      const ListInicial = [];
      if (response && response.OK) {
        response.msg.forEach((element, i) => {
          ListInicial.push({
            id: i,
            agentname: element.client,
            visits: element.neto,
            color: ColorDefault[i],
          });
        });

        const ListFinal = [];
        if (ListInicial.length >= 10) {
          for (let index = 0; index < 10; index++) {
            const element = ListInicial[index];
            ListFinal.push(element);
          }
        } else {
          for (let index = 0; index < ListInicial.length; index++) {
            const element = ListInicial[index];
            ListFinal.push(element);
          }
        }
        setTop10Most(ListFinal);
        Top10MostusedAgentsScript(ListFinal);
      } else {
        setTop10Most([
          {
            id: "1",
            agentname: "No data load",
            visits: "0",
            color: ColorDefault[0],
          },
        ]);
        Top10MostusedAgentsScript([
          {
            id: "1",
            agentname: "No data load",
            visits: "0",
            color: ColorDefault[0],
          },
        ]);
      }
    }

    GetData();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = env2.api + endPoint.topArtifact;
    async function GetData() {
      const response = await AxiosFetch("GET", url, source);
      if (response && response.OK) {
        let array = response.msg.result;

        if (array[2].top.length > 0) {
          let Top5ArtifactsSuccessfull = [];

          array[2].top.forEach((element, i) => {
            Top5ArtifactsSuccessfull.push(element);
          });
          let sumOfArtifactsValue1 = Top5ArtifactsSuccessfull.reduce(
            (sum, current) => {
              return sum + current.neto;
            },
            0
          );

          Top5ArtifactsSuccessfull.forEach((element, i) => {
            element.value = (
              (element.neto * 100) /
              sumOfArtifactsValue1
            ).toFixed(1);
            element.color = colorList[i];
          });
          setTop5ArtifactsSuccessfull(Top5ArtifactsSuccessfull);
          GetTop5ArtifactsRealExecution(Top5ArtifactsSuccessfull);
        } else {
          setTop5ArtifactsSuccessfull([]);
          GetTop5ArtifactsRealExecution([]);
        }
        if (array[0].top.length > 0) {
          let DataTop5ArtifactsNetworkSecurity = [];

          array[0].top.forEach((element, i) => {
            DataTop5ArtifactsNetworkSecurity.push(element);
          });
          let sumOfArtifactsValue2 = DataTop5ArtifactsNetworkSecurity.reduce(
            (sum, current) => {
              return sum + current.neto;
            },
            0
          );

          DataTop5ArtifactsNetworkSecurity.forEach((element, i) => {
            element.value = (
              (element.neto * 100) /
              sumOfArtifactsValue2
            ).toFixed(1);
            element.color = colorList[i];
          });

          setTop5ArtifactsNetworkSecurity(DataTop5ArtifactsNetworkSecurity);
          GetTop5ArtifactsNetworkExecution(DataTop5ArtifactsNetworkSecurity);
        } else {
          setTop5ArtifactsNetworkSecurity([]);
          GetTop5ArtifactsNetworkExecution([]);
        }
      } else {
        setTop5ArtifactsSuccessfull([]);
        GetTop5ArtifactsRealExecution([]);
        setTop5ArtifactsNetworkSecurity([]);
        GetTop5ArtifactsNetworkExecution([]);
      }

      return () => {
        source.cancel();
      };
    }

    GetData();
  }, []);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = env2.api + endPoint.topAgents;
    async function GetData() {
      const allresponse = await AxiosFetch("GET", url, source);
      const response = allresponse?.msg ? allresponse.msg : false;

      const List2 = [];
      const List3 = [];

      const List5 = [];

      if (response && Array.isArray(response)) {
        const List4 = response[2].top;
        response.forEach((element) => {
          element.top.forEach((element1, i) => {
            List2.push({
              id: i,
              agentname: element1.client,
              visits: element1.satisfactory,
              color: colorArr[i],
            });
          });
        });

        List2.sort((a, b) => {
          if (a.visits < b.visits) {
            return 1;
          }
          if (a.visits > b.visits) {
            return -1;
          }
          return 0;
        });

        if (List2.length >= 10) {
          for (let index = 0; index < 10; index++) {
            const element = List2[index];
            List3.push(element);
          }
        } else {
          for (let index = 0; index < List2.length; index++) {
            const element = List2[index];
            List3.push(element);
          }
        }

        if (List4.length >= 5) {
          for (let index = 0; index < 5; index++) {
            const element = List4[index];
            List5.push(element);
          }
        } else {
          for (let index = 0; index < List4.length; index++) {
            const element = List4[index];
            List5.push(element);
          }
        }
      }
      setTop5Most(List5);
      GetTop5MosAgentThreatenedChart(List5);
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, []);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = env2.api + endPoint.historyExecutionCount;
    async function GetData() {
      const response = await AxiosFetch("GET", url, source);
      if (response) {
        let res = response.msg.result;
        let totalSuccessExe = 0;
        let totalFailedExe = 0;
        let totalNotSendExe = 0;
        let total = 0;
        let totalData = [
          {
            name: "success",
            NetworkSecurity:
              res["NetworkSecurity"].length > 0
                ? res["NetworkSecurity"][0].satisfactory
                : 0,
            Epp: res["EPP"].length > 0 ? res["EPP"][0].satisfactory : 0,
            RealExecution:
              res["RealExecution"].length > 0
                ? res["RealExecution"][0].satisfactory
                : 0,
          },
          {
            name: "notSent",
            NetworkSecurity:
              res["NetworkSecurity"].length > 0
                ? res["NetworkSecurity"][0].notSent
                : 0,
            Epp: res["EPP"].length > 0 ? res["EPP"][0].notSent : 0,
            RealExecution:
              res["RealExecution"].length > 0
                ? res["RealExecution"][0].notSent
                : 0,
          },
          {
            name: "failed",
            NetworkSecurity:
              res["NetworkSecurity"].length > 0
                ? res["NetworkSecurity"][0].failed
                : 0,
            Epp: res["EPP"].length > 0 ? res["EPP"][0].failed : 0,
            RealExecution:
              res["RealExecution"].length > 0
                ? res["RealExecution"][0].failed
                : 0,
          },
        ];
        let array = [
          {
            Name: "NetworkSecurity",
            failed:
              res["NetworkSecurity"].length > 0
                ? res["NetworkSecurity"][0].failed
                : 0,
            notSent:
              res["NetworkSecurity"].length > 0
                ? res["NetworkSecurity"][0].notSent
                : 0,
            satisfactory:
              res["NetworkSecurity"].length > 0
                ? res["NetworkSecurity"][0].satisfactory
                : 0,
          },
          {
            Name: "EPP",
            failed: res["EPP"].length > 0 ? res["EPP"][0].failed : 0,
            notSent: res["EPP"].length > 0 ? res["EPP"][0].notSent : 0,
            satisfactory:
              res["EPP"].length > 0 ? res["EPP"][0].satisfactory : 0,
          },
          {
            Name: "RealExecution",
            failed:
              res["RealExecution"].length > 0
                ? res["RealExecution"][0].failed
                : 0,
            notSent:
              res["RealExecution"].length > 0
                ? res["RealExecution"][0].notSent
                : 0,
            satisfactory:
              res["RealExecution"].length > 0
                ? res["RealExecution"][0].satisfactory
                : 0,
          },
        ];
        totalSuccessExe = array.reduce((a, b) => {
          return a + b.satisfactory;
        }, 0);
        totalFailedExe = array.reduce((a, b) => {
          return a + b.failed;
        }, 0);
        totalNotSendExe = array.reduce((a, b) => {
          return a + b.notSent;
        }, 0);

        total = totalSuccessExe + totalFailedExe + totalNotSendExe;
        setTotalResult(totalData);
        setTotalSuccess(totalSuccessExe);
        setTotalNoSend(totalNotSendExe);
        setTotalFailed(totalFailedExe);

        let dataExecutionsCommited = [
          {
            status: "Successful emulations",
            value: ((totalSuccessExe * 100) / total).toFixed(0),
            full: total + 20,
            config: {
              fill: "#e91e63",
            },
          },
          {
            status: "Failed emulations",
            value: ((totalFailedExe * 100) / total).toFixed(0),
            full: total + 20,
            config: {
              fill: "#9c27b0",
            },
          },
          {
            status: "Artifacts Not Delivered",
            value: ((totalNotSendExe * 100) / total).toFixed(0),
            full: total + 20,
            color: "#fff",

            config: {
              fill: "#663ab6",
            },
          },
        ];
        ExecutionsCommittedScript(dataExecutionsCommited);

        let legendArr = [
          {
            status: "Artifacts Not Delivered",
            value: totalNotSendExe,
            borderClass: "purple_border",
          },
          {
            status: "Failed emulations",
            value: totalFailedExe,
            borderClass: "voilet_border",
          },
          {
            status: "Successful emulations",
            value: totalSuccessExe,
            borderClass: "pink_border",
          },
        ];
        setdataExecutionsCommitedLegend(legendArr);
      } else {
        let totalData = [
          {
            name: "success",
            NetworkSecurity: 0,
            Epp: 0,
            RealExecution: 0,
          },
          {
            name: "notSent",
            NetworkSecurity: 0,
            Epp: 0,
            RealExecution: 0,
          },
          {
            name: "failed",
            NetworkSecurity: 0,
            Epp: 0,
            RealExecution: 0,
          },
        ];
        setTotalResult(totalData);
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, []);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let day = new Date();
    let GMT = day.toString().split("GMT")[1].split(" ")[0];
    let value = parseInt(GMT.charAt(0) + GMT.charAt(1) + GMT.charAt(2), 10);
    const bodyData = {
      todayIs: moment(selectedDate).format("YYYY-MM-DD"),
      utc: value,
      goBackDay: parseInt(DaysGoBack, 10),
    };
    const body = JSON.stringify(bodyData);
    const url = env2.api + endPoint.historyRecordCount;
    async function GetData() {
      const response = await AxiosFetch("POST", url, source, body);
      let Week = response?.msg ? response.msg : [];
      var hoursData = [];
      if (Week?.length) {
        for (var i = 0; i < Week.length; i++) {
          var recordsCount = Week[i]["recordCount"];

          var hoursObj = {
            date: Week[i]["day"],
            artifact: recordsCount.Artifacts["create"],
            package: recordsCount.Packages["create"],
            emulations: recordsCount.Executions["create"],
            scheduled: recordsCount.Scheduled["create"],
            clients: recordsCount.Clients["create"],
            htmlCount: `Uploaded Artifacts : ${recordsCount.Artifacts["create"]} \n New packages : ${recordsCount.Packages["create"]} \n  Emulations : ${recordsCount.Executions["create"]} \n Scheduleds : ${recordsCount.Scheduled["create"]} \n Endpoints : ${recordsCount.Clients["create"]}`,
            total:
              recordsCount.Artifacts["create"] +
              recordsCount.Packages["create"] +
              recordsCount.Executions["create"] +
              recordsCount.Scheduled["create"] +
              recordsCount.Clients["create"],
          };
          hoursData.push(hoursObj);
        }
      }

      var data1 = [];

      for (let i = 0; i < hoursData.length; i++) {
        data1.push({
          date: hoursData[i]["date"],
          value1: hoursData[i]["artifact"],
          value2: hoursData[i]["package"],
          value3: hoursData[i]["emulations"],
          value4: hoursData[i]["scheduled"],
          value5: hoursData[i]["clients"],
          total: hoursData[i]["total"],
          htmlValue: hoursData[i]["htmlCount"],
        });
      }
      setdataTimeLine(data1);
      TimeLineOfExecutionsScript(data1);
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [DaysGoBack, selectedDate]);

  return (
    <ThemeProvider theme={pageTheme}>
      <Grid2 style={{ paddingLeft: "10px" }} container spacing={3}>
        <Grid2
          style={{ marginTop: "10px", paddingBottom: "0px" }}
          className="dash-top-tlt"
          item
          size={6}
        >
          <Typography variant="pageTitle">
            <img src={imageasset} alt="" className="rt-icon-header" />
            Dashboard
          </Typography>
        </Grid2>

        <Grid2
          style={{ marginTop: "15px", paddingBottom: "0px" }}
          className="dash-top-des"
          item
          size={6}
        >
          <p style={{ marginRight: "10px" }}>
            Welcome{" "}
            <b>
              {DataUser.user.result.firtsName +
                " " +
                DataUser.user.result.lastName}
            </b>
          </p>
        </Grid2>

        <GridComponent initialSize={12} blockSize={true}>
          <ExecutionsResults
            DataUser={DataUser}
            TotalSuccess={TotalSuccess}
            TotalResult={TotalResult}
            TotalNoSend={TotalNoSend}
            TotalFailed={TotalFailed}
          />
        </GridComponent>
        <GridComponent>
          <Top10MosUsedAgents Top10MostUsedAgentsData={Top10Most} />
        </GridComponent>
        <GridComponent blockSize={true}>
          <AgentsConnecteds AgentConnect={AgentConnect} />
        </GridComponent>
        <GridComponent item xs={10} md={12} lg={6} xl={6}>
          <Top5SuccessfullyExecutedArtifacts
            DataTop5ArtifactsSuccessfull={Top5ArtifactsSuccessfull}
          />
        </GridComponent>
        <GridComponent item xs={10} md={12} lg={6} xl={6}>
          <Top5MostThreatenedAgents DataTop5MostThreatenedAgents={Top5Most} />
        </GridComponent>
        <GridComponent item xs={10} md={12} lg={6} xl={6}>
          <Top5ArtifactsSuccessfullyEvadedNetworkSecurity
            Top5MostArtifactsEvadedData={Top5ArtifactsNetworkSecurity}
          />
        </GridComponent>
        <GridComponent item xs={10} md={12} lg={6} xl={6}>
          <ExecutionsCommited
            ExecutionsCommitedData={dataExecutionsCommitedLegend}
          />
        </GridComponent>
        <GridComponent item xs={10} md={12} lg={6} xl={6}>
          <AgentsConnectedsTableRender
            AgentConnect={AgentConnect}
            Redirect={Redirect}
          />
        </GridComponent>
        <GridComponent item xs={10} md={12} lg={6} xl={6}>
          <Box className="col-12 col-lg-12 mb-4">
            <Box className="dash-cmn-box">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "10px",
                  borderRadius: "12px 12px 0px 0px",
                  backgroundColor: "#090f1a",
                }}
              >
                <h2
                  className="dashbord_main_title"
                  style={{width:"40%"}}
                >
                  Events Timeline
                </h2>

                <Box style={{ display: "block",width:"50%" }}>
                  <TextField
                    type="date"
                    name="selectDays"
                    id="selectdays"
                    value={selectedDate}
                    onChange={(e) => {
                      setselectedDate(e.target.value);
                    }}
                    sx={{width:"170px", marginLeft:"60px"}}
                    maxDate={new Date()}
                  />
                </Box>
                <div style={{margin:"-30px -30px 0px 15px"}}>
                <label htmlFor="calender-input" style={{fontSize:"10px"}}> Timeline Duration (Days)</label>
                <TextField
                  Input={{
                    inputProps: { min: 7, max: 365 },
                  }}
                  id="calender-input"
                  type="number"
                  value={DaysGoBack}
                  onChange={(event) => {
                    setDaysGoBack(event.target.value);
                  }}
                  style={{
                    marginTop:"-15px",
                    border: "transparent",
                    width: "70px",
                  }}
                /></div>
              </div>
              <TimeLineOfExecutions DataTimeLineOfExecutions={dataTimeLine} />
            </Box>
          </Box>
        </GridComponent>
      </Grid2>
    </ThemeProvider>
  );
}
