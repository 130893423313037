import { useCallback, useEffect, useState } from "react";
import { sha512 } from "js-sha512";
import Logo from "../../assets/logo.png";
// import Cookies from "universal-cookie";

import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { env2 } from "../../ayudas/Instance";
import { endPoint } from "../../Services/EndPoint";
import AxiosFetch from "../../Services/FetchServices";
import { CheckCircleRounded } from "@mui/icons-material";
import MenuDrawner from "./Menu";
import { ConvertDateUtc } from "../../ayudas/GlobalData";
import { env } from "../../env";
import { jwtDecode } from "jwt-decode";
const stylePaper = {
  m: 1,
  width: "100%",
  height: "auto",
  textAlign: "center",
  alignContent: "center",
  backgroundColor: "transparent",
  boxShadow: "none",
};
const styleFont = {
  fontSize: "30px",
  color: "#fff",
  textShadow: "1px 1px 1px #000",
};

export function HomeEpiphany() {
  const { keycloak } = useKeycloak();
  const [tokenReady, setTokenReady] = useState(false);
  const [DataUser, setDataUser] = useState({});
  const [licenceValidation, setLicenceValidation] = useState(false);
  const [licenseActive, setLicenseActive] = useState(
    "Confirming that your user license is valid."
  );
  const handleCloseSession = () => {
    keycloak.clearToken();
    keycloak.logout({
      redirectUri: window.location.origin, // Redirige al usuario a la página principal tras el logout
    });
  };

  const [verificationConexion, setVerificationConexion] = useState(0);
  const [verificationAccessSystem, setVerificationAccessSystem] = useState(0);
  const [keys, setKeys] = useState([]);

  const handleKeyDown = useCallback(
    (e) => {
      const keystemp = [...keys];
      keystemp.push(e.key);
      setKeys(keystemp);

      if (e.key === "}") {
        var returnImage = document.getElementById("body-eve-platform");
        returnImage.style.backgroundImage = "url(../../assets/background3.jpg)";
        var returnback = document.getElementById("internalback");
        returnback.style.backgroundImage = "url(../../assets/background3.jpg)";
        returnback.style.backgroundColor = "#0a081866";
        var font = document.getElementById("fontColor");
        font.style.color = "white";
        setKeys([]);
      }
    },
    [keys]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
  useEffect(() => {
    if (verificationAccessSystem === -1) {
      if (keys.toString() === "j,e,s,u,s,x,x,x") {
        var changeImage = document.getElementById("body-eve-platform");
        changeImage.style.backgroundImage = "url(../../assets/SAOnew.webp)";
        changeImage.style.color = "red !important";
        var changeback = document.getElementById("internalback");
        changeback.style.backgroundImage = "none";
        changeback.style.backgroundColor = "transparent";
        changeback.style.backgroundSize = "cover";
        var font = document.getElementById("fontColor");
        font.style.color = "black";

        setKeys([]);
      }
    }
  }, [verificationAccessSystem, keys]);

  useEffect(() => {
    async function verify() {
      try {
        const response = await fetch(
          env.REACT_APP_KEYCLOAK_INSTANCE +
            "/realms/" +
            env.REACT_APP_KEYCLOAK_REALM +
            "/protocol/openid-connect/token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              grant_type: "client_credentials",
              client_id: "",
              client_secret: "",
            }),
          }
        ); // 'HEAD' para no descargar todo el contenido
        if (response.status === 401) {
          // console.log("Conexión exitosa con el servidor.");

          setVerificationConexion(1);
        } else {
          // console.log(
          //   "Error al conectar con el servidor. Código de estado:",
          //   response.status
          // );
          setVerificationConexion(-1);
        }
      } catch (error) {
        setVerificationConexion(-1);
        console.error("No se pudo conectar al servidor:", error);
      }
    }
    verify();
  }, []);
  const [getInfo, setGetInfo] = useState(true);

  const [phaseAttacks, setphaseAttacks] = useState(false);
  const [threatTypes, setthreatTypes] = useState(false);
  const [packageTypes, setpackageTypes] = useState(false);

  const SetDataUser = useCallback(async () => {
    const url = env2.api + endPoint.phaseAttacks;
    const data = await AxiosFetch("GET", url);
    if (data?.msg && data.msg.query) {
      let result = data.msg.result;
      setphaseAttacks(false);
      let array = [];
      result.forEach((element) => {
        array.push({
          id: element._id,
          name: element.name,
          description: element.description,
        });
      });
      let DataUserCreate = { ...DataUser, List: array };
      setDataUser(DataUserCreate);
    }
  }, [DataUser]);

  useEffect(() => {
    if (tokenReady) {
      async function VerifyLisence() {
        const decoded = jwtDecode(keycloak.token);

        if (
          (decoded.realm_access &&
            decoded.realm_access.roles.includes("eip_sr_0x000")) || //admin
          decoded.realm_access.roles.includes("eip_sr_0xFFF") || //admin
          decoded.realm_access.roles.includes("eip_sr_0x03C") || //user
          decoded.rol === "admin" ||
          decoded.rol === "user"
        ) {
          setVerificationAccessSystem(1);
        } else {
          console.log(
            "Rol not assigned",
            decoded?.realm_access ? decoded.realm_access : "rol unsearch"
          );
          setVerificationAccessSystem(-1);
        }
        const url = env2.api + endPoint.licenseData;
        const urlValidateToken = env2.api + endPoint.validateToken;
        try {
          const config = {
            headers: {
              Pragma: "no-cache",
              "Cache-Control": "no-store",
              Authorization: `Bearer ${keycloak.token}`,
              "Access-Control-Allow-Origin": window.location.origin,
            },
          };
          const response = await axios.get(url, config);
          if (response.status === 200) {
            const data = response.data;
            if (data && data?.msg && data.OK) {
              setLicenceValidation(true);
            }
          } else if (response.status === 403) {
            setLicenseActive(
              response.response.data.msg.msg +
                " in " +
                ConvertDateUtc(response.response.data.msg.expiresAT)
            );
          } else {
            console.log("Error desconocido:", response.status, response.data);
          }
          const responseToken = await axios.get(urlValidateToken, config);
          if (responseToken.status === 200) {
            // console.log(
            //   "Token valido:",
            //   responseToken.status,
            //   responseToken.data
            // );
          } else {
            console.log(
              "Error al validar token:",
              responseToken.status,
              responseToken.data
            );
          }
        } catch (error) {
          console.log("Error en la petición:", error);
        }
      }
      VerifyLisence();
    }

    return () => {};
  }, [tokenReady, keycloak.token]);

  useEffect(() => {
    if (licenceValidation) {
      let phaseAttacks = localStorage.getItem("phaseAttacks");
      let threatTypes = localStorage.getItem("threatTypes");
      let packageTypes = localStorage.getItem("packageTypes");

      if (phaseAttacks && threatTypes && packageTypes) {
        setGetInfo(false);
      } else {
        if (!phaseAttacks) {
          setphaseAttacks(true);
        }

        if (!threatTypes) {
          setthreatTypes(true);
        }

        if (!packageTypes) {
          setpackageTypes(true);
        }
      }
    }

    return () => {};
  }, [licenceValidation]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      if (phaseAttacks) {
        const url = env2.api + endPoint.phaseAttacks;
        const data = await AxiosFetch("GET", url, source);
        if (data?.msg && data.msg.query) {
          let result = data.msg.result;
          localStorage.setItem("phaseAttacks", JSON.stringify(result));
          setphaseAttacks(false);
        }
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [phaseAttacks]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      if (threatTypes) {
        const url = env2.api + endPoint.threatTypes;
        const data = await AxiosFetch("GET", url, source);
        if (data?.msg && data.msg.query) {
          let result = data.msg.result;
          localStorage.setItem("threatTypes", JSON.stringify(result));
          setthreatTypes(false);
        }
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [threatTypes]);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      if (packageTypes) {
        const url = env2.api + endPoint.packageTypes;
        const data = await AxiosFetch("GET", url, source);
        if (data?.msg && data.msg.query) {
          let result = data.msg.result;

          localStorage.setItem("packageTypes", JSON.stringify(result));
          setpackageTypes(false);
        }
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [packageTypes]);

  useEffect(() => {
    if (getInfo) {
      if (!phaseAttacks && !threatTypes && !packageTypes) {
        setTimeout(() => {
          setGetInfo(false);
        }, 1000);
      }
    }
    return () => {};
  }, [phaseAttacks, threatTypes, packageTypes, getInfo]);

  const [ActiveMenu, setActiveMenu] = useState(false);
  const [CountReconnect, setCountReconnect] = useState(0);

  useEffect(() => {
    if (verificationConexion === 1) {
      if (keycloak.authenticated && keycloak.token && DataUser?.user) {
        if (DataUser?.List && DataUser.List.length > 0) {
          setActiveMenu(true);
        } else {
          SetDataUser();
        }
      } else {
        if (keycloak.token) {
          localStorage.setItem("token", keycloak.token);
          localStorage.setItem("DI", sha512(keycloak.tokenParsed.email));
          const decoded = jwtDecode(keycloak.token);

          if (!decoded?.realm_access && !decoded?.rol) {
            setVerificationAccessSystem(-1);
            return;
          }
          let rolLevel =
            decoded?.realm_access?.roles.includes("eip_sr_0x000") ||
            decoded?.realm_access?.roles.includes("eip_sr_0xFFF") ||
            decoded?.rol === "admin"
              ? "admin"
              : decoded?.realm_access?.roles.includes("eip_sr_0x03C") ||
                decoded?.rol === "user"
              ? "user"
              : "";
          localStorage.setItem("UsrLevel", rolLevel);
          let DataUserCreate = {
            user: {
              result: {
                id: keycloak.tokenParsed.sub,
                lastName: keycloak.tokenParsed.family_name,
                firtsName: keycloak.tokenParsed.given_name,
                email: keycloak.tokenParsed.preferred_username,
                rol: rolLevel,
                urlIma: keycloak.tokenParsed.avatar,
                actions: { isTOTP: 0 },
              },
            },
          };
          setDataUser(DataUserCreate);
          let socketlogs = localStorage.getItem("viewSocketLogs");
          if (!socketlogs) {
            localStorage.setItem("viewSocketLogs", false);
          }
          if (!localStorage.getItem("redirectActive")) {
            localStorage.setItem("redirectActive", false);
          }
          setTokenReady(true);
        } else {
          setTimeout(() => {
            if (CountReconnect === 10) {
              setVerificationConexion(-1);
              return false;
            }
            setCountReconnect(CountReconnect + 1);
          }, 1000);
        }
      }
    }
  }, [keycloak, DataUser, CountReconnect, SetDataUser, verificationConexion]);
  return (
    <>
      {verificationConexion === -1 && (
        <Box className="container-login">
          <Box style={{ textAlign: "center" }}>
            <img
              src={Logo}
              alt="logo-EVE"
              style={{ width: "400px", marginTop: "80px" }}
            />
            <Box
              className={"Section"}
              sx={{
                backgroundImage: "url(../../assets/background3.jpg)",
                backgroundColor: "#0a081866",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Paper sx={stylePaper} elevation={4}>
                  <Typography sx={styleFont}>
                    {
                      "Error connecting to keycloak, please contact your service provider."
                    }
                  </Typography>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {verificationAccessSystem === -1 && (
        <Box className="container-login">
          <Box style={{ textAlign: "center" }}>
            <img
              src={Logo}
              alt="logo-EVE"
              style={{ width: "400px", marginTop: "80px" }}
            />
            <Box
              className={"Section"}
              id="internalback"
              sx={{
                backgroundImage: "url(../../assets/background3.jpg)",
                backgroundColor: "#0a081866",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Paper sx={stylePaper} elevation={4}>
                  <Typography sx={styleFont} id="fontColor">
                    {
                      "Sorry, you don't have the permissions to access the EVE platform, Please contact your system administrator and we'll get you set up in no time!"
                    }
                  </Typography>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {verificationConexion === -1 ||
      verificationConexion === 0 ||
      verificationAccessSystem === -1 ||
      verificationAccessSystem === 0 ? (
        <></>
      ) : !keycloak.authenticated ? (
        <Box className="container-login">
          <Box style={{ textAlign: "center" }}>
            <img
              src={Logo}
              alt="logo-EVE"
              style={{ width: "400px", marginTop: "80px" }}
            />
            <Box
              className={"Section"}
              sx={{
                backgroundImage: "url(../../assets/background3.jpg)",
                backgroundColor: "#0a081866",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Paper sx={stylePaper} elevation={4}>
                  <Typography sx={styleFont}>
                    {"Ensuring that your token is authenticated and validated."}
                  </Typography>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : !licenceValidation ? (
        <Box className="container-login">
          <Box sx={{ textAlign: "center" }}>
            <img
              src={Logo}
              alt="logo-EVE"
              style={{ width: "400px", marginTop: "80px" }}
            />
          </Box>
          <Box
            className={"Section"}
            sx={{
              backgroundImage: "url(../../assets/background3.jpg)",
              backgroundColor: "#0a081866",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Paper sx={stylePaper} elevation={4}>
                <Typography sx={styleFont}>
                  <Typography sx={styleFont}>{licenseActive}</Typography>
                </Typography>
              </Paper>
            </Box>
          </Box>
        </Box>
      ) : getInfo ? (
        <Box className="container-login">
          <Box sx={{ textAlign: "center" }}>
            <img
              src={Logo}
              alt="logo-EVE"
              style={{ width: "400px", marginTop: "80px" }}
            />
          </Box>
          <Box
            className={"Section"}
            sx={{
              backgroundImage: "url(../../assets/background3.jpg)",
              backgroundColor: "#0a081866",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Paper sx={stylePaper} elevation={4}>
                <Typography sx={styleFont}>
                  {phaseAttacks ? (
                    <CircularProgress sx={{ color: "#c59d48" }} />
                  ) : (
                    <CheckCircleRounded
                      sx={{ color: "#c59d48", fontSize: "30px" }}
                    />
                  )}
                  {"Getting an attack phases catalog."}
                </Typography>
              </Paper>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Paper sx={stylePaper} elevation={4}>
                <Typography sx={styleFont}>
                  {threatTypes ? (
                    <CircularProgress sx={{ color: "#c59d48" }} />
                  ) : (
                    <CheckCircleRounded
                      sx={{ color: "#c59d48", fontSize: "30px" }}
                    />
                  )}
                  {"Getting a threat type catalog."}
                </Typography>
              </Paper>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Paper sx={stylePaper} elevation={4}>
                <Typography sx={styleFont}>
                  {packageTypes ? (
                    <CircularProgress sx={{ color: "#c59d48" }} />
                  ) : (
                    <CheckCircleRounded
                      sx={{ color: "#c59d48", fontSize: "30px" }}
                    />
                  )}
                  {"Getting a package type catalog."}
                </Typography>
              </Paper>
            </Box>
          </Box>
        </Box>
      ) : ActiveMenu ? (
        <div className="Center">
          <MenuDrawner
            name={keycloak.tokenParsed.preferred_username}
            DataUser={DataUser}
            closeSession={handleCloseSession}
          />
        </div>
      ) : (
        <Box className="container-login">
          <Box style={{ textAlign: "center" }}>
            <img
              src={Logo}
              alt="logo-EVE"
              style={{ width: "400px", marginTop: "80px" }}
            />
            <Box
              className={"Section"}
              sx={{
                backgroundImage: "url(../../assets/background3.jpg)",
                backgroundColor: "#0a081866",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Paper sx={stylePaper} elevation={4}>
                  <Typography sx={styleFont}>{"Getting user data"}</Typography>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
