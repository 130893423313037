import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  ThemeProvider,
  Typography,
  Menu,
  Checkbox,
  Tooltip,
} from "@mui/material";
import Cookies from "universal-cookie";
import "./Artefacts.scss";

import Icon from "../../assets/images/file-icon-new.svg";
import FileIcon from "../../assets/images/file-icon-white.svg";
import PackageIcon from "../../assets/images/package-icon.svg";
import AddArtefact from "./AddArtefact/AddArtefact";
import CreatePackage from "./CreatePackages/CreatePackage";
import { GetDate, rolValidation } from "../../ayudas/ScriptsFunctions";
import { GetArtifactByIdAsync } from "../../Services/Artifacts";
import DetailsmodalArtifact from "./DetailsArtifact/ArtifactDetails";
import {
  THREAT_TYPE_TAG,
  pageTheme,
  PrimaryButton,
  SecondaryButton,
  StyledDataGrid,
  FormInputTextField,
  ButtonActions,
  CustomLoadingOverlay,
} from "../../ayudas/Themes";

import {
  Clear,
  FilterAlt,
  Link,
  MoreVert,
  OfflineShareOutlined,
  Search,
} from "@mui/icons-material";
import WindowIcon from "../../assets/images/window-icon.svg";
import LinuxIcon from "../../assets/images/linux-icon.svg";
import MacIcon from "../../assets/images/apple-logo.svg";
import UnkwonIcon from "../../assets/images/device-unknown-icon.svg";
import { GridOverlay } from "@mui/x-data-grid-pro";
import axios from "axios";
import { env2 } from "../../ayudas/Instance";
import { endPoint } from "../../Services/EndPoint";
import AxiosFetch from "../../Services/FetchServices";
import { enqueueSnackbar } from "notistack";
import TablePaginationActions from "../../ayudas/TablePaginations";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styleIcon = {
  maxWidth: "20px",
  maxHeight: "20px",
  objectFit: "contain",
  objectPosition: "center",
  position: "relative",
  top: "-2px",
  verticalAlign: "middle",
  marginRight: "7px",
};

function setTag(params) {
  let status = null;
  switch (params) {
    case "tested": {
      status = "success";

      break;
    }
    case "PartiallyTested": {
      status = "warning";
      break;
    }
    case "untested": {
      status = "error";
      break;
    }

    default:
      break;
  }
  return status;
}

export default function TableArtefacts(props) {
  const { DataUser, SelectedPage } = props;

  const [sortModel, setSortModel] = useState([
    { field: "lastModification", sort: "desc" },
  ]);
  const [lastValuePage, setLastValuePage] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [signalReload, setSignalReload] = useState(0);

  const [count, setCount] = useState(0);
  const [tempRows, settempRows] = useState([]);
  const [FilterName, setFilterName] = useState("");
  const [ThreatTypeList, setThreatTypeList] = useState([]);
  const [FilterThreat, setFilterThreat] = useState([]);
  const [FilterPhaseType, setFilterPhaseType] = useState([]);
  const [FilterPlatform, setFilterPlatform] = useState("");
  const [FilterStatus, setFilterStatus] = useState([]);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array
  const goMitre = async (artefact) => {
    const response = await GetArtifactByIdAsync(
      typeof artefact?.id === "number" ? artefact._id : artefact.id
    );
    if (response !== null) {
      var urlsafe = "https://www.virustotal.com/gui/search/" + response.md5;
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = urlsafe;
      a.click();
    }
  };
  const goMitreLink = async (artefact) => {
    const response = await GetArtifactByIdAsync(
      typeof artefact?.id === "number" ? artefact._id : artefact.id
    );
    if (response !== null && response?.urlMitre) {
      var urlsafe = response.urlMitre;
      if (!/^(?:f|ht)tps?:\/\//.test(response.urlMitre)) {
        urlsafe = "http://" + response.urlMitre;
      }
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = urlsafe;
      a.click();
    } else {
      enqueueSnackbar("This file doesn’t have a reference about MITRE", {
        variant: "info",
      });
    }
  };
  const Columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.4,
      filterable: false,
      sortable: false,
      minWidth: 40,
      align: "center",
      renderHeader: () => <Box style={{ color: "#0a0818" }}>|</Box>,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Checkbox
          color="warning"
          style={{ padding: 10, color: "#c59d48" }}
          onClick={handleToggleArtifacts(params.row)}
          checked={selectionModel.indexOf(params.value) !== -1}
        ></Checkbox>
      ),
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: 0.2,
      filterable: false,
      minWidth: 100,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Platform</Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.actions.platform === "Windows" ? (
            <img src={WindowIcon} alt="" style={{ marginRight: "7px" }} />
          ) : null}
          {params.row.actions.platform === "GNULinux" ? (
            <img src={LinuxIcon} alt="" style={{ marginRight: "7px" }} />
          ) : null}
          {params.row.actions.platform === "MacOS" ? (
            <img src={MacIcon} alt="" style={{ marginRight: "7px" }} />
          ) : null}
          {params.row.actions.platform === undefined ? (
            <img src={UnkwonIcon} alt="" style={{ marginRight: "7px" }} />
          ) : null}
        </>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <DetailsmodalArtifact
          Choose={true}
          artefact={params}
          DataUser={DataUser}
          Reload={Reload}
        />
      ),
    },
    {
      field: "phaseAttacks",
      headerName: "MITRE ATT&CK Phase",
      filterable: false,
      flex: 2,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box
          style={{
            alignContent: "center",
            height: "100%",
            textAlign: "center",
            width: "100%",
          }}
        >
          MITRE ATT&CK Phase
        </Box>
      ),
      renderCell: (params) => (
        <Box
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            textAlign: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto , Helvetica,Arial,sans-serif",
              fontWeight: "100",
              fontSize: "0.875rem",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "threatType",
      headerName: "Challenge Level",
      filterable: false,
      flex: 0.3,
      minWidth: 100,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          Challenge Level
        </Box>
      ),
      renderCell: (params) => (
        <Typography
          style={{
            alignContent: "center",
            height: "100%",
            fontFamily: "Roboto , Helvetica,Arial,sans-serif",
            fontWeight: "400",
            fontSize: "0.875rem",
            maxWidth: "100%",
            wordBreak: "break-word",
            textAlign: "justify",
            color: THREAT_TYPE_TAG[params.value].color,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "tagTest",
      headerName: "Status",
      flex: 0.5,
      minWidth: 100,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Status</Box>
      ),
      renderCell: (params) => (
        <Chip
          style={{ width: "120px" }}
          variant="outlined"
          label={
            params.value === "PartiallyTested"
              ? "Partially Tested"
              : params.value === "tested"
              ? "Tested"
              : params.value === "untested"
              ? "Untested"
              : null
          }
          color={setTag(params.value)}
        />
      ),
    },
    {
      field: "lastModification",
      headerName: "Date Uploaded",
      flex: 1,
      minWidth: 150,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          {"Date Uploaded"}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      minWidth: 100,
      sortable: false,
      filterable: false,
      type: "actions",
      renderCell: (params) => (
        <>
          <DetailsmodalArtifact
            choose
            artefact={params}
            DataUser={DataUser}
            Reload={Reload}
          />

          <Tooltip title="Mitre Link">
            <IconButton
              className="iconButtonSize"
              onClick={goMitreLink.bind(this, params.row)}
            >
              <Link color="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Virus total page">
            <IconButton
              className="iconButtonSize"
              onClick={goMitre.bind(this, params.row)}
            >
              <OfflineShareOutlined />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const handlepaginationMode = (event) => {
    if (
      event.pageSize === 5 ||
      event.pageSize === 10 ||
      event.pageSize === 20
    ) {
      setRowsPerPage(parseInt(event?.pageSize, 10));
      setPage(parseInt(event?.page));
    }
  };
  const CheckWhatChange = useCallback(() => {
    let flag = false;
    if (lastValuePage !== page) {
      flag = true;
    }
    return flag;
  }, [page, lastValuePage]);

  const Checkfilters = useCallback(() => {
    let flag = false;
    if (
      FilterStatus.length !== 0 ||
      FilterPhaseType.length !== 0 ||
      FilterName !== "" ||
      FilterPlatform !== "" ||
      FilterThreat.length !== 0
    ) {
      flag = true;
    }
    return flag;
  }, [FilterStatus, FilterPhaseType, FilterName, FilterPlatform, FilterThreat]);

  const ConstructorBody = useCallback(() => {
    var ArrayBody = {};
    if (FilterName) {
      ArrayBody.name = FilterName;
    }
    if (FilterThreat.length > 0) {
      ArrayBody.threatType = FilterThreat;
    }
    if (FilterPhaseType.length > 0) {
      ArrayBody.phaseAttacks = FilterPhaseType;
    }
    if (FilterStatus.length > 0) {
      ArrayBody.tagTest = FilterStatus;
    }
    if (FilterPlatform) {
      ArrayBody.platform = FilterPlatform;
    }

    let jsonReturn = JSON.stringify(ArrayBody);

    return jsonReturn;
  }, [FilterStatus, FilterPhaseType, FilterName, FilterPlatform, FilterThreat]);

  useEffect(() => {
    cookies.set("CurrentPageDataPagination", "2", {
      path: "/",
      SameSite: "none",
      secure: true,
    });
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const url = env2.api + endPoint.threatTypes;
    async function GetData() {
      setLoading(true);

      const response = await AxiosFetch("GET", url, source);
      if(response){
        setLoading(false);
      }

      if (response?.msg) {
        let array = [];
        response.msg.result.forEach((element) => {
          array.push({ id: element._id, name: element.name });
        });
        setThreatTypeList(array);
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [cookies]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      const baseUrl = `${env2.api}${endPoint.artifactsPageAdvanced}`;
      const paginationParams = `${rowsPerPage <= 0 ? 10 : rowsPerPage}/${
        CheckWhatChange() ? (page <= 0 ? 1 : page + 1) : 1
      }`;
      const sortParams =
        sortModel.length > 0
          ? `col=${sortModel[0].field}&order=${sortModel[0].sort}`
          : `col=lastModification&order=desc`;

      const url = `${baseUrl}${paginationParams}?${sortParams}`;
      setLoading(true);

      const data = await AxiosFetch("POST", url, source, ConstructorBody());
      if(data){
        setLoading(false);
      }

      const response = data?.msg ? data.msg : null;
      if (response?.result) {
        let dataArtifacts = response.result;
        let array = [];
        if (
          dataArtifacts?.artifacts &&
          Array.isArray(dataArtifacts.artifacts)
        ) {
          dataArtifacts.artifacts.forEach((element) => {
            let type =
              element.threatType === "Zero-Day"
                ? "ZeroDay"
                : element.threatType;
            type = element.threatType === "Midd" ? "Mid" : type;
            array.push({
              tagTest: element.tagTest,
              id: element.id,
              name: element.name,
              phaseAttacks: buildPhase(element.phaseAttacks),
              threatType: type,
              lastModification: GetDate(element.lastModification),
              actions: element,
              urlVirusTotal: element.urlVirusTotal,
              urlMitre: element.urlMitre,
            });
          });
          let NoTotal = dataArtifacts.info.neto;
          setCount(NoTotal);
          settempRows(array);
          setLoading(false);
        } else {
          setCount(0);
          settempRows([]);
          setLoading(false);
        }
      } else {
        setCount(0);
        settempRows([]);
        setLoading(false);
      }
    }
    GetData();

    return () => {
      if (SelectedPage !== 2) source.cancel();
    };
  }, [
    page,
    rowsPerPage,
    sortModel,
    FilterName,
    FilterPhaseType,
    FilterThreat,
    FilterStatus,
    FilterPlatform,
    cookies,
    signalReload,
    CheckWhatChange,
    ConstructorBody,
    SelectedPage,
  ]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectionModelRow, setselectionModelRow] = useState([]);

  const handleOpenCreateModal = () => {
    setOpenModalCreate(true);
  };
  const handleToggleArtifacts = (value) => () => {
    const currentIndex = selectionModel.indexOf(value.id);
    const newChecked = [...selectionModel];
    const currentIndex2 = selectionModelRow.indexOf(value);
    const newChecked2 = [...selectionModelRow];

    if (currentIndex === -1) {
      newChecked.push(value.id);
      newChecked2.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newChecked2.splice(currentIndex2, 1);
    }

    setSelectionModel(newChecked);
    setselectionModelRow(newChecked2);
    if (selectionModel.length >= 30) {
      enqueueSnackbar("Sorry to exceed limit of package is 30.", {
        variant: "error",
      });
    }
  };
  const handleCloseCreateModal = () => {
    setOpenModalCreate(false);
    Reload();
    setSelectionModel([]);
    setselectionModelRow([]);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const buildPhase = (array) => {
    let concat = "";
    array.forEach((element, index) => {
      if (index === array.length - 1) {
        concat += element;
      } else if (index === array.length - 2) {
        concat += element + " & ";
      } else {
        concat += element + ", ";
      }
    });
    return concat;
  };

  const GeneratePackage = () => {
    if (selectionModel.length === 0) {
      enqueueSnackbar("Please first select file to create new package.", {
        variant: "info",
      });
    } else {
      handleOpenCreateModal();
    }
  };

  const Reload = () => {
    settempRows([]);
    setSelectionModel([]);
    handleCloseModal();
    setSignalReload(signalReload + 1);
  };

  //Funciones de Filtros y Ordenamientos
  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const HandleFilterName = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== "") {
        setFilterName(e.target.value);
      } else {
        setFilterName("");
      }
    }
    setLastValuePage(0);
    setPage(0);
  };

  const HandleFilterPlatform = (e) => {
    if (e.target.value !== "") {
      setFilterPlatform(e.target.value);
    } else {
      setFilterPlatform("");
    }
    setLastValuePage(0);
    setPage(0);
  };

  const handleThreatlist = (event) => {
    const {
      target: { value },
    } = event;
    setFilterThreat(typeof value === "string" ? value.split(",") : value);
    setLastValuePage(0);
    setPage(0);
  };
  const handleTestlist = (event) => {
    const {
      target: { value },
    } = event;
    setFilterStatus(typeof value === "string" ? value.split(",") : value);
    setLastValuePage(0);
    setPage(0);
  };
  const handlePhaseType = (event) => {
    const {
      target: { value },
    } = event;
    setFilterPhaseType(typeof value === "string" ? value.split(",") : value);
    setLastValuePage(0);
    setPage(0);
  };
  const CleanFilter = () => {
    setFilterPlatform("");
    setFilterName("");
    setFilterPhaseType([]);
    setFilterThreat([]);
    setFilterStatus([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLastValuePage(0);
    setPage(0);
  };

  //Constructor de ventana para responsiva

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileId = "page-menu-mobile";
  const renderMobilePageMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <SecondaryButton
          variant="outlined"
          disabled={selectionModel.length > 30 ? true : false}
          onClick={GeneratePackage}
        >
          <img src={PackageIcon} alt="" style={styleIcon} />
          New package
        </SecondaryButton>
        <PrimaryButton
          disabled={DataUser.user.result.rol !== "admin"}
          onClick={handleOpenModal}
          variant="outlined"
        >
          <img src={FileIcon} alt="" style={styleIcon} />
          New artifact
        </PrimaryButton>
      </Menu>
    </Box>
  );
  const [showEl, setShowel] = React.useState(null);
  const abrir = Boolean(showEl);
  const handleClick = (event) => {
    setShowel(event.currentTarget);
  };
  const handleClose = () => {
    setShowel(null);
  };

  return (
    <Box style={{ width: "100%" }}>
      <Modal open={openModal} onClose={handleCloseModal}>
        <>
          <AddArtefact
            Reload={() => {
              Reload();
            }}
            CloseModal={handleCloseModal}
            DataUser={DataUser}
          />
        </>
      </Modal>
      <Modal open={openModalCreate} onClose={handleCloseCreateModal}>
        <>
          <CreatePackage
            DataUser={DataUser}
            Reload={handleCloseCreateModal}
            FileList={selectionModelRow}
          />
        </>
      </Modal>

      <ThemeProvider theme={pageTheme}>
        <Typography variant="pageTitle">
          <img src={Icon} alt="" className="rt-icon-header" />
          Threat Library
          <Box
            sx={{
              float: { xs: "none", md: "right" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <SecondaryButton
              variant="outlined"
              disabled={
                rolValidation() || selectionModel.length > 30 ? true : false
              }
              onClick={GeneratePackage}
            >
              <img src={PackageIcon} alt="" style={styleIcon} />
              New package
            </SecondaryButton>
            <PrimaryButton
              disabled={DataUser.user.result.rol !== "admin"}
              onClick={handleOpenModal}
              variant="outlined"
            >
              <img src={FileIcon} alt="" style={styleIcon} />
              New artifact
            </PrimaryButton>
          </Box>
          <Box
            sx={{
              float: { xs: "right", md: "none" },
              display: { xs: "inline-block", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </Box>
        </Typography>
        {renderMobilePageMenu}
        <Box
          style={{
            width: "100%",
            backgroundColor: "#242933",
            border: " 1px solid #090f1a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "14px",
            marginTop: "22px",
          }}
        >
          <div style={{ height: "auto", width: "100%" }}>
            <FormInputTextField
              onChange={(e) => {
                HandleFilterName(e);
              }}
              color="secondary"
              variant="outlined"
              value={FilterName}
              label="Search name..."
              InputProps={{
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    onClick={() => setFilterName("")}
                  >
                    {FilterName ? <Clear /> : <Search />}
                  </IconButton>
                ),
              }}
              disabled={rolValidation()}
              style={{ color: "#ffffff", marginBottom: "10px" }}
            />
            <ButtonActions
              id="basic-button"
              aria-controls={abrir ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={abrir ? "true" : undefined}
              onClick={handleClick}
              style={{ marginLeft: "15px", marginTop:"10px", borderRadius:"8px" }}
              disabled={rolValidation()}
            >
              <FilterAlt />
              <Typography style={{ textTransform: "capitalize" }}>
                Filters
              </Typography>
            </ButtonActions>

            {selectionModel.length !== 0 ? (
              <Box
                style={{
                  marginLeft: "20px",
                  display: "inline",
                  fontSize: "20px",
                  color: "#66638f",
                }}
              >
                {selectionModel.length}{" "}
                {selectionModel.length > 1
                  ? " items selected"
                  : " item selected"}
              </Box>
            ) : null}
            <Menu
              id="basic-menu"
              anchorEl={showEl}
              open={abrir}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by Phase
                  </InputLabel>
                  <Select
                    id="FilterPhase"
                    color="secondary"
                    variant="outlined"
                    value={FilterPhaseType}
                    onChange={handlePhaseType}
                    multiple
                    MenuProps={MenuProps}
                  >
                    {DataUser.List && DataUser.List.length > 0
                      ? DataUser.List.map((Phase) => (
                          <MenuItem
                            style={{
                              backgroundColor:
                                FilterPhaseType.indexOf(Phase.id) > -1
                                  ? "purple"
                                  : "transparent",
                            }}
                            key={Phase.id}
                            value={Phase.id}
                          >
                            {Phase.name}
                          </MenuItem>
                        ))
                      : DataUser.List.map((Phase) => (
                          <MenuItem
                            style={{
                              backgroundColor:
                                FilterPhaseType.indexOf(Phase.id) > -1
                                  ? "purple"
                                  : "transparent",
                            }}
                            key={Phase.id}
                            value={Phase.id}
                          >
                            {Phase.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </MenuItem>
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by Challenge level
                  </InputLabel>
                  <Select
                    color="secondary"
                    variant="outlined"
                    id="FilterTreat"
                    value={FilterThreat}
                    onChange={handleThreatlist}
                    multiple
                    MenuProps={MenuProps}
                  >
                    {ThreatTypeList.length > 0
                      ? ThreatTypeList.map((threat) => (
                          <MenuItem
                            style={{
                              backgroundColor:
                                FilterThreat.indexOf(threat.id) > -1
                                  ? "purple"
                                  : "transparent",
                            }}
                            key={threat.id}
                            value={threat.id}
                          >
                            {threat.name === "Midd" ? "Mid" : threat.name}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </MenuItem>
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by Test Status
                  </InputLabel>
                  <Select
                    color="secondary"
                    variant="outlined"
                    id="FilterSattus"
                    value={FilterStatus}
                    onChange={handleTestlist}
                    multiple
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      style={{
                        backgroundColor:
                          FilterStatus.indexOf("untested") > -1
                            ? "purple"
                            : "transparent",
                      }}
                      key={"untested"}
                      value={"untested"}
                    >
                      Untested
                    </MenuItem>
                    <MenuItem
                      style={{
                        backgroundColor:
                          FilterStatus.indexOf("tested") > -1
                            ? "purple"
                            : "transparent",
                      }}
                      key={"tested"}
                      value={"tested"}
                    >
                      Tested
                    </MenuItem>
                    <MenuItem
                      style={{
                        backgroundColor:
                          FilterStatus.indexOf("PartiallyTested") > -1
                            ? "purple"
                            : "transparent",
                      }}
                      key={"PartiallyTested"}
                      value={"PartiallyTested"}
                    >
                      Partially Tested
                    </MenuItem>
                  </Select>
                </FormControl>
              </MenuItem>
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by Platform
                  </InputLabel>
                  <Select
                    color="secondary"
                    variant="outlined"
                    id="FilterPlatformSelector"
                    value={FilterPlatform}
                    onChange={HandleFilterPlatform}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      style={{
                        backgroundColor:
                          FilterPlatform === "Windows"
                            ? "purple"
                            : "transparent",
                      }}
                      key={"Windows"}
                      value={"Windows"}
                      onClick={() => {
                        if ("Windows" === FilterPlatform) {
                          setFilterPlatform("");
                        }
                      }}
                    >
                      Windows
                    </MenuItem>
                    <MenuItem
                      style={{
                        backgroundColor:
                          FilterPlatform === "GNULinux"
                            ? "purple"
                            : "transparent",
                      }}
                      key={"GNULinux"}
                      value={"GNULinux"}
                      onClick={() => {
                        if ("GNULinux" === FilterPlatform) {
                          setFilterPlatform("");
                        }
                      }}
                    >
                      GNU/Linux
                    </MenuItem>
                  </Select>
                </FormControl>
              </MenuItem>
            </Menu>
            {Checkfilters() ? (
              <Chip
                style={{
                  backgroundColor: "#e328af",
                  marginLeft: "15px",
                  color: "#fff",
                }}
                label="Filters Active"
                onDelete={CleanFilter}
              />
            ) : null}
            <StyledDataGrid
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
                loadingOverlay: CustomLoadingOverlay,
                noRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                noResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),                
              }}
              slots={{
                loadingOverlay: CustomLoadingOverlay,
                noRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                noResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
              }}
              pagination={!loading}
              pageSizeOptions={[5, 10, 20]}
              rowCount={count}
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(newSort) => handleSortModelChange(newSort)}
              disableColumnMenu={true}
              columns={Columns} 
              loading={loading}
              rows={tempRows}
              autoHeight={true}
              initialState={{
                pagination: { paginationModel: { pageSize: rowsPerPage } },
              }} 
              disableSelectionOnClick
              disableRowSelectionOnClick
              disableColumnSelector
              suppressPaginationPanel={true}
              disableDensitySelector
              onRowsPerPageChange={handleChangeRowsPerPage}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              onPaginationModelChange={(event) => {
                handlepaginationMode(event);
              }}
              density="comfortable"
            />
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
