import { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid, Modal, Tooltip, Divider, IconButton } from "@mui/material";
import "../../BodyScriptPageStyles.scss";
import Eye from "../../../../../../assets/images/eye-icon.svg";

import { GetBoxByIdAsync } from "../../../../Services/ServiceScriptPage";
import { ConvertDateUtc } from "../../../../../../ayudas/GlobalData";
import { ActionButton } from "../../../../../../ayudas/Themes";

export default function DetailsBox(props) {
  const { BoxData, DataUser, Choose } = props;
  const [open, setOpen] = useState(false);
  const [BoxDetails, setBoxDetails] = useState(null);

  const handleOpen = async () => {
    const response = await GetBoxByIdAsync(
      DataUser.token,
      typeof BoxData?.id === "number" ? BoxData._id : BoxData.id
    );
    if (response !== null) {
      setBoxDetails(response);

      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box
      className="Section"
      style={{
        backgroundcolor: "rgb(36,41,51)",
        borderBottom: "1px solid #1e1c3a",
        minHeight: "600px",
        maxHeight: "90%",
        overflowY: "auto",
        overflowX: "hidden",
        minWidth: "300px",
        width: "auto",
        maxWidth: "80%",
        padding: "20px",
      }}
    >
      {BoxDetails ? (
        <Grid
          container
          backgroundColor="#090f1a"
          spacing={1}
          borderRadius={"8px"}
        >
          <Grid item md={12}>
            <Typography
              variant="h6"
              style={{ margin: "7px" }}
              textAlign="center"
            >
              {"Summary"}
            </Typography>
            <Divider />

            <Typography
              style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}
            >
              {"Name"}
            </Typography>
            <Typography style={{ margin: "0px 8px" }}>
              {BoxDetails.name}
            </Typography>
            <Typography
              style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}
            >
              {"Description:"}
            </Typography>
            <Typography style={{ margin: "0px 8px" }}>
              {BoxDetails.description}
            </Typography>
            <Typography
              style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}
            >
              {"Status:"}
            </Typography>
            <Typography style={{ margin: "0px 8px" }}>
              {BoxDetails.status === 0 ? "Unrun" : "Run"}
            </Typography>
            <Typography
              style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}
            >
              {"Created By:"}
            </Typography>
            <Typography style={{ margin: "0px 8px" }}>
              {BoxDetails?.createdBy
                ? BoxDetails.createdBy
                : "-- --"}
            </Typography>
            <Typography
              style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}
            >
              {"Last Modification:"}
            </Typography>
            <Typography style={{ margin: "0px 8px 15px 8px" }}>
              {ConvertDateUtc(BoxDetails.updatedAt)}
            </Typography>
            <Divider />

            <Typography
              style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}
            >
              {"Endpoint(s):"}
            </Typography>

            {BoxDetails?.fk_client && BoxDetails.fk_client.length > 0
              ? BoxDetails.fk_client.map((agent) => {
                  return (
                    <Typography
                      style={{
                        margin: "0px 8px",
                        fontSize: "14px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50px",
                          backgroundColor: "#fff",
                          display: "inline-flex",
                          marginRight: "7px",
                        }}
                      />{" "}
                      {agent.workUser[0].hostname}
                    </Typography>
                  );
                })
              : null}
            <Typography
              style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}
            >
              {"Script(s):"}
            </Typography>
            {BoxDetails?.fk_script && BoxDetails.fk_script.length > 0
              ? BoxDetails.fk_script.map((script) => {
                  return (
                    <>
                      <Typography
                        style={{
                          margin: "0px 8px",
                          fontSize: "14px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "6px",
                            height: "6px",
                            borderRadius: "50px",
                            backgroundColor: "#fff",
                            display: "inline-flex",
                            marginRight: "7px",
                          }}
                        />{" "}
                        {script.name}
                      </Typography>
                      <Typography
                        style={{ margin: "0px 8px", marginLeft: "30px" }}
                        color={"GrayText"}
                      >
                        {"Language: "}

                        {script.language === "BATHWIN"
                          ? "bash"
                          : script.language === "BATHLinux"
                          ? "shell"
                          : script.language}
                      </Typography>
                      <Typography
                        style={{ margin: "0px 8px 10px 30px" }}
                        color={"GrayText"}
                      >
                        {"Platform: "}
                        {script.platform}
                      </Typography>
                    </>
                  );
                })
              : null}
            <Divider />
          </Grid>

          <Grid item md={12} textAlign={"center"}>
            <ActionButton onClick={handleClose}>Close</ActionButton>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );

  return (
    <Box
      style={{
        display: "inline-block",
        cursor: "pointer",
      }}
    >
      {Choose ? (
        <Typography
          onClick={handleOpen}
          style={{
            fontFamily: "Roboto , Helvetica,Arial,sans-serif",
            fontWeight: "400",
            fontSize: "0.875rem",
          }}
        >
          <p
            style={{
              minWidth: "100px",
              maxWidth: "200px",
              overflow: "hidden",
              whiteSpace: "initial",
              textOverflow: "ellipsis",
              textAlign: "justify",
            }}
          >
            {BoxData.row?.name ? BoxData.row.name : BoxData.name}
          </p>
        </Typography>
      ) : (
        <Tooltip title="File Details">
          <IconButton className="iconButtonSize" onClick={handleOpen}>
            <img src={Eye} alt="" style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
      )}
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </Box>
  );
}

DetailsBox.defaultProps = {
  Information: true,
};
