import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ActionButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../../ayudas/Themes";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Coronavirus, DesktopWindows, Webhook } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { GetDate } from "../../../../ayudas/ScriptsFunctions";
import { GetExecutionIDAsymc } from "../../../../Services/Executions";
import { enqueueSnackbar } from "notistack";
const styleCell = {
  border: "2px solid #6a688666",
  background:"#6a688622",
  textAlign: "left !important",
};
const images = {
  NetworkSecurity: <Coronavirus sx={{ fontSize: "150px" }} />,
  EPP: <DesktopWindows sx={{ fontSize: "150px" }} />,
  RealExecution: <Webhook sx={{ fontSize: "150px" }} />,
};

export default function PopUpModalDetailsExecution(props) {
  const { GetOpenModal, InfoExecution } = props;
  const [open, setOpen] = useState(GetOpenModal);
  const [infoExecutionData, setInfoExecutionData] = useState(InfoExecution);
  const [openDetails, setOpenDetails] = useState(false);
  const [tableInfo, setTableInfo] = useState([]);
  const [filterActived, setFilterActived] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setOpenDetails(false);
    setTableInfo([]);
    setFilterActived(false);
    props.effectModal(false);
  };
  useEffect(() => {
    setOpen(GetOpenModal);
  }, [GetOpenModal]);

  useEffect(() => {
    setInfoExecutionData(InfoExecution);
  }, [InfoExecution]);

  const hanldeInfo = async () => {
    if (tableInfo.length > 0) {
      setOpenDetails(true);
    } else {
      enqueueSnackbar("Getting information additional", { variant: "info" });
      const Result = await GetExecutionIDAsymc(InfoExecution.id);

      if (Result) {
        let array = [];
        const promises = Result.fk_package.map(async (pack) => {
          const artifactsPromises = pack.fk_artifacts.map(async (artifact) => {
            array.push({
              package: pack.name,
              artifact: artifact?.name ? artifact.name : "N/A",
              argument: artifact?.arg ? artifact.arg : "",
            });
          });
          // Espera a que todas las promesas de artefactos se resuelvan
          await Promise.all(artifactsPromises);
        });

        // Espera a que todas las promesas de paquetes se resuelvan
        await Promise.all(promises);

        setTableInfo(array);
        setOpenDetails(true);
      } else {
        enqueueSnackbar("Execution without information", { variant: "info" });
      }
    }
  };
  const validaData = () => {
    if (!filterActived) {
      return true;
    }
    if (tableInfo.length === 0) {
      return false;
    }
    let countArgument = 0;
    for (let index = 0; index < tableInfo.length; index++) {
      const row = tableInfo[index];
      if (row.argument !== "") {
        countArgument++;
      }
    }
    let flag = countArgument > 0;
    return flag;
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
       
      >
        <Fade in={open}>
          <Box
            className="Section"
            sx={{
              backgroundColor: "#090f1a",
              borderBottom: "1px solid #1e1c3a",
              width: openDetails ? "95%" : "40%",
            }}
          >
            <Grid container spacing={3}>
              <Grid item size={openDetails ? 4 : 12} >
                <Card
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#090f1a",
                    border: "1px solid #1e1c3a",
                    borderRadius: "8px",
                    fontSize: "14px",
                    padding: "3px 15px",
                    color: "#8e97ad",
                    height:"100%"
                  }}
                >
                  <Grid container>
                    <Grid item size={6}>
                      <Typography variant="h6">
                        {"Emulation Details"}
                      </Typography>
                      <Typography variant="subtitle1">{`Emulation Name: ${infoExecutionData?.name}`}</Typography>
                    </Grid>
                    <Grid item size={6}>
                      {!openDetails && (
                        <>
                          <PrimaryButton onClick={hanldeInfo}>
                            Show more
                          </PrimaryButton>
                          <SecondaryButton
                            onClick={handleClose}
                            style={{ margin: "0px" }}
                            aria-label="settings"
                          >
                            <CloseIcon style={{ color: "white" }} />
                          </SecondaryButton>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid size={6}>
                        <Box
                          style={{
                            width: "200px",
                            height: "200px",
                            margin: "auto",
                            borderRadius: "8px",
                            backgroundColor: "#0a0818",
                            padding: "20px",
                          }}
                        >
                          {images[infoExecutionData?.vector]}
                        </Box>
                      </Grid>
                      <Grid item size={6}>
                        <Grid container spacing={0}>
                          <Grid item size={12}>
                            <Box
                              style={{
                                margin: "5px",
                                backgroundColor: "#0a0818",
                                border: "1px solid #1e1c3a",
                                borderRadius: "8px",
                                fontSize: "18px",
                                padding: "3px 15px",
                                color: "#8e97ad",
                              }}
                            >
                              Activate the secure download:{" "}
                            </Box>
                            <Typography>
                              {infoExecutionData?.safeDownload === "n"
                                ? "False"
                                : "True"}
                            </Typography>

                            <Box
                              style={{
                                margin: "20px 5px",
                                backgroundColor: "#0a0818",
                                border: "1px solid #1e1c3a",
                                borderRadius: "8px",
                                fontSize: "18px",
                                padding: "3px 15px",
                                color: "#8e97ad",
                              }}
                            >
                              Emulation Vector:
                            </Box>

                            <Typography marginTop={"-10px"}>
                              {infoExecutionData?.vector === "RealExecution"
                                ? "Execution"
                                : infoExecutionData?.vector === "EPP" ||
                                  infoExecutionData?.vector === "Epp"
                                ? "Endpoint"
                                : "Network"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item size={6}>
                        <Box
                          style={{
                            margin: "5px",
                            backgroundColor: "#0a0818",
                            border: "1px solid #1e1c3a",
                            borderRadius: "8px",
                            fontSize: "18px",
                            padding: "3px 15px",
                            color: "#8e97ad",
                            width: "100%",
                          }}
                        >
                          <Typography>Last Update:</Typography>
                        </Box>
                        <Typography>
                          {GetDate(infoExecutionData?.lastModification)}
                        </Typography>
                      </Grid>

                      <Grid item size={6}>
                        <Box
                          style={{
                            margin: "5px",
                            backgroundColor: "#0a0818",
                            border: "1px solid #1e1c3a",
                            borderRadius: "8px",
                            fontSize: "18px",
                            padding: "3px 15px",
                            color: "#8e97ad",
                          }}
                        >
                          Status emulation:
                        </Box>
                        <Typography>{infoExecutionData?.status}</Typography>
                      </Grid>
                      <Grid item size={12}>
                        <Box
                          style={{
                            margin: "5px",
                            backgroundColor: "#0a0818",
                            border: "1px solid #1e1c3a",
                            borderRadius: "8px",
                            fontSize: "18px",
                            padding: "3px 15px",
                            color: "#8e97ad",
                          }}
                        >
                          Vector times:
                        </Box>
                        <Typography>
                          {infoExecutionData?.vector === "NetworkSecurity"
                            ? `Network: ${infoExecutionData.time_NetworkSecurity} seconds`
                            : infoExecutionData?.vector === "EPP"
                            ? `Network: ${infoExecutionData.time_NetworkSecurity} seconds,
                           Endpoint: ${infoExecutionData.time_EPP} seconds`
                            : infoExecutionData?.vector === "RealExecution"
                            ? `Network: ${infoExecutionData.time_NetworkSecurity} seconds,
                           Endpoint: ${infoExecutionData.time_EPP} seconds,
                           Execution: ${infoExecutionData.time_RealExecution} seconds`
                            : null}
                        </Typography>
                      </Grid>
                      <Grid item size={6}>
                        <Box
                          style={{
                            margin: "5px",
                            backgroundColor: "#0a0818",
                            border: "1px solid #1e1c3a",
                            borderRadius: "8px",
                            fontSize: "18px",
                            padding: "3px 15px",
                            color: "#8e97ad",
                          }}
                        >
                          Endpoints
                        </Box>
                        <Typography
                          style={{
                            textAlign: "center",
                            maxHeight: "180px",
                            overflow: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {infoExecutionData?.clients.length !== 0
                            ? infoExecutionData?.clients?.map((row) => {
                                return <div>{row.workUser[0].hostname}</div>;
                              })
                            : "The endpoints is not reachable"}
                        </Typography>
                      </Grid>
                      <Grid item size={6}>
                        <Box
                          style={{
                            margin: "5px",
                            backgroundColor: "#0a0818",
                            border: "1px solid #1e1c3a",
                            borderRadius: "8px",
                            fontSize: "18px",
                            padding: "3px 15px",
                            color: "#8e97ad",
                          }}
                        >
                          Packages
                        </Box>
                        <Typography
                          style={{
                            textAlign: "center",
                            maxHeight: openDetails ? "50px" : "100px",
                            overflow: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            {infoExecutionData?.packages.length !== 0
                              ? infoExecutionData?.packages.map((row) => {
                                  return (
                                    <Typography
                                      style={{
                                        minWidth: "240px",
                                        maxWidth: "495px",
                                        overflow: "hidden",
                                        whiteSpace: "initial",
                                        textOverflow: "ellipsis",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.name}
                                    </Typography>
                                  );
                                })
                              : "Information about their one or many packages deleted"}
                          </div>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {openDetails && (
                <Grid item size={8}>
                  <Card
                    style={{
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: "#090f1a",
                      border: "1px solid #1e1c3a",
                      borderRadius: "8px",
                      fontSize: "14px",
                      padding: "3px 15px",
                      color: "#8e97ad",
                      height: "100%",
                    }}
                  >
                    {openDetails && (
                      <>
                        <ActionButton
                          onClick={() => {
                            setFilterActived(!filterActived);
                          }}
                          sx={{ float: "left" }}
                        >
                          {filterActived
                            ? "Show all artifacts"
                            : "Show only artifacts with arguments"}
                        </ActionButton>
                        <SecondaryButton
                          onClick={handleClose}
                          sx={{ float: "right" }}
                          aria-label="settings"
                        >
                          <CloseIcon style={{ color: "white" }} />
                        </SecondaryButton>{" "}
                        <PrimaryButton
                          sx={{ float: "right" }}
                          onClick={() => {
                            setOpenDetails(false);
                          }}
                        >
                          Show less
                        </PrimaryButton>
                      </>
                    )}

                    <div
                      style={{
                        overflow: "auto",
                        height: "570px",
                        width: "100%",
                        paddingTop:"15px"
                      }}
                    >
                      <Table >
                        <TableHead>
                          <TableRow sx={{ background: "#0d0b21" }}>
                            <TableCell sx={{fontSize:"20px !important"}}>Package Name</TableCell>
                            <TableCell sx={{fontSize:"20px !important"}}>Threat Name</TableCell>
                            <TableCell sx={{fontSize:"20px !important"}}>Arguments</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableInfo.map((row) => {
                            if (filterActived) {
                              if (row?.argument && row.argument !== "") {
                                return (
                                  <TableRow
                                    key={row.id}
                                    sx={{ height: "20px" }}
                                  >
                                    <TableCell
                                      style={{ width: "30%" }}
                                      sx={styleCell}
                                    >
                                      {row.package}
                                    </TableCell>
                                    <TableCell
                                      style={{ width: "30%" }}
                                      sx={styleCell}
                                    >
                                      {row.artifact}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "40%",
                                        color: "darkgray",
                                      }}
                                      sx={styleCell}
                                    >
                                      {row.argument}
                                    </TableCell>
                                  </TableRow>
                                );
                              } else {
                                return false;
                              }
                            } else {
                              return (
                                <TableRow key={row.id} sx={{ height: "20px" }}>
                                  <TableCell
                                    style={{ width: "30%" }}
                                    sx={styleCell}
                                  >
                                    {row.package}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "30%" }}
                                    sx={styleCell}
                                  >
                                    {row.artifact}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "40%", color: "darkgray" }}
                                    sx={styleCell}
                                  >
                                    {row.argument}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                        </TableBody>
                      </Table>
                      {validaData() === false && (
                        <Typography
                          textAlign={"center"}
                          width={"100% !important"}
                          marginTop={"25%"}
                          variant="h6"
                        >
                          Without artifacts found{" "}
                        </Typography>
                      )}
                    </div>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
