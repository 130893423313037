import { env } from "../env";

export const endPoint = {
    
    //Artefactos
    artifact: "/artifacts/",                            
    totalArtifacts:"/artifacts/info/size",              
    artifactsPageAdvanced:"/artifacts/page/ADVANCED/",  
    artifactsPageMin:"/artifacts/page/MIN/",           
    addArtifact:"/artifacts/add",                       
    downloadArtifact:"/artifacts/download/",           
    downloadArtifactPdf:"/artifacts/anexo/",            
    //updatePdf:"/artifacts/change/anexo/",               
    updatePdf:"/artifacts/change/",               
    deleteArtifact:"/artifacts/deactivate/",      

    //Catalogos
    phaseAttacks:"/cat/phase/attacks",                  
    userRoles:"/cat/roles/all",                         
    threatTypes:"/cat/threat/types",                   
    packageTypes:"/cat/types/packages/all", 
    groupsCat:"/cat/sub/groups/all",   
    catLicense:"/cat/type/licenses",              

    //Paquetes
    packages:"/packages/all",                          
    packagePageMin:"/packages/page/MIN/",               
    packagePageAdvanced:"/packages/page/ADVANCED/",     
    packageSize:"/packages/info/size",                   
    addPackage:"/packages/add",                         
    downloadAnexoPackage:"/packages/download/anexo/",   
    package:"/packages/",                            
    changePackage:"/packages/change/",                  
    desactivatePackageById: "/packages/deactivate/",   

    //Usuarios
    baseKeycloakUser:env.REACT_APP_KEYCLOAK_INSTANCE+"/admin/realms/" + env.REACT_APP_KEYCLOAK_REALM + "/users",
    addUser:"/users/add",                            
    users:"/users/all",                             
    updateUser:"/users/change/",                      
    loggin: "/users/loggin",                            
    lockPassword:"/users/reset/lock",                   
    unlockPassword:"/users/activate",               
    deleteUser:"/users/deactivate/",                    
    getuser:"/users/",          
    
    /******** User TOTP ******************/
    userTOTP:"/users/totp",

    /***********  VERIFY TOTP ************/
    userVerifyTOTP:"/users/verify/totp",

    //Clientes
    clients:"/clients/all",                             
    onlineClients:"/clients/all/online",                
    deleteClient:"/clients/deactivate/", 
    changeGroup:"/clients/change/group/",               

    //Agentes
    agents:"/agents/all",                               
    downloadAgent:"/agents/download",                  
    downloadOldAgent:"/agents/old/download/",           
    downloadAuth:"/agents/config/file/",                
    addAgent:"/agents/publish",                             
    deleteAgent:"/agents/unpublish/",                  
    deleteOldAgent:"/agents/old/deactivate/",
    updateAgentFile:"/agents/change/",                  
    updateAgentData:"/agents/change/",                  
    oldAgents:"/agents/old/",
    agentslist:"/agents/list/all",
    updateAlias:"/clients/alias/add/",
                      

    //Executions
    getAdvancedExecutionPage:"/executions/page/ADVANCED/",
    executions:"/executions",
    scheduledExecutions:"/executions/scheduled/all?extend=artifactName",
    addExecution:"/executions/add",
    getMinExecutionPage:"/executions/page/MIN/",
    updateExecution:"/executions/change/",
    desactivateExecution:"/executions/deactivate/",
    executionsCount:"/executions/info/size",
    
    //Reports
    topAgentsSuccesfully:"/historys/client/record/top/general",
    getListExecutions:"/historys/execution/dates/",
    getInfoExecution:"/historys/execution/",
    historyExecutionCount:"/historys/execution/record/count",
    historyRecordCount:"/historys/record/count",
    topArtifact:"/historys/execution/record/top",
    topAgents:"/historys/client/record/top",
    clientReport:"/historys/client/",
    reportDeatiled:"/historys/execution/custom/",

    //Enviroments
    enviroments:"/environments/app",                                    
    updateExecutionEnviroment:"/environments/app/executions/change",    
    updateIsolationEnviroment:"/isolation/ip/definition",
    updateTokenEnviroment:"/environments/app/token/change",
    updateEmailEnviroment:"/environments/app/email/change",
    updateUrlEnviroment:"/environments/app/url/change",
    testEmailEnviroment:"/environments/app/email/test",
    isolationIp:"/isolation/ip",
    //License
    licenseData:"/agents/info/config",
    validateToken:"/validate/token",

    //IoC 
    allIOC:"/ioc/all",
    addIOCExec:"/ioc/add",
    desactiveIOC:"/ioc/deactivate/",
    getIOC:"/ioc/",
    getResultIOC:"/ioc/results/",

    //Scripts
    addNewScript:"/scripts/add",
    editScript:"/scripts/change/",
    getScriptById:"/scripts/",
    getAllScript:"/scripts",
    getAllScriptsPaginated:"./scripts/page/:mode/:size/:noPage?col=:platform&order=:order",
    downloadScriptFile:"/scripts/download/",
    deleteScript:"/scripts/deactivate/",
    minPaginationScript:"/scripts/page/MIN/",
    advancePaginationScript:"/scripts/page/ADVANCED/",
    getCodeScript:"/scripts/download/",

    //Emulation Scripts
    advancePaginationBox:"/box/scripts/page/ADVANCED/",
    getAllBox:"/box/scripts",
    deleteBox:"/box/scripts/deactivate/",
    getDetailsBox:"/box/scripts/",
    addNewScriptEmulation: "/box/scripts/add",
    updateScriptEmulation: "/box/scripts/change/",
    getEmulationScriptById: "/box/scripts/",

    //Box Emulation Results
    getAllBoxEmulationResults:"/result/scripts",
    getBoxEmulationByID:"/result/scripts/",
    getLogScriptZip:"/result/scripts/log/download",
    previewlog:"/result/scripts/log/preview",
}