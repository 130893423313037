import { endPoint } from "./EndPoint";
import { env2 } from "../ayudas/Instance";
import { downloadFile } from "./FetchRequests";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";
import AxiosFetch from "./FetchServices";
import { enqueueSnackbar } from "notistack";

const AGENT_LOADED = "Agent loaded successfully";
const AGENT_DELETED = "Agent deleted successfully";
const PREPARING_AGENT_FILE = "Prepare your file For upload, please wait";
const AGENT_AUTH = "Auth.json";

export async function getAgentsAsync() {
  const url = env2.api + endPoint.agents;
  const response = await AxiosFetch("GET", url);
  return getMsgResult(response);
}

export async function downloadAuthFileAsync(token, id) {
  const url = env2.api + endPoint.downloadAuth + id + "?lang=en-US";
  await downloadFile(token, url, AGENT_AUTH);
}

export async function addAgent(agentData) {
  const url = env2.api + endPoint.addAgent;
  let formData = new FormData();
  formData.append("file", agentData.file);
  formData.append("name", agentData.name);
  formData.append("version", agentData.version);
  formData.append("platform", agentData.platform);
  if (agentData?.id) {
    formData.append("id", agentData.id);
  }
  enqueueSnackbar(PREPARING_AGENT_FILE,{variant:"info"});
  const response = await AxiosFetch("PUT", url, null, formData);
  return getResultAndShowSuccess(response, AGENT_LOADED);
}

export async function deleteOldAgentAsync(token, agentId) {
  const url = env2.api + endPoint.deleteOldAgent + agentId;
  const response = await AxiosFetch("GET", url);
  return getResultAndShowSuccess(response, AGENT_DELETED);
}

export async function deleteAgentAsync(token, agentId) {
  const url = env2.api + endPoint.deleteAgent + agentId;
  const response = await AxiosFetch("GET", url);
  return getResultAndShowSuccess(response, AGENT_DELETED);
}
