/* eslint-disable no-undef */

export default function PieChartAgent(
  data, disposed
) {
  let arraydata = data.map((e)=>({
    name: e.name,
    value: e.value.toFixed(1),
    neto:e?.neto
  }));

  am4core.useTheme(am4themes_dark);
    am4core.useTheme(am4themes_animated);
  let chart = am4core.create("SuccessandFail", am4charts.PieChart);
  chart.logo.height = -15000;

  // Add and configure Series
  let pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = "value";
  pieSeries.dataFields.category = "name";

  // Let's cut a hole in our Pie chart the size of 30% the radius
  chart.innerRadius = am4core.percent(30);

  // Put a thick white border around each Slice
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeWidth = 2;
  pieSeries.slices.template.strokeOpacity = 1;
  // pieSeries.slices.template
  // // change the cursor on hover to make it apparent the object can be interacted with
  // .cursorOverStyle = [
  //     {
  //     "property": "cursor",
  //     "value": "pointer"
  //     }
  // ];

  var colorSet = new am4core.ColorSet();
  colorSet.list = ["#38c135", "#ff4747"].map(function(color) {
      return new am4core.color(color);
    });
  pieSeries.colors = colorSet;
  //  pieSeries.alignLabels = false;
  // pieSeries.labels.template.bent = true;
  // pieSeries.labels.template.radius = 3;
  // pieSeries.labels.template.padding(0,0,0,0);
  // pieSeries.labels.template.fill = am4core.color("white");
  pieSeries.slices.template.tooltipText = "{name}: {neto}";
  //pieSeries.tooltip.disabled = true;
  
  pieSeries.ticks.template.disabled = false;

  // Create a base filter effect (as if it's not there) for the hover to return to
  let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
  shadow.opacity = 0;

  // Create hover state
  let hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

  // Slightly shift the shadow and make it more prominent on hover
  let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
  hoverShadow.opacity = 0.7;
  hoverShadow.blur = 5;

  // Add a legend
//  chart.legend = new am4charts.Legend(); 
//  chart.legend.position="right" ;
 
 chart.data = arraydata;
 if(disposed === true)
  {chart.dispose();}
}
