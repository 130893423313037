import { useState } from "react";
import React from "react";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Modal } from "@mui/material";

import detailsIcon from "../../../assets/images/eye-icon.svg";
import { StyledDataGrid } from "../../../ayudas/Themes";

export default function ModelBase(props) {
  const { DataSettings } = props;
  const [Content, setContent] = useState([]);
  const [open, setOpen] = useState(false);

  const ChangeName = (Name) => {
    switch (Name) {
      case "PORTHTTP":
        return "Port HTTP";
      case "PORTHTTPS":
        return "Port HTTPS";
      case "INSTANCIA":
        return "Instance";
      case "MAXHTTPBUFFERSIZE":
        return "Max HTTP buffer size";
      case "ORIGIN":
        return "Origin";
      case "PATH":
        return "Path";
      case "PORTWS":
        return "Port WS";
      case "PORTWSS":
        return "Port WSS";
      case "noAgents":
        return "Number of Agents";
      case "HOSTNAME":
        return "Host Name";
      case "IPADDRESS":
        return "IP Address";
      case "C2":
        return "C2";
      case "C2_HOST":
        return "C2 Host";
      case "C2_QUERY":
        return "C2 Query";
      case "C2_SAVE":
        return "C2 Save";
      case "INTERNALURL":
        return "Internal URL";
      case "URL":
        return "URL";
      case "CLIENTID":
        return "Client ID";
      default:
        break;
    }
  };

  const handleOpen = () => {
    let array = [];
    DataSettings.settings?.forEach((element, index) => {
      array.push({
        id: index + "tablecontent",
        variable: ChangeName(element.variable),
        value: element.value,
      });
    });
    setContent(array);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: "variable", headerName: "Variable", flex: 1 },
    {
      field: "value",
      flex: 1,
      headerName: "Value",
    },
  ];
  const body = (
    <Box
      className="Section"
      sx={{
        backgroundColor: "#090f1a",
        borderBottom: "1px solid #1e1c3a",
        width: "40%",
      }}
    >
      <Typography align="center" variant="h6">
        Settings
      </Typography>
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgb(36,41,51)",
          padding: "15px",
          boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
          borderRadius: "12px",
          marginTop: "24px",
        }}
      >
        <StyledDataGrid
          rows={Content}
          columns={columns}
          disableSelectionOnClick
          density="comfortable"
          autoPageSize
          // autoHeight
        />
      </Box>
    </Box>
  );

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <img src={detailsIcon} alt="" />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
