import { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import GeneralModalDelete from "../../../../../../ayudas/CommonComponents/GeneralDeleteModal";
import { deleteScriptByIdAsync } from "../../../../Services/ServiceScriptPage";

export default function DeleteModalScript(props) {
  const { ScriptData } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const DeleteFucntion = async (e) => {
    await deleteScriptByIdAsync(localStorage.getItem("token"), ScriptData.id);
    props.Reload();
    setOpen(false);
  };

  return (
    <Box style={{ display: "inline-block" }}>
      <Tooltip title="Delete">
        <IconButton
          className="iconButtonSize"
          onKeyDown={(event) => {
            if (event.key === " ") {
              // Prevent key navigation when focus is on button
              event.stopPropagation();
            }
          }}
        >
          <Delete
            color="inherit"
            onClick={handleOpen}
            style={{ cursor: "pointer" }}
          />
        </IconButton>
      </Tooltip>
      <GeneralModalDelete
        HeaderText="Do you want to delete this script?"
        BodyText="Removing this script may affect emulations containing this script."
        ExternalOpen={open}
        CloseAction={handleClose}
        CancelAction={handleClose}
        PrimaryAction={DeleteFucntion}
      />
    </Box>
  );
}
