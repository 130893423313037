import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";

import { ThemeProvider, Checkbox, Typography, Tooltip } from "@mui/material";

import {
  pageTheme,
  StyledDataGrid,
  QuickSearchToolbar,
  escapeRegExp,
  CustomLoadingOverlay,
} from "../../../../../../ayudas/Themes";
import ConnexionIcon from "../../../../../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../../../../../assets/images/not-connected-icon.svg";
import { getClientsAsync } from "../../../../../../Services/Clients";
import { GridOverlay } from "@mui/x-data-grid-pro";
import TablePaginationActions from "../../../../../../ayudas/TablePaginations";
import { DesktopMacTwoTone } from "@mui/icons-material";

export default function AgentsToAll(props) {
  const { ValuesCheckedAgentsAll } = props;
  const [tempRowsForAllAgentsClients, settempRowsForAllAgentsClients] =
    useState([]);
  const [checkedAgentsAll, setCheckedAgentsAll] = useState(
    ValuesCheckedAgentsAll
  );
  const [sortModel, setSortModel] = useState([
    { field: "online", sort: "desc" },
  ]);
  const [loading, setLoading] = useState(false);
  const rowsPerPage = 10;
  const [selectionModel, setSelectionModel] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalRows, settotalRows] = useState([]);
  const [page, setPage] = useState(0);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsPerPage,
    page: page,
  });

  const newGetAllAgentsClients = useCallback(async () => {
    setLoading(true);

    const response = await getClientsAsync();
    if (response) {
      let array = [];
      response.forEach((element) => {
        array.push({
          id: element._id,
          online: element.online,
          hostname: element?.hostname
            ? element.hostname
            : element.workUser[0].hostname,
          ip:
            element.IPInformation.length > 0
              ? element.IPInformation[0].public.IP
              : "N/A",
          private:
            element.IPInformation.length > 0
              ? element.IPInformation[0].private.IP
              : "N/A",
          port:
            element.IPInformation.length > 0
              ? element.IPInformation[0].port
              : "N/A",
          osInformation: element.osInformation[0].name,
        });
      });
      settempRowsForAllAgentsClients(array);
      settotalRows(array);
      setLoading(false);
    } else {
      settempRowsForAllAgentsClients([]);
      settotalRows([]);
    }
  }, []);

  useEffect(() => {
    newGetAllAgentsClients();
  }, [newGetAllAgentsClients]);

  useEffect(() => {
    setSelectionModel(ValuesCheckedAgentsAll);
  }, [ValuesCheckedAgentsAll]);

  const handleToggleAgentsOnlineAll = (value) => () => {
    const currentIndex = checkedAgentsAll.indexOf(value);
    const newChecked = [...checkedAgentsAll];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAgentsAll(newChecked);
    props.handleCheckedAgentsAll(newChecked);
  };

  const Columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
      filterable: false,
      sortable: false,
      minWidth: 40,
      align: "center",
      renderHeader: () => <Box style={{ color: "#0a0818" }}>|</Box>,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Checkbox
          color="success"
          onClick={handleToggleAgentsOnlineAll(params.id)}
          checked={selectionModel.indexOf(params.id) !== -1}
        ></Checkbox>
      ),
    },
    {
      field: "online",
      headerName: "Online",
      flex: 0.5,
      minWidth: 50,
      align: "center",
      type: "boolean",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <DesktopMacTwoTone style={{ verticalAlign: "middle" }} />
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.value ? (
            <>
              <Box sx={{ width: "50%" }}>
                <Tooltip title="Connected">
                  <img
                    src={ConnexionIcon}
                    alt=""
                    style={{ marginRight: "15px" }}
                  />
                </Tooltip>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ width: "50%" }}>
                <Tooltip title="Disconnected">
                  <img
                    src={DesconnexionIcon}
                    alt=""
                    style={{ marginRight: "15px" }}
                  />
                </Tooltip>
              </Box>
            </>
          )}
        </>
      ),
    },
    {
      field: "hostname",
      headerName: "Host Name",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ alignContent: "center", height: "100%" }}>
          <Typography
            style={{
              color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
            }}
          >
            {params.row.hostname}
          </Typography>
        </Box>
      ),
    },
    {
      field: "private",
      headerName: "Ip Private",
      sortable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      field: "osInformation",
      headerName: "Operating System",
      sortable: false,
      filterable: false,
      flex: 1,
    },
  ];
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    settempRowsForAllAgentsClients(filteredRows);

    setPage(0);
    if (searchValue === "") {
      settempRowsForAllAgentsClients(totalRows);
    }
  };

  return (
    <>
      <ThemeProvider theme={pageTheme}>
        <Box
          style={{
            width: "100%",
            height: "510px",
            backgroundColor: "rgb(36, 41, 51)",
            border: "1px solid #1e1c3a",
            borderRadius: "8px",
          }}
        >
          <Typography sx={{ marginTop: "10px", textAlign: "center" }}>
            Select Endpoints
          </Typography>
          <Box style={{ maxHeight: "480px", overflow: "auto", width: "100%" }}>
            <StyledDataGrid
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
              slots={{
                toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
              }}
              rows={tempRowsForAllAgentsClients}
              columns={Columns}
              pagination
              pageSize={rowsPerPage}
              count={1000}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5, 10, 25]}
              page={page}
              disableSelectionOnClick
              loading={loading}
              density="comfortable"
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
            />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
