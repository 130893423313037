import styled from "@emotion/styled";
import { HomeEpiphany } from "./paginas/Home/HomeEpiphany";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  backgroundColor: "rgb(36, 41, 51)",
  color: "#fff",
  "&.notistack-MuiContent-success": {
    borderLeft: "20px solid green",
  },
  "&.notistack-MuiContent-error": {
    borderLeft: "20px solid red",
  },
  "&.notistack-MuiContent-info": {
    borderLeft: "20px solid blue",
  },
  "&.notistack-MuiContent-warning": {
    borderLeft: "20px solid yellow",
  },
}));

function AppEpiphany() {
  return (
    <SnackbarProvider
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    preventDuplicate
    autoHideDuration={3000}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
    >
      <HomeEpiphany />
    </SnackbarProvider>
  );
}

export default AppEpiphany;
