import moment from "moment";
export const GetDate = (dateData) => {
  if (dateData) {
    return moment(dateData).format("YYYY-MM-DD hh:mm:ss a");
  }
};
// lets

export const rolValidation = () => {
  let data = localStorage.getItem("UsrLevel")
  let bool =data !== "admin"
  return bool;
};
