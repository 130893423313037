import { getAgentsAsync } from "../../../../Services/Agent";

export const criticalColors = (threatType) => {
  let Color = "";
  switch (threatType) {
    case "Zero-Day":
      Color = "darkred";
      break;
    case "Critical":
      Color = "#6a0000";
      break;
    case "High":
      Color = "#ff0000";
      break;

    case "Midd":
      Color = "#ff5830";
      break;

    case "Low":
      Color = "#ffffa2";
      break;

    case "None":
      Color = "#c039ff";
      break;
    default:
      Color = "#FFF";
      break;
  }
  return Color;
};

export const GenerateParamsSuccesAndFails = (array) => {
  var successPer = array.statistics.efficiency;
  var failedPer = 100 - successPer;
  let succesNeto = array.statistics.successful;
  let failNeto = array.statistics.failed;
  let exeStatusTop = [
    {
      name: "Success",
      value: successPer,
      neto: succesNeto,
    },
    {
      name: "Failed",
      value: failedPer,
      neto: failNeto,
    },
  ];
  var totalExe =
    array.resumeQualification.failed +
    array.resumeQualification.partiallySatisfactory +
    array.resumeQualification.satisfactory;
  return { topStatus: exeStatusTop, totalExecution: totalExe };
};

export const GenerateDataTable = (array) => {
  let Data = [];
  array.executions?.forEach((execution) => {
    if (execution.Reports.length > 0) {
      execution.Reports?.forEach((report, index) => {
        if (report.historys.packages.length > 0) {
          report.historys.packages?.forEach((pack, index) => {
            let datestart = new Date(report.historys.general[0].createdAt);
            Data.push({
              id: Math.random(),
              Execution: execution.name,
              Package: pack.name,
              Success: execution.statistics.successful,
              Fail: execution.statistics.failed,
              Qualification: execution.statistics.qualification,
              Date: datestart.toString(),
              DateString:datestart,
              PackageData: pack,
            });
          });
        }
      });
    }
  });
  return Data;
};

const getInstallerVersion = async () => {
  
  const response = await getAgentsAsync();
  if (response) {
    let data = {
      versionWindows: "",
      versionLinux: "",
    };
    response?.forEach((element) => {
      if (element.platform === "Windows") {
        data.versionWindows = element.version;
      } else {
        data.versionLinux = element.version;
      }
    });
    return data;
  }
};

export const GenerateDataClients = async (response) => {
  let array = [];
  let data = await getInstallerVersion();
  if(response.query === true){
    response.result.forEach((element) => {
      let OS = 0;
      OS = element.osInformation[0].platform.search("Windows") !== -1 ? 1 : OS;
      OS = element.osInformation[0].platform.search("GNULinux") !== -1 ? 2 : OS;
      OS = element.osInformation[0].platform.search("Mac") !== -1 ? 3 : OS;
      let ippublic = element.IPInformation[0].public.IP.split("::ffff:");
      let version =
        element.osInformation[0].platform.search("Windows") !== -1
          ? data.versionWindows
          : data.versionLinux;
      array.push({
        id: element._id,
        online: element.online,
        isolation: element.online ? element.isolation : false,
        hostname: element.hostname
          ? element.hostname
          : element.workUser[0].hostname,
        ip: element.IPInformation.length > 0 ? ippublic : "N/A",
        private:
          element.IPInformation.length > 0
            ? element.IPInformation[0].private.IP
            : "N/A",
        port:
          element.IPInformation.length > 0
            ? element.IPInformation[0].port
            : "N/A",
        hardwareInformation: element.hardwareInformation,
        osInformation: element.osInformation,
        OS: OS,
        workUser: element.workUser,
        files: [],
        fileRequest: false,
        fk_agent: element.fk_agent,
        fkaghis: false,
        lastVersion: element.lastVersion,
        updated: version === element.lastVersion ? true : false,
        group: element.fk_subGroup?._id,
        alias: element?.alias
          ? element.alias
          : element.hostname
          ? element.hostname
          : element.workUser[0].hostname,
      });
    });
  }
  
  return array;
};
