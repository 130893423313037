import React, { useEffect, useState } from "react";
import {
  pageTheme,
  StyledDataGrid,
  TableBox,
  STATUS_TAG,
  FormInputTextField,
} from "../../../../ayudas/Themes";
import {
  Box,
  Chip,
  IconButton,
  ThemeProvider,
  Typography,
  Checkbox,
  TablePagination,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import TablePaginationActions from "../../../../ayudas/TablePaginations";

export default function ArtifactTable(props) {
  const { artifactsSelectedInPage, handlePage } = props;
  const [FilterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setloading] = useState(false);
  const [sortModel, setSortModel] = useState([
    { field: "lastModification", sort: "desc" },
  ]);
  const [selectionModel, setSelectionModel] = useState(artifactsSelectedInPage);

  const handleToggleArtifacts = (value) => () => {
    const currentIndex = findWithAttr(selectionModel, "id", value.id);

    const newChecked = [...selectionModel];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectionModel(newChecked);
    props.handleSelectionModelChange(newChecked);
  };
  useEffect(() => {
    if (selectionModel.length > 30) {
      enqueueSnackbar("Sorry to limit package is 30", { variant: "error" });
    }
    setSelectionModel(artifactsSelectedInPage);
  }, [artifactsSelectedInPage, selectionModel.length]);

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  const Columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
      filterable: false,
      sortable: false,
      minWidth: 40,
      align: "center",
      renderHeader: () => <Box style={{ color: "#0a0818" }}>|</Box>,
      renderCell: (params) => (
        <>
          <Checkbox
            style={{ padding: 10 }}
            onClick={handleToggleArtifacts({
              id: params.row.id,
              name: params.row.name,
              threatType: params.row.threatType,
              file: params.row.file,
              phaseAttacks: params.row.phaseAttacks,
              md5: params.row.md5,
            })}
            checked={findWithAttr(selectionModel, "id", params.value) !== -1}
          />
        </>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "threatType",
      headerName: "Challenge Level",
      sortable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      field: "tagTest",
      headerName: "Status",
      sortable: false,
      filterable: false,
      flex: 0.5,
      align: "center",
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={STATUS_TAG[params.value].label}
          color={STATUS_TAG[params.value].chipColor}
        />
      ),
    },
    {
      field: "lastModification",
      headerName: "Date Uploaded",
      flex: 1,
      filterable: false,
    },
  ];

  const HandleFilterName = (e) => {
    setPage(0);
    setFilterName(e.target.value);
  };

  useEffect(() => {
    const field =
      sortModel[0] === undefined ? "lastModification" : sortModel[0].field;
    const sort = sortModel[0] === undefined ? "desc" : sortModel[0].sort;
    handlePage(rowsPerPage, page, sort, field, FilterName);
  }, [page, rowsPerPage, sortModel, FilterName, handlePage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlepaginationMode = (event) => {
    setloading(true);
    if (
      event.pageSize === 5 ||
      event.pageSize === 10 ||
      event.pageSize === 20
    ) {
      setRowsPerPage(parseInt(event?.pageSize, 10));
      setPage(parseInt(event?.page));
    }
    setloading(false);
  };

  return (
    <Box style={{ width: "100%" }}>
      <ThemeProvider theme={pageTheme}>
        <Box style={TableBox}>
          <div
            style={{
              maxHeight: "75vh",
              overflow: "auto",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            <FormInputTextField
              onChange={(e) => HandleFilterName(e)}
              color="secondary"
              variant="outlined"
              value={FilterName}
              label="Search name…"
              InputProps={{
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    onClick={() => setFilterName("")}
                  >
                    {FilterName ? <Clear /> : <Search />}
                  </IconButton>
                ),
              }}
              style={{ marginBottom: "7px", marginTop: "5px" }}
            />
            <Typography
              style={{
                color: "rgba(255,255,255,0.65)",
                fontSize: "0.75rem",
                fontWeight: "600",
                marginLeft: "7px",
              }}
            >
              Artifacts selected :{" "}
              <Typography
                color="#c59d48"
                style={{
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  display: "inline-block",
                  marginLeft: "7px",
                }}
              >
                {props.labelDisplay}
              </Typography>
            </Typography>
            <StyledDataGrid
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
              }}
              slots={{
                Pagination: () => (
                  <>
                    <TablePagination
                      component="div"
                      rowCount={props.rowCount}
                      pageSizeOptions={[5, 10, 20]}
                    />
                  </>
                ),
              }}
              pagination={!loading}
              pageSizeOptions={[5, 10, 20]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(newSort) => setSortModel(newSort)}
              columns={Columns}
              rows={props.rows}
              rowCount={props.rowCount}
              autoPageSize
              disableColumnMenu={true}
              disableSelectionOnClick
              disableRowSelectionOnClick
              disableColumnSelector
              suppressPaginationPanel={true}
              disableDensitySelector
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              onPaginationModelChange={(event) => {
                handlepaginationMode(event);
              }}
            />
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
