import React from 'react';
import { Box, Typography, Chip} from '@mui/material';
import FlowButtons from "./FlowButtons";
import "../../Packages.scss";
import FileUpload from '../../../../ayudas/CommonComponents/FileUpload';

class PdfForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            fileDeleted: false,
                };    
    }

    handleInputFile = (file) => {
        if (file) {
              this.props.handleChange(file);
          }
       
    }

    handleDelete = () => {
        // console.info('You clicked the delete icon.');
        this.props.handleDeleted();
    }

    renderChipPdfName =()=> {
        return (
            <Box>
                <Chip 
                    label={this.props.valueName} 
                    color="primary"
                    onDelete={this.handleDelete} 
                />
            </Box>
        );
    }

    renderInputFile =()=> {
        return(
            <Box className="ContainerTwo">
                <FileUpload HandleFile={this.handleInputFile}/>
            </Box>
        );
    }

    render(){
        return (
            <Box>
                <Box className="rt-InputContent">
                        <Typography className="TexTTitles"> {this.props.title} </Typography>
                        <Box className="ContainerOne">
                            {this.props.valueName ? this.renderChipPdfName() : null}    
                            {this.props.valueName ? null : this.renderInputFile()}
                        </Box>
                </Box>

            <FlowButtons labelNext= "Next" 
                         handleNext= {this.props.handleNext} 
                         handleBack={this.props.handleBack}
            />
            </Box>
        )
    }
}

export default PdfForm;