import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, TablePagination } from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import scheduledIcon from "../../../assets/images/new-scheduled-icon.svg";
import PopUpDetailsScheduled from "./PopUpDetailsScheduled/PopUpDetailsScheduled";
import PopUpDeleteScheduled from "./PopUpDeleteScheduled/PopUpDeleteScheduled";
import { ThemeProvider } from "@emotion/react";
import {
  QuickSearchToolbar,
  pageTheme,
  StyledDataGrid,
  CustomLoadingOverlay,
  escapeRegExp,
} from "../../../ayudas/Themes";
import { GridOverlay } from "@mui/x-data-grid-pro";
import { GetDate } from "../../../ayudas/ScriptsFunctions";
import { Delete } from "@mui/icons-material";
import { env2 } from "../../../ayudas/Instance";
import { endPoint } from "../../../Services/EndPoint";
import AxiosFetch from "../../../Services/FetchServices";
import axios from "axios";
import TablePaginationActions from "../../../ayudas/TablePaginations";

const ButtonScheduled = styled(Button)({
  padding: "10px",
  color: "#fff",
  backgroundColor: "#b69141 ",
  "&:hover": {
    backgroundColor: "#b69141",
  },
});

export default function ExecutionsScheduled(props) {
  const { effectRefresh1, Token } = props;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tempRows, settempRows] = useState([]);
  const [openModalEditScheduled, setOpenModalEditScheduled] = useState(false);
  const [ExecutionScheduledDataRow, setExecutionScheduledDataRow] = useState(
    []
  );
  const [idOfExecutionDataRow, setIdOfExecutionDataRow] = useState();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);
  const [totalRows, settotalRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [signalReload, setsignalReload] = useState(0);
  const [disabledPagination, setdisabledPagination] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      const baseUrl = `${env2.api}${endPoint.scheduledExecutions}`;
      setLoading(true);
      const data = await AxiosFetch("GET", baseUrl, source);
      if (data) {
        setLoading(false);        
      }
      const response = data?.msg ? data.msg : null;
      if (response?.result && response.query) {
        let NoTotal = response.result.length;
        setCount(NoTotal);
        settempRows(response.result);
        settotalRows(response.result);
      } else {
        settempRows([]);
        settotalRows([]);
        setCount(0);
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [effectRefresh1, signalReload]);

  useEffect(() => {
    setExecutionScheduledDataRow(ExecutionScheduledDataRow);
  }, [ExecutionScheduledDataRow]);

  const openModalEditScheduledEffect = (value) => {
    setOpenModalEditScheduled(value);
  };

  const effectModalDelete = (value) => {
    setOpenModalDelete(value);
  };

  const effectRefresh = () => {
    setsignalReload(signalReload + 1);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "createdBy",
      headerName: "Updated By",
      flex: 1,
      minWidth: 150,

      renderCell: (params) => <>{params.row.createdBy}</>,
    },
    {
      field: "createdAt",
      headerName: `Created GTM  ${Token.GMT}`,
      flex: 1,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          {`Date Created`}
        </Box>
      ),
      renderCell: (params) => <>{GetDate(params.value)}</>,
    },
    {
      field: "updatedAt",
      headerName: `Updated GTM  ${Token.GMT}`,
      flex: 1,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          {`Date Uploaded`}
        </Box>
      ),
      renderCell: (params) => <>{GetDate(params.value)}</>,
    },
    {
      field: "startDate",
      headerName: `Start GTM  ${Token.GMT}`,
      flex: 1,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          {`Date Started`}
        </Box>
      ),
      renderCell: (params) => <>{GetDate(params.value)}</>,
    },
    {
      field: "calendar",
      headerName: "Calendar",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Calendar</Box>
      ),
      renderCell: (params) => (
        <>
          <Tooltip title="View Details Scheduled" arrow>
            <ButtonScheduled
              onClick={() => {
                setOpenModalEditScheduled(true);
                setExecutionScheduledDataRow(params.row);
              }}
            >
              <img style={{ marginRight: "8px" }} src={scheduledIcon} alt="" />
              Scheduled
            </ButtonScheduled>
          </Tooltip>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      align: "center",
      minWidth: 150,
      type: "actions",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Actions</Box>
      ),
      renderCell: (params) => (
        <>
          <Tooltip title="Delete Emulation" arrow>
            <IconButton
              onClick={() => {
                setIdOfExecutionDataRow(params.row.id);
                setOpenModalDelete(true);
                setsignalReload(signalReload + 1);
              }}
            >
              <Delete color="inherit" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    settempRows(filteredRows);
    let NoTotal = filteredRows.length;
    setCount(NoTotal);
    setPage(0);
    if (searchValue === "") {
      settempRows(totalRows);
      let NoTotal = totalRows.length;
      setCount(NoTotal);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlepaginationMode = (event) => {
    if (
      event.pageSize === 5 ||
      event.pageSize === 10 ||
      event.pageSize === 20
    ) {
      setRowsPerPage(parseInt(event?.pageSize, 10));
      setPage(parseInt(event?.page));
      setdisabledPagination(true);
    }
  };

  return (
    <>
      <ThemeProvider theme={pageTheme}>
        <Box
          style={{
            width: "100%",
            backgroundColor: "#242933",
            border: " 1px solid #090f1a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "14px",
            height: "auto",
          }}
        >
          <div style={{ height: "auto", width: "100%" }}>
            <StyledDataGrid
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
              slots={{
                toolbar: QuickSearchToolbar,
                loadingOverlay: CustomLoadingOverlay,
                noRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                noResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
              
              }}
              rows={tempRows}
              columns={columns}
              pagination
              autoHeight={true}
              initialState={{
                pagination: { paginationModel: { pageSize: rowsPerPage } },
              }}
              pageSizeOptions={[5, 10, 20]}
              loading={loading}
              disableRowSelectionOnClick
              disableColumnSelector
              disableSelectionOnClick
              suppressPaginationPanel={true}
              disableDensitySelector
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              onPaginationModelChange={(event) => {
                handlepaginationMode(event);
              }}   
              density="comfortable"
            />
          </div>
        </Box>
        <PopUpDetailsScheduled
          DataUser={Token}
          InfoExecutionScheduled={ExecutionScheduledDataRow}
          GetOpenModal={openModalEditScheduled}
          effectModalEdit={openModalEditScheduledEffect}
        ></PopUpDetailsScheduled>

        <PopUpDeleteScheduled
          idExecution={idOfExecutionDataRow}
          DataUser={Token}
          GetOpenModal={openModalDelete}
          effectModal={effectModalDelete}
          effectRefresh={effectRefresh}
        ></PopUpDeleteScheduled>
      </ThemeProvider>
    </>
  );
}
