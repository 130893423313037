import React, { useState } from "react";
import { Box } from "@mui/material";

import "../Incursion.scss";
import PackageColorIcon from "../../../assets/images/select-package.svg";
import PackageDetails from "../../Packages/Sections/Details/PackageDetails";
import {
  getAnexoPackage,
  getPackageByIdAsync,
} from "../../../Services/Package";
import { enqueueSnackbar } from "notistack";

export default function PackModal(props) {
  const { Row } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const handleOpen = async () => {
    let response = await getPackageByIdAsync(Row.id);
    if (response) {
      setData(response);
      setOpen(true);
    } else {
      enqueueSnackbar("Don't found package's detail",{variant:"info"});
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DownloadPDFFile = () => {
    getAnexoPackage(localStorage.getItem("token"), Row.id, Row.anexo);
  };

  return (
    <Box>
      <img onClick={handleOpen} src={PackageColorIcon} alt="" />
      <PackageDetails
        open={open}
        packageDetails={data ? data : Row}
        downloadPdfFile={DownloadPDFFile}
        onClose={handleClose}
      ></PackageDetails>
    </Box>
  );
}
