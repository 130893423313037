import { useEffect, useState } from "react";
import { getClientsAsync } from "../Services/Clients";

import ExcelIcon from "../assets/images/excel-icon.svg";
import { DownloadOutlined } from "@mui/icons-material";
import "./ExportExcel.scss";
import ExporExcel from "./ExportExcel";
import { getAllPackagesAsync } from "../Services/Package";
import { GetExecutionsAsymc } from "../Services/Executions";
import { myColor } from "../BaseCss";
import { GetDate, rolValidation } from "./ScriptsFunctions";
import { GetDataClientAll } from "../paginas/Agents/Common/Models/ModelClientDataAgent";
import { enqueueSnackbar } from "notistack";
const styleButton = {
  maxWidth: "250px",
  margin: "8px",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "10px 30px",
  borderRadius: "12px",
  border: "1px solid gray",
  lineHeight: 1.5,
  justifyContent: "center",
  display: "flex",
  backgroundColor: myColor.SecondaryButton,
  color: "#fff",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#544628",
    borderColor: "#1C1A33",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "090f1a",
    borderColor: "#1C1A33",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "#1C1A33",
  },
};

export default function ExporExcelMiddle(props) {
  const { pageOrigin, fileName, externalData = [] } = props;
  const [data, setData] = useState(null);
  const [process, setProcess] = useState(false);

  const AgentsExcel = async () => {
    let dataAllAgents;
    let response = await getClientsAsync();
    if (response) {
      dataAllAgents = GetDataClientAll(response);
    }
    let expo = {
      data: dataAllAgents,
      colums: [
        { header: "Host name", key: "hostname" },
        { header: "Private IP address", key: "private" },
        { header: "Public IP address ", key: "ip" },
        { header: "Port", key: "port" },
      ],
    };

    setData(expo);
  };
  const AgentHistoryExcel = () => {
    let dataxlsx = [];
    externalData?.forEach((element) => {
      dataxlsx.push({
        Execution: element.Execution,
        Package: element.Package,
        Success: element.Success,
        Fail: element.Fail,
        Date: GetDate(element.Date),
      });
    });
    let expo = {
      data: dataxlsx,
      colums: [
        { header: "Emulation", key: "Execution" },
        { header: "Package", key: "Package" },
        { header: "Success", key: "Success" },
        { header: "Fail", key: "Fail" },
        { header: "Date", key: "Date" },
      ],
    };

    setData(expo);
  };
  const PackageExcel = async () => {
    let response = await getAllPackagesAsync();
    if (response) {
      response.forEach((packagedetails) => {
        packagedetails.createdAt = GetDate(packagedetails.createdAt);
        packagedetails.updatedAt = GetDate(packagedetails.updatedAt);
      });
    }
    let expo = {
      data: response,
      colums: [
        { header: "Name", key: "name" },
        { header: "Created", key: "createdAt" },
        { header: "Updated", key: "updatedAt" },
        { header: "Type", key: "type" },
      ],
    };

    setData(expo);
  };

  const ExecutionExcel = async () => {
    const response = await GetExecutionsAsymc();
    let array = [];
    response?.forEach((element) => {
      array.push({
        name: element.name,
        lastModification: GetDate(element.updatedAt),
        status: element.status,
        vector:
          element.vector === "RealExecution"
            ? "Execution"
            : element.vector === "EPP"
            ? "Endpoint"
            : "Network",
      });
    });

    let expo = {
      data: array,
      colums: [
        { header: "Name", key: "name" },
        { header: "Last Modification", key: "lastModification" },
        { header: "Status", key: "status" },
        { header: "Vector", key: "vector" },
      ],
    };

    setData(expo);
  };
  const ReportExcel = async () => {
    setData(externalData);
  };

  const handleClick = async () => {
    setProcess(true);

    switch (pageOrigin) {
      case "Agents":
        AgentsExcel();
        break;
      case "AgentsHistory":
        AgentHistoryExcel();
        break;
      case "Packages":
        PackageExcel();
        break;
      case "Execution":
        ExecutionExcel();
        break;
      case "ReportExecution":
        ReportExcel();
        break;
      case "ScriptReport":
        ReportExcel();
        break;
      case "AgentDiagram":
        ReportExcel();
        break;
      case "Groups":
        ReportExcel();
        break;
      case "Users":
        ReportExcel();
        break;

      default:
        // console.log("execute excel export");
        break;
    }
  };

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        if (data.data.length === 0 || !data.data) {
          enqueueSnackbar("Data not found to export", {
            variant: "info",
          });
        } else {
          let button = document.getElementById("exportedButton");
          if (button) {
            button.click();
          }
          setProcess(false);
          setData(null);
        }
      }, 3000);
    }
  }, [data]);

  return (
    <>
      <div
        disabled={pageOrigin === "none" || !rolValidation()}
        variant="outlined"
        id="exportedButtonAction"
        style={styleButton}
        onClick={() => {
          if (!rolValidation()) {
            handleClick();
          }
        }}
      >
        {!process ? (
          <img
            src={ExcelIcon}
            alt=""
            style={{ width: "20px", marginRight: "15px" }}
          />
        ) : (
          <DownloadOutlined className="iconAnimated" />
        )}
        {!process ? (
          "Get Data Report"
        ) : (
          <label className="textAnimated">Wait at moment ...</label>
        )}
      </div>
      <div
        style={{
          width: "0px",
          height: "0px",
          backgroundColor: "transparent",
          borderColor: "transparent",
        }}
      >
        <ExporExcel importData={data} fileName={fileName} />
      </div>
    </>
  );
}
