import { DesktopMacTwoTone } from "@mui/icons-material";

import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from "react";
import {
  SecondaryButton,
  StyledDataGrid,
  CustomLoadingOverlay,
  QuickSearchToolbar,
  PrimaryButton,
} from "../../../../../../../ayudas/Themes";
import { getClientsAsync } from "../../../../../../../Services/Clients";
import ConnexionIcon from "../../../../../../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../../../../../../assets/images/not-connected-icon.svg";
import WindowIcon from "../../../../../../../assets/images/window-icon.svg";
import LinuxIcon from "../../../../../../../assets/images/linux-icon.svg";
import MacIcon from "../../../../../../../assets/images/apple-logo.svg";
import UnkwonIcon from "../../../../../../../assets/images/device-unknown-icon.svg";
import { enqueueSnackbar } from "notistack";
import TablePaginationActions from "../../../../../../../ayudas/TablePaginations";

export default function StepAgentScript(props) {
  const { valueSelectAgent, AgentConnect } = props;
  const [loading, setLoading] = useState(false);
  const [tempRowsForAllAgentsClients, settempRowsForAllAgentsClients] =
    useState([]);
  const [totalRows, settotalRows] = useState([]);
  const [agentSelection, setAgentSelection] = useState(valueSelectAgent || []);
  const rowsPerPage = 5;
  const [sortModel, setSortModel] = useState([
    { field: "online", sort: "desc" },
  ]);

  const [searchText, setSearchText] = useState("");

  const newGetAllAgentsClients = useCallback(async () => {
    setLoading(true);
    const response = await getClientsAsync();
    if (response) {
      let array = [];
      response.forEach((element) => {
        array.push({
          id: element._id,
          online: element.online,
          hostname: element?.hostname
            ? element.hostname
            : element.workUser[0].hostname,
          ip:
            element.IPInformation.length > 0
              ? element.IPInformation[0].public.IP
              : "N/A",
          private:
            element.IPInformation.length > 0
              ? element.IPInformation[0].private.IP
              : "N/A",
          port:
            element.IPInformation.length > 0
              ? element.IPInformation[0].port
              : "N/A",
          osInformation: element.osInformation[0].name,
          selection: false,
          platform: element.osInformation[0].platform,
        });
      });
      // console.log(array);
      array.forEach((element) => {
        agentSelection.forEach((element2) => {
          if (element.id === element2.id) {
            element.selection = element2.selection;
          }
        });
      });
      settempRowsForAllAgentsClients(array);
      settotalRows(array);
      setLoading(false);
    } else {
      settempRowsForAllAgentsClients([]);
      settotalRows([]);
    }
  }, [agentSelection]);

  useEffect(() => {
    newGetAllAgentsClients();
  }, [AgentConnect, newGetAllAgentsClients]);

  const handleToggleAgents = (value) => () => {
    const currentIndex = findWithAttr(agentSelection, "id", value.id);
    const newChecked = [...agentSelection];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAgentSelection(newChecked);
    if (agentSelection.length >= 30) {
      enqueueSnackbar("Sorry to exceed limit of agents selected is 30.", {
        variant: "error",
      });
    }
  };

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.1,
      filterable: false,
      sortable: false,
      minWidth: 40,
      align: "center",
      renderHeader: () => <Box style={{ color: "#0a0818" }}>|</Box>,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Checkbox
          style={{ padding: 10 }}
          onClick={handleToggleAgents({
            id: params.row.id,
            hostname: params.row.hostname,
            platform: params.row.platform,
          })}
          checked={findWithAttr(agentSelection, "id", params.value) !== -1}
        ></Checkbox>
      ),
    },
    {
      field: "online",
      headerName: "Online",
      minWidth: 50,
      align: "center",
      type: "boolean",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <DesktopMacTwoTone style={{ verticalAlign: "middle" }} />
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.value ? (
            <Box>
              <Tooltip title="Connected">
                <img
                  src={ConnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Tooltip title="Disconnected">
                <img
                  src={DesconnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "platform",
      headerName: "Platform",
      minWidth: 50,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>
          Platform
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.platform === "Windows" ? (
            <img src={WindowIcon} alt="" style={{ marginRight: "7px" }} />
          ) : params.row.platform === "GNULinux" ? (
            <img src={LinuxIcon} alt="" style={{ marginRight: "7px" }} />
          ) : params.row.platform === "Mac" ? (
            <img src={MacIcon} alt="" style={{ marginRight: "7px" }} />
          ) : (
            <img src={UnkwonIcon} alt="" style={{ marginRight: "7px" }} />
          )}
        </>
      ),
    },
    {
      field: "hostname",
      headerName: "Host name",
      minWidth: 200,
      flex: 0.5,
      renderCell: (params) => (
        <>
          <Typography
            style={{
              alignContent: "center",
              height: "100%",
              color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
            }}
          >
            {params.value}
          </Typography>
        </>
      ),
    },
    {
      field: "ip",
      headerName: "Public IP address",
      flex: 0.5,
      minWidth: 150,

      renderCell: (params) => (
        <Typography
          style={{
            alignContent: "center",
            height: "100%",
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
  ];
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    let filteredRows = [];
    totalRows?.forEach((row) => {
      if (row.hostname.toUpperCase().indexOf(searchValue.toUpperCase()) > -1) {
        filteredRows.push(row);
      }
    });
    settempRowsForAllAgentsClients(filteredRows);

    if (searchValue === "") {
      settempRowsForAllAgentsClients(totalRows);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgb(21, 19, 43)",
          padding: "20px",
          borderRadius: "10px",
          width: "100%",
          height: "auto",
          textAlign: "left",
          overflow: "auto",
          maxHeight: "600px",
        }}
      >
        <Typography textAlign={"center"}>Select Endpoints:</Typography>
        <StyledDataGrid
          slotProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
              showFirstButton: true,
              showLastButton: true,
            },
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
          slots={{
            toolbar: QuickSearchToolbar,
            LoadingOverlay: CustomLoadingOverlay,
            NoRowsOverlay: () => (
              <GridOverlay>
                <div>No Data Results</div>
              </GridOverlay>
            ),
            NoResultsOverlay: () => (
              <GridOverlay>
                <div>No Results Filter Data</div>
              </GridOverlay>
            ),
          }}
          showLastButton
          showFirstButton
          rows={tempRowsForAllAgentsClients}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: rowsPerPage } },
          }}
          loading={loading}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          pagination
          pageSizeOptions={[5, 10, 20]}
          density="comfortable"
        />
      </Box>
      <SecondaryButton
        color="inherit"
        // disabled={activeStep === 0}
        onClick={() => {
          props.HandleAgentSelection(agentSelection);
          props.HandleActiveStepBack();
        }}
        sx={{ mr: 1 }}
      >
        Back
      </SecondaryButton>
      <PrimaryButton
        disabled={agentSelection.length > 0 ? false : true}
        style={{ float: "right" }}
        onClick={() => {
          props.HandleAgentSelection(agentSelection);
          props.HandleActiveStepNext();
        }}
      >
        Next
      </PrimaryButton>
    </>
  );
}
