import React, { useState, useEffect, useCallback } from "react";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import launchIcon from "../../../../assets/images/launch-icon.svg";
import { ThemeProvider } from "@mui/material/styles";
import {
  pageTheme,
  PrimaryButton,
  SecondaryButton,
} from "../../../../ayudas/Themes";
import { enqueueSnackbar } from "notistack";

const styleModal = {
  boxShadow: "18px 4px 35px #00000026",
  borderRadius: "14px",
  maxWidth: "700px",
  margin: "0 auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "#0a0818",
  color: "white",
  p: 2,
};
export default function PopUpStartExecution(props) {
  const { DataUser, ClientOfExecution, openModalGet, AgentConnect } = props;
  const [openModal, setOpenModal] = React.useState(openModalGet);
  const [clientOfExecution, setClientOfExecution] = useState(ClientOfExecution);
  const [agentConnected, setAgentsConnecteds] = useState(AgentConnect);

  const ValidateAgentsConExecution = useCallback(() => {
    let agentCon = agentConnected.map((element) => element.hostname);
    let agentsInExecution = ClientOfExecution?.clients.map(
      (element) => element.workUser[0].hostname
    );

    if (DataUser.INSOLATIONPROTECTION === "Y" && ClientOfExecution?.clients) {
      for (let index = 0; index < ClientOfExecution.clients.length; index++) {
        const element = ClientOfExecution.clients[index];
        if (
          !element.isolation &&
          element.online &&
          element.osInformation[0].platform !== "GNULinux"
        ) {
          setIsolationProtectWarning(true);
          break;
        }
      }
    }

    if (agentCon.length === 0) {
      setAllInsiderDesconnetWarning(true);
    } else {
      let totalInsiders = 0;
      agentsInExecution.forEach((element) => {
        let validate = agentCon.indexOf(element);
        if (validate !== -1) {
          totalInsiders++;
        }
      });
      if (totalInsiders === agentsInExecution.length) {
        setAllInsiderConnectMessage(true);
      } else if (
        totalInsiders < agentsInExecution.length &&
        totalInsiders > 0
      ) {
        setAtLeastOneInsiderWarning(true);
      } else {
        setAllInsiderDesconnetWarning(true);
      }
    }
  }, [DataUser, ClientOfExecution, agentConnected]);

  useEffect(() => {
    setOpenModal(openModalGet);
    if (openModalGet) {
      ValidateAgentsConExecution();
    }
  }, [openModalGet, ValidateAgentsConExecution]);

  useEffect(() => {
    setAgentsConnecteds(AgentConnect);
  }, [AgentConnect]);

  useEffect(() => {
    setClientOfExecution(ClientOfExecution);
  }, [ClientOfExecution]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsolationProtectWarning(false);
    setAtLeastOneInsiderWarning(false);
    setAllInsiderDesconnetWarning(false);
    setAllInsiderConnectMessage(false);
    props.effectModal(false);
  };
  const startExecute = () => {
    if (openModal === true) {
      props.Execute(clientOfExecution.id);
      enqueueSnackbar("Emulation is sending",{variant:"success"});
      handleCloseModal();
    }
  };

  const [isolationProtectWarning, setIsolationProtectWarning] = useState(false);
  const [atLeastOneInsiderWarning, setAtLeastOneInsiderWarning] =
    useState(false);
  const [allInsiderDesconnetWarning, setAllInsiderDesconnetWarning] =
    useState(false);
  const [allInsiderConnectMessage, setAllInsiderConnectMessage] =
    useState(false);

  return (
    <ThemeProvider theme={pageTheme}>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={styleModal}>
            <Box
              style={{
                textAlign: "center",
                backgroundColor: "#242933",
                border: "1px solid #090f1a",
                boxShadow: "18px 4px 35px #00000026",
                borderRadius: "14px",
                padding: "15px",
                maxWidth: "700px",
                margin: "0 auto",
              }}
            >
              <Typography id="keep-mounted-modal-title" variant="h6">
                Emulation:
                <p
                  style={{
                    minWidth: "240px",
                    maxWidth: "400px",
                    overflow: "hidden",
                    whiteSpace: "initial",
                    textOverflow: "ellipsis",
                    textAlign: "justify",
                  }}
                >
                  {clientOfExecution?.name}
                </p>
              </Typography>
              {isolationProtectWarning ? (
                <Typography id="keep-mounted-modal-title" variant="h6">
                  The Insider(s) doesn't have the protection of the isolation,
                </Typography>
              ) : null}
              {atLeastOneInsiderWarning ? (
                <Typography id="keep-mounted-modal-title" variant="h6">
                  Some of the Insiders are not connected,
                  <br />
                  Are you sure to start?
                </Typography>
              ) : null}{" "}
              {allInsiderDesconnetWarning ? (
                <Typography id="keep-mounted-modal-title" variant="h6">
                  No Insider is currently online,
                  <br />
                  Are you sure to start?
                </Typography>
              ) : null}
              {allInsiderConnectMessage ? (
                <Typography id="keep-mounted-modal-title" variant="h6">
                  Are you sure to start?
                </Typography>
              ) : null}
              <Box
                style={{
                  textAlign: "center",
                  backgroundColor: "#242933",
                  border: "1px solid #090f1a",
                  boxShadow: "18px 4px 35px #00000026",
                  borderRadius: "14px",
                  padding: "15px",
                  maxWidth: "400px",
                  margin: "0 auto",
                  marginTop: "20px",
                }}
              >
                Endpoints:
                {clientOfExecution?.clients.length ? (
                  clientOfExecution?.clients.map((row, index) => {
                    return <div key={index}>{row.workUser[0].hostname}</div>;
                  })
                ) : (
                  <div>Are not Endpoints viewer package emulation</div>
                )}
              </Box>
              <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                <Box style={{ textAlign: "center", fontSize: "35px" }}>
                  <img style={{ width: "100px" }} src={launchIcon} alt=""></img>
                </Box>
              </Typography>
              <Box
                style={{
                  textAlign: "center",
                }}
              >
                <SecondaryButton onClick={handleCloseModal}>No</SecondaryButton>
                <PrimaryButton onClick={startExecute}>Yes</PrimaryButton>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
}
