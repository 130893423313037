import { enqueueSnackbar } from "notistack";
import { env2 } from "../ayudas/Instance";
import { endPoint } from "./EndPoint";
import { downloadFile } from "./FetchRequests";
import AxiosFetch from "./FetchServices";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";

const ARTIFACT_LOADED = "Artifact loaded successfully";
const PDF_UPDATED = "Pdf file updated successfully";
const ARTIFACT_DELETED = "Artifact deleted successfully";
const PREPARING_ARTIFACT =
  "Validating session to prepare artifact, please wait";

export async function GetArtifactByIdAsync(id) {
  const url = env2.api + endPoint.artifact + id;
  const response = await AxiosFetch("GET", url);
  return getMsgResult(response);
}

export async function getArtifactsPageAsync(
  size,
  Nopage,
  orderby,
  order,
  bodyContent
) {
  const url =
    env2.api +
    endPoint.artifactsPageAdvanced +
    size +
    "/" +
    Nopage +
    "?col=" +
    orderby +
    "&order=" +
    order;
  var ArrayBody = {};
  if (bodyContent.name) {
    ArrayBody.name = bodyContent.name;
  }
  if (bodyContent.threatType) {
    ArrayBody.threatType = bodyContent.threatType;
  }
  if (bodyContent.phaseAttacks) {
    ArrayBody.phaseAttacks = bodyContent.phaseAttacks;
  }
  if (bodyContent.tagTest) {
    ArrayBody.tagTest = bodyContent.tagTest;
  }
  if (bodyContent.platform) {
    ArrayBody.platform = bodyContent.platform;
  }
  const body = JSON.stringify(ArrayBody);
  const response = await AxiosFetch("POST", url, null, body);
  return getMsgResult(response);
}

export async function addArtifactAsync(artifact) {
  let url = env2.api + endPoint.addArtifact;
  var formData = new FormData();
  formData.append("platform", artifact.platform);
  formData.append("file", artifact.file);
  formData.append("fk_threatType", artifact.fk_threatType);
  artifact.phaseAttacks.forEach((phaseAttac) =>
    formData.append("phaseAttacks", phaseAttac)
  );
  formData.append("description", artifact.description);

  formData.append("callback", artifact.isCallback);
  formData.append("evasion", artifact.isEvasion);

  if (artifact.manifest) {
    formData.append("manifest", artifact.manifest);
  }
  if (artifact.resolutionNetwork) {
    formData.append("resolutionNetwork", artifact.resolutionNetwork);
  }
  if (artifact.resolutionEndPoint) {
    formData.append("resolutionEndPoint", artifact.resolutionEndPoint);
  }
  if (artifact.resolutionCallback) {
    formData.append("resolutionCallback", artifact.resolutionCallback);
  }
  if (artifact.urlMitre) {
    formData.append("urlMitre", artifact.urlMitre);
  }
  if (artifact.anexo) {
    formData.append("anexo", artifact.anexo);
  }
  const response = await AxiosFetch("PUT", url, null, formData);
  return getResultAndShowSuccess(response, ARTIFACT_LOADED);
}

export async function downloadArtifactAsync(token, artifactData) {
  const url = env2.api + endPoint.downloadArtifact + artifactData.id + "/ZIP";
  const name = artifactData.name + ".zip";
  enqueueSnackbar(PREPARING_ARTIFACT,{variant:"info"});
  await downloadFile(token, url, name);
}

export async function downloadArtifactPdfAsync(token, pdfData) {
  
  const url = env2.api + endPoint.downloadArtifactPdf + pdfData.id;
  await downloadFile(localStorage.getItem("token"), url, pdfData.name);
}

export async function updatePdfAsync(fileData) {
  let url = env2.api + endPoint.updatePdf + fileData.id;
  var formData = new FormData();
  formData.append("anexo", fileData.anexo);
  const response = await AxiosFetch("PUT", url, null, formData);
  return getResultAndShowSuccess(response, PDF_UPDATED);
}

export async function deleteArtifactByIdAsync(id) {
  const url = env2.api + endPoint.deleteArtifact + id;
  const response = await AxiosFetch("GET", url);
  return getResultAndShowSuccess(response, ARTIFACT_DELETED);
}
