import { Box, Grid2, Tooltip } from "@mui/material";
import { useState } from "react";
import "./GridComponent.scss";
export function SizeBar(props) {
  return (
    <Box className="SizeBar">
      {/* <Tooltip title="Min size">
                <Box className="Item Min" onClick={() => { props.changeSize(1) }} />
            </Tooltip> */}
      <Tooltip title="Standard size">
        <Box
          className="Item Stand"
          onClick={() => {
            props.changeSize(6);
          }}
        />
      </Tooltip>
      <Tooltip title="Max size">
        <Box
          className="Item Max"
          onClick={() => {
            props.changeSize(12);
          }}
        />
      </Tooltip>
    </Box>
  );
}

export default function GridComponent(props) {
  const { children, initialSize, blockSize } = props;
  const [size, setSize] = useState(initialSize ? initialSize : 6);
  const lock = blockSize ? blockSize : false;
  const handleSize = (value) => {
    setSize(value);
  };

  return (
    <Grid2 className="ContainerGrid" item size={size}>
      {lock ? null : <SizeBar changeSize={handleSize} />}
      {children}
    </Grid2>
  );
}
