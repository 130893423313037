/* eslint-disable no-undef */
export default function EmptyChartNotDataAvailable() {
/* Set themes */
am4core.useTheme(am4themes_animated);

/* Create chart */
var chart = am4core.create("NetworkSecurityEvation", am4charts.PieChart);
chart.logo.height = -15000; 
chart.innerRadius = am4core.percent(30)
chart.width = am4core.percent(100);
chart.height = am4core.percent(100);

/* Dummy innitial data data */
chart.data = [{
  "country": "Dummy",
  "disabled": true,
  "value": 1000,
  "color": am4core.color("#dadada"),
  "opacity": 0.1,
  "strokeDasharray": "4,4",
  "tooltip": "No data available"
}];

/* Create series */
var series = chart.series.push(new am4charts.PieSeries());
series.dataFields.value = "value";
series.dataFields.category = "country";
series.dataFields.hiddenInLegend = "disabled";

/* Set tup slice appearance */
var slice = series.slices.template;
slice.propertyFields.fill = "color";
slice.propertyFields.fillOpacity = "opacity";
slice.propertyFields.stroke = "color";
slice.propertyFields.strokeDasharray = "strokeDasharray";
slice.propertyFields.tooltipText = "tooltip";


series.labels.template.propertyFields.disabled = "disabled";
series.ticks.template.propertyFields.disabled = "disabled";

/* This creates initial animation */
series.hiddenState.properties.opacity = 1;
series.hiddenState.properties.endAngle = -90;
series.hiddenState.properties.startAngle = -90;

}