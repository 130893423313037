import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./FileUpload.scss";
import { enqueueSnackbar } from "notistack";

export default function FileUpload(props) {
  const {
    Validator,
    NameID,
    ArtifactType,
    Error,
    validateFileName,
    Clean,
    CleanOff,
  } = props;
  const [selectedFileName, setSelectedFileName] = useState(
    validateFileName ? validateFileName : ""
  );
  const [fileIsHoveringInput, setFileIsHoveringInput] = useState(false);

  useEffect(() => {
    const input = document.getElementById(NameID);
    if (input) {
      input.addEventListener("drop", drop);
      input.addEventListener("dragenter", dragenter);
      input.addEventListener("dragleave", dragleave);
      input.addEventListener("focus", function () {
        input.classList.add("has-focus");
      });
      input.addEventListener("blur", function () {
        input.classList.remove("has-focus");
      });
    }
  }, [NameID]);

  useEffect(() => {
    if (Clean) {
      setSelectedFileName("");
      CleanOff();
    }
  }, [Clean, CleanOff]);
  const drop = () => {
    setFileIsHoveringInput(false);
  };

  const dragenter = () => {
    setFileIsHoveringInput(true);
  };

  const dragleave = () => {
    setFileIsHoveringInput(false);
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      let ext = e.target.files[0].name.split(".");
      if (ArtifactType) {
        setSelectedFileName(e.target.files[0].name);
        props.HandleFile(e.target);
      } else if (ext[ext.length - 1] === Validator) {
        setSelectedFileName(e.target.files[0].name);
        props.HandleFile(e.target);
      } else {
        enqueueSnackbar("Extension no support",{variant:"error"});
      }
    }
  };

  const determineLabelToUse = () => {
    if (selectedFileName) {
      return selectedFileName;
    } else {
      return "";
    }
  };

  return (
    <div className={"file-upload"}>
      <label
        style={{
          textOverflow: "ellipsis",
          // display: "inline-block",
          overflow: "hidden",
          width: "350px",
          whiteSpace: "nowrap",
          //verticalAlign: "middle",
        }}
        htmlFor={NameID}
      >
        {determineLabelToUse()}
      </label>
      <Button
        id="secondary-button"
        variant="contained"
        style={{ color: fileIsHoveringInput ? "#ffa" : "#fff" }}
      >
        {selectedFileName ? "Replace File" : "Choose File"}
        {Error ? " Missing" : ""}
        <input
          type="file"
          id={NameID}
          name="image_uploads"
          accept={ArtifactType ? "" : ".pdf,.xml"}
          onChange={handleChange}
        />
      </Button>
    </div>
  );
}
FileUpload.defaultProps = {
  Validator: "pdf",
  NameID: "file-input",
  ArtifactType: false,
  Error: false,
  Clean: 0,
};
